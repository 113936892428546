import { PopupButton, PopupType } from "./popup-enums";
import { KeyValuesException } from "./error/key-values-exception";

export class PopupInformation {
  constructor(
    public header: string,
    public information: string,
    public buttons: PopupButton[],
    public type: PopupType,
    public keyValueErrors: KeyValuesException[] = null
  ) {}
}
