export enum UserRoles {
  None = 0,
  Read = 11,
  Write = 12,
}

export enum SortProperty {
  StartTime = 1,
  LastUpdate = 2,
  Alphabetically = 3,
}

export enum TextPlacement {
  Left,
  Right,
}

export enum FilterDirection {
  North,
  South,
}

export enum MapLineType {
  Base,
  Highlight,
  Active,
}

/** Used to describe the physical lines position to make path finding easier */
export enum PhysicalLinePosition {
  NorthWest,
  NorthEast,
  Center,
  SouthWest,
  SouthEast,
  None,
}

export enum FilterType {
  None,
  Time,
  TrainNumber,
  Line,
  Direction
}

export enum SortOrder {
  Ascending = 1,
  Descending = 2,
}

/** The component shown in the event container */
export enum EventCard {
  EventList,
  EventForm,
  TrafficActionForm,
  EventLog,
  Announcement,
}

export enum TrafficActionType {
  UNDEFINED = 0,
  OtherAdjustment = 1,
  BrokenTrip = 2,
  CancelledBreak = 3,
  Cancelled = 4,
}

export enum Application {
  MTR = 1,
  TRV = 2,
}

/** Describes what kind of update was made on an event */
export enum UserAction {
  Create,
  Add,
  Update,
  Remove,
  Send,
}

/** The type of updated item shown in the event log */
export enum EventLogItemType {
  Event,
  TrafficAction,
  Announcement,
}

export enum NotificationItemType {
  Event = 0,
  TrafficAction = 1,
  Announcement = 2,
}

export enum FilterAnnouncementType {
  Error,
  EventList,
}
