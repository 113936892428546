import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { Announcement } from "../models/announcement/announcement";
import { AnnouncementCategoryText } from "../models/announcement/announcement-category-text";
import { DropdownValue } from "../models/dropdown-value";
import { AutoUnsubscribe } from "../shared/decorators";
import { FilterService } from "./filter.service";
import { IAnnouncementService } from "./interfaces/i-announcement.service";
import { Train } from "../models/train";
import { AnnouncementCategory } from "../models/announcement/announcement-category";
import { AnnouncementState } from "../models/announcement/announcement-enums";
import {
  AnnouncementHistoryResponse,
  AnnouncementHistoryRequest,
} from "../models/announcement/announcement-history";
import { Filter } from "../models/event/filter";
import _ from "lodash";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle everything to do with announcements */
@Injectable({
  providedIn: "root",
})
@AutoUnsubscribe()
export class AnnouncementService implements IAnnouncementService, OnDestroy {
  public loading: boolean;
  public announcementTexts: DropdownValue[];

  public selectedAnnouncementCategoryText: AnnouncementCategoryText;
  public announcementTextsPopulated = new Subject<boolean>();

  private readonly announcementCategorySegment = "announcementcategories";
  private readonly stationsSegment = "GetOnStation";
  private readonly announcementSegment = "Announcement";
  private readonly announcementHistorySegment = "AnnouncementHistory";
  private readonly getAnnouncementHistorySegment = "GetAnnouncementHistory";

  private readonly headers = { "Api-Version": environment.actionApi.version };

  private _selectedAnnouncement = new BehaviorSubject<Announcement>(null);
  public selectedAnnouncement = this._selectedAnnouncement.asObservable();

  private selectAnnouncementText = new Subject<AnnouncementCategoryText>();

  private _announcements = new BehaviorSubject<Announcement[]>([]);
  public announcements = this._announcements.asObservable();

  private _selectedAnnouncementCategories = new BehaviorSubject<
    AnnouncementCategory[]
  >([]);
  public selectedAnnouncementCategories = this._selectedAnnouncement.asObservable();

  private _announcementCategories = new BehaviorSubject<AnnouncementCategory[]>(
    []
  );
  public announcementCategories = this._announcementCategories.asObservable();

  public createState: boolean;

  constructor(
    private httpClient: HttpClient,
    private filterService: FilterService,
    private urlBuilderService: UrlBuilderService
  ) {
    this.populateAnnouncementCategoryTexts();
  }

  public getAnnouncements() {
    return this._announcements.getValue();
  }

  public getAnnouncementCategories() {
    return this._announcementCategories.getValue();
  }

  public getAnnouncementCategoriesObservable() {
    return this.announcementCategories;
  }

  public getSelectedAnnouncementCategories() {
    return this._selectedAnnouncementCategories.getValue();
  }

  public clearAnnouncements() {
    return this._announcements.next([]);
  }

  public addAnnouncement(announcement: Announcement) {
    const currentAnnouncements = this._announcements.getValue();
    currentAnnouncements
      .filter((x) => x.categoryType === announcement.categoryType)
      .forEach((a) => {
        if (a.id === null || a.id === 0) {
          return;
        }

        // TODO: set state of announcement list item to changed

        // this.registerUpdatedAnnouncement(a);
      });
    this._announcements.next([...this._announcements.getValue(), announcement]);
  }

  public setAnnouncementCategories(
    announcementCategories: AnnouncementCategory[]
  ) {
    this._announcementCategories.next(announcementCategories);
  }

  /** Get a single announcement category */
  public getAnnouncementCategoryByType(
    categoryType: string
  ): AnnouncementCategory {
    return this.getAnnouncementCategories().find(
      (x) => x.categoryType == categoryType
    );
  }

  /** Get a list of announcement texts based on category */
  public getAnnouncementTextList(
    categoryPrefix: string
  ): Observable<AnnouncementCategoryText[]> {
    return this.httpClient.get<AnnouncementCategoryText[]>(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.announcementCategorySegment,
        categoryPrefix,
      ]),
      {
        headers: this.headers,
      }
    );
  }

  public getAnnouncementsForStation(
    stationCode: string
  ): Observable<Announcement[]> {
    const filter = this.filterService.getFilter();
    if (filter.arrivingTrains == null && filter.departingTrains == null) {
      filter.departingTrains = true;
    }

    return this.httpClient.post<Announcement[]>(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.announcementSegment,
        this.stationsSegment,
        stationCode,
      ]),
      filter,
      {
        headers: this.headers,
      }
    );
  }

  public selectAnnouncement(announcement: Announcement) {
    this._selectedAnnouncement.next(announcement);
  }

  public getSelectedAnnouncement(): Announcement {
    return this._selectedAnnouncement.getValue();
  }

  public emitSelectAnnouncementText(
    announcementText: AnnouncementCategoryText
  ) {
    this.selectedAnnouncementCategoryText = announcementText;
    this.selectAnnouncementText.next(announcementText);
  }

  public getSelectAnnouncementText(): Observable<AnnouncementCategoryText> {
    return this.selectAnnouncementText;
  }

  /** Get an empty announcement model based on event and traffic action */
  getNewAnnouncement(
    trainNumbers: Train[],
    actionId: number,
    eventId: number,
    actionFilter: Filter
  ) {
    var newAnnouncement = new Announcement({
      id: null,
      active: true,
      split: false,
      trafficActionId: actionId,
      eventId: eventId,
      title: null,
      refId: null,
      trainNumbers: [],
      filterGeneratedTrains: [],
      categoryType: "Deviation",
      categoryText: null,
      history: [],
      filter: _.cloneDeep(actionFilter),
      announcementActivityIds: [],
      arrivals: false,
      departures: true,
      firstLast: false,
      state: AnnouncementState.New,
      status: "New",
      notified: false,
      priority: 1,
      onlyInTrv: false,
      toBeDeleted: false,
      percentageReplacedByAnother: 0,
      containsExceedsTrvBatchLimits: false,
      tempId: "",
      stations: [],
      splitFromId: 0,
      overrideViaStationValidation: false
    });
    this.setFilterTrainsToTrafficActionTrains(
      newAnnouncement,
      actionFilter,
      trainNumbers
    );
    return newAnnouncement;
  }

  setFilterTrainsToTrafficActionTrains(
    announcement: Announcement,
    actionFilter: Filter,
    trainNumbers: Train[]
  ) {
    announcement.filter.trainNumbers = trainNumbers.map(
      ({ trainNumber }) => trainNumber
    );
  }

  /** Populates the announcement category texts */
  private populateAnnouncementCategoryTexts() {
    this.httpClient
      .get<AnnouncementCategory[]>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.announcementCategorySegment,
        ]),
        {
          headers: this.headers,
        }
      )
      .subscribe((categories) => {
        let categoriesWithIndex = categories.map((c, i) => ({
          categoryType: c.categoryType, 
          prefix: c.prefix, 
          displayName: c.displayName, 
          id: i + 1
        }))
        this.setAnnouncementCategories(categoriesWithIndex);
      });
  }

  public getAnnouncementHistory(
    announcement: Announcement
  ): Observable<AnnouncementHistoryResponse> {
    const announcementHistoryRequest: AnnouncementHistoryRequest = {
      announcementId: announcement.id,
      splitFromAnnouncementId: announcement.splitFromId,
      announcementActivityIds: announcement.announcementActivityIds,
    };
    return this.httpClient.post<AnnouncementHistoryResponse>(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.announcementHistorySegment,
        this.getAnnouncementHistorySegment,
      ]),
      announcementHistoryRequest,
      {
        headers: this.headers,
      }
    );
  }

  public getAnnouncementReplacementText(announcement: Announcement): string {
    if (announcement.percentageReplacedByAnother > 0) {
      if (announcement.percentageReplacedByAnother < 1) {
        return "Annonsering kommer delvis att ersättas.";
      }
      else if (announcement.percentageReplacedByAnother == 1) {
        return "Annonsering kommer ersättas.";
      }
    }
    else {
      return "";
    }
  }

  ngOnDestroy() {}
}
