import { Component, OnInit } from "@angular/core";
import { Announcement } from "src/app/models/announcement/announcement";
import { AnnouncementService } from "src/app/services/announcement.service";
import { EventContainerService } from "src/app/services/event-container.service";
import { FilterService } from "src/app/services/filter.service";
import { Helper } from "src/app/shared/helper";
import { AnnouncementsModalStateService } from "./announcements-modal-state.service";

@Component({
  selector: "dua-announcements-modal",
  templateUrl: "./announcements-modal.component.html",
  styleUrls: ["./announcements-modal.component.scss"],
})
export class AnnouncementsModalComponent implements OnInit {
  constructor(
    private modalState: AnnouncementsModalStateService,
    private eventContainerService: EventContainerService,
    private filterService: FilterService,
    private announcementService: AnnouncementService
  ) {}

  ngOnInit(): void {}

  getDirectionString(announcement: Announcement) {
    const trainNumbers = announcement.trainNumbers
      .filter((t) => Helper.isNumber(t.trainNumber))
      .map((t) => Number.parseInt(t.trainNumber));

    if (trainNumbers.length === 0) {
      return "";
    } else if (trainNumbers.every((t) => Helper.isEvenNumber(t))) {
      return "N:";
    } else if (trainNumbers.every((t) => Helper.isOddNumber(t))) {
      return "S:";
    } else {
      return "N/S:";
    }
  }

  onAvgChange(value: boolean) {
    this.filterService.getFilter().departingTrains = value;
    this.modalState.reload();
  }

  onAnkChange(value: boolean) {
    this.filterService.getFilter().arrivingTrains = value;
    this.modalState.reload();
  }

  close(): void {
    this.modalState.setIsOpen(false);
  }
  getAnnouncementTitle(announcement: Announcement) {
    return announcement.title;
  }

  openAnnouncement(announcement: Announcement): void {
    return;
  }
}
