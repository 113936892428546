import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { EventLog } from "../models/event/event-log";
import { EventLogToTextService } from "./event-log-to-text.service";
import { IEventLogService } from "./interfaces/i-event-log.service";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle event log API  */
@Injectable({
  providedIn: "root",
})
export class EventLogService implements IEventLogService {
  private readonly apiSegment = "EventLog";
  private readonly headers = { "Api-Version": environment.eventLogApi.version };

  constructor(
    private httpClient: HttpClient,
    private eventLogToTextService: EventLogToTextService,
    private urlBuilderService: UrlBuilderService,
  ) {}

  /** Get event logs for speicified event */
  getEventLogs(eventId: number): Observable<string[]> {
    return this.httpClient
      .get<EventLog[]>(
        this.urlBuilderService.buildUrl([
          environment.eventLogApi.path,
          this.apiSegment,
          `?eventId=${eventId}`]),
        { headers: this.headers }
      )
      .pipe(
        map((logArray: EventLog[]) => {
          return logArray.map((logItem) =>
            this.eventLogToTextService.createTextLog(logItem)
          );
        })
      );
  }
}
