import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Subscription } from "rxjs";
import { KeyValuePair } from "../../../models/key-value-pair";
import { MapNode } from "../../../models/line-map/map-node";
import { PathDrawService } from "../../../services/path-draw.service";
import { ZoomAndPanService } from "../../../services/zoom-and-pan.service";

@Component({
  selector: "g[dua-map-node]",
  templateUrl: "./map-node.component.html",
  styleUrls: ["./map-node.component.scss"],
})
export class MapNodeComponent implements OnInit, AfterViewInit {
  @Input() node: MapNode;
  @Output() clickNode: EventEmitter<
    KeyValuePair<MapNode, boolean>
  > = new EventEmitter();

  circleScaleSubscription: Subscription;

  //#region Sizes
  //Size for circle and font size with initial values
  circleScale = 8.5;
  fontSize = 16;
  //#endregion

  constructor(
    private zoomAndPanService: ZoomAndPanService,
    private pathDrawService: PathDrawService
  ) {}

  ngOnInit(): void {
    this.circleScaleSubscription = this.zoomAndPanService
      .getCircleScaleChange()
      .subscribe((scale) => {
        this.circleScale = scale;
      });
  }

  ngAfterViewInit(): void {
    this.pathDrawService.moveToTop(this.node?.id + "-node");
  }

  clickMapNode(event, node: MapNode): void {
    this.clickNode.emit(new KeyValuePair(node, event.shiftKey));
  }
}
