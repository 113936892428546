import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
  selector: "dua-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnChanges {
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    const currentValue = changes["loading"]?.currentValue;

    if (this.timeout && !currentValue) {
      clearTimeout(this.timeout);
      this.internalShow = false;
      this.timeout = undefined;
      return;
    }

    if (!this.timeout && currentValue) {
      this.timeout = setTimeout(() => {
        this.internalShow = true;
        this.timeout = undefined;
      }, this.timeoutValue);
      return;
    }

    if (!currentValue && !this.timeout) {
      this.internalShow = false;
      this.timeout = undefined;
      return;
    }
  }

  timeout: ReturnType<typeof setTimeout>;

  @Input() loading: boolean;

  /** The time out value for when the spinner starts in ms. Default is 250 ms*/
  @Input() timeoutValue: number = 250;

  internalShow: boolean;
}
