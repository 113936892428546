import { Component, Input, OnInit } from '@angular/core';
import { AnnouncementCategory } from "../../../models/announcement/announcement-category";
import { MultiSelectDropdownValue } from "../../../models/multiselect-dropdown-value";
import { FilterService } from '../../../services/filter.service';
import { AnnouncementService } from 'src/app/services/announcement.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dua-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss']
})
export class CategoryFilterComponent implements OnInit {

  @Input() announcementCategoryValues: AnnouncementCategory[];
  categoryChangeSubscription: Subscription;
  announcementCategories: MultiSelectDropdownValue[];
  selectedCategories: any;// TODO: To be marked somewhere as select by default, rather than hardcoding.
  categories: AnnouncementCategory[]

  constructor(private filterService: FilterService, private announcementService: AnnouncementService) {

    announcementService.getAnnouncementCategoriesObservable().subscribe((categories) => {
      this.categories = categories;
      this.announcementCategories = categories.map((x) => new MultiSelectDropdownValue(x.displayName, x.categoryType));
    });
    this.categoryChangeSubscription = this.filterService.getAnnouncementCategoryChange()
    .subscribe((change) => {
      const selectedCategories: string[] = this.categories.filter(c => change.includes(c)).map(c => c.categoryType);
      this.selectedCategories=selectedCategories;
    });
  }

  ngOnInit(): void {
    this.selectedCategories=this.filterService.getCategoryFilter();
  }

  onChange(event: any){
    let selectedCategories = this.categories.filter(c => event.value.includes(c.categoryType));
    this.filterService.emitAnnouncementCategoryChange(selectedCategories);
  }

}
