import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FilterDirection, FilterType } from "../../../models/enums";
import { FilterService } from "../../../services/filter.service";

@Component({
  selector: "dua-direction-filter",
  templateUrl: "./direction-filter.component.html",
  styleUrls: [
    "./../filters.component.scss",
    "./direction-filter.component.scss",
  ],
})
export class DirectionFilterComponent implements OnInit {
  southGoing: boolean = true;
  northGoing: boolean = true;
  filterType = FilterType.Direction;

  resetSubscription: Subscription;
  filterUpdatedSubscription: Subscription;

  constructor(private filterService: FilterService) {}

  ngOnInit(): void {
    this.populateFilter();
    this.resetFilter();
  }
  isDisabled():boolean{
    return this.filterService.isDisabled();
  }
  resetFilter() {
    this.resetSubscription = this.filterService
      .getResetFilterTrigger()
      .subscribe(() => {
        this.populateFilter();
      });
    this.filterUpdatedSubscription = this.filterService
      .getAllUpdatedFilters()
      .subscribe(() => {
        this.populateFilter();
      });
  }

  populateFilter() {
    let filter = this.filterService.getFilter();
    if (filter) {
      this.southGoing = filter.southGoing;
      this.northGoing = filter.northGoing;
    }
  }

  onChange(event: boolean, direction: FilterDirection) {
    if(!this.isDisabled()){
      //Set filter type to Direction to show that no other filter is in focus (by closing the other filters)
      this.filterService.setCurrentFilterType(this.filterType);
  
      if (direction == FilterDirection.North) {
        this.northGoing = event;
        this.filterService.emitNorthGoingChange(this.northGoing);
      } else {
        this.southGoing = event;
        this.filterService.emitSouthGoingChange(this.southGoing);
      }
    }
  }
}
