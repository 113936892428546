import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MapNodeComponent } from "./map-node/map-node.component";
import { LineMapComponent } from "./line-map.component";
import { StationTextComponent } from "./map-node/station-text/station-text.component";
import { SharedModule } from "./../../shared/shared.module";
import { AnnouncementsModalComponent } from "./announcements-modal/announcements-modal.component";

const components = [
  MapNodeComponent,
  LineMapComponent,
  StationTextComponent,
  AnnouncementsModalComponent,
];
@NgModule({
  declarations: components,
  imports: [CommonModule, SharedModule],
  exports: components,
})
export class LineMapModule {}
