import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "timeSince",
  pure: false,
})
export class TimePipe implements PipeTransform {
  intervals = {
    år: 31536000,
    månader: 2592000,
    veckor: 604800,
    dagar: 86400,
    timmar: 3600,
    minuter: 60,
    sekunder: 1,
  };

  shortNames = {
    år: "år",
    månader: "månad",
    veckor: "vecka",
    dagar: "dag",
    timmar: "timme",
    minuter: "minut",
    sekunder: "sekund",
  };

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return "just nu";

      let counter;
      for (const curInt in this.intervals) {
        counter = Math.floor(seconds / this.intervals[curInt]);
        if (counter > 0) {
          if (counter == 1) {
            return counter + " " + this.shortNames[curInt] + " sedan";
          } else {
            return counter + " " + curInt + " sedan";
          }
        }
      }
    }
    return value;
  }
}
