import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TooltipModule } from "primeng/tooltip";
import { PipesModule } from "../pipes/pipes.module";
import { AffectedTrainListComponent } from "./affected-train-list/affected-train-list.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { ClickOutsideDirective } from "./click-outside.directive";
import { InfoButtonComponent } from "./info-button/info-button.component";
import { InputFieldComponent } from "./input-field/input-field.component";
import { RadioButtonComponent } from "./radio-button/radio-button.component";
import { TextAreaComponent } from "./text-area/text-area.component";
import { DropdownModule } from "primeng/dropdown";
import { SpinnerComponent } from "./spinner/spinner.component";
import { NotificationDotComponent } from "./notification-dot/notification-dot.component";
import { PopupComponent } from './popup/popup.component';
import { MultiSelectModule } from "primeng/multiselect";

const exportedDeclarations: any[] = [
  AffectedTrainListComponent,
  CheckboxComponent,
  InfoButtonComponent,
  InputFieldComponent,
  RadioButtonComponent,
  TextAreaComponent,
  ClickOutsideDirective,
  SpinnerComponent,
  NotificationDotComponent,
  PopupComponent
];
const exportedImports: any[] = [
  FormsModule,
  ReactiveFormsModule,
  TooltipModule,
  PipesModule,
  BrowserAnimationsModule,
  HttpClientModule,
  DropdownModule,
  MultiSelectModule
];
@NgModule({
  declarations: exportedDeclarations,
  imports: [CommonModule, ...exportedImports],
  exports: [...exportedDeclarations, ...exportedImports],
})
export class SharedModule {}
