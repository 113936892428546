import { Component, Input, OnInit } from '@angular/core';
import { AnnouncementCategory } from 'src/app/models/announcement/announcement-category';

@Component({
  selector: 'dua-announcement-filter',
  templateUrl: './announcement-filter.component.html',
  styleUrls: ['./announcement-filter.component.scss']
})
export class AnnouncementFilterComponent implements OnInit {

  @Input() announcementCategories:AnnouncementCategory;
  constructor() { }

  ngOnInit(): void {
    }

}
