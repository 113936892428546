import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "dua-radio-button",
  templateUrl: "./radio-button.component.html",
  styleUrls: ["./radio-button.component.scss"],
})
export class RadioButtonComponent implements OnInit {
  @Input() active: boolean;
  @Input() disabled: boolean;
  @Output() clickRadioButton: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  onClick() {
    if (this.disabled) {
      return;
    }

    this.active = true;
    this.clickRadioButton.emit(this.active);
  }
}
