import { Component, OnInit } from "@angular/core";

@Component({
  selector: "dua-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
})
export class FiltersComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
