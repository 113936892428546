import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject, timer } from "rxjs";
import { concatMap, map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { SortOrder, SortProperty } from "../models/enums";
import { Filter } from "../models/event/filter";
import { Event } from "../models/event/event";
import { EventListItem } from "../models/event/event-list-item";
import { EventQuery } from "../models/event/event-query";
import { EventsResponse } from "../models/event/events-response";
import { Sorting } from "../models/event/sorting";
import { AutoUnsubscribe } from "../shared/decorators";
import { FilterService } from "./filter.service";
import { IEventService } from "./interfaces/i-event.service";
import { PagingService } from "./paging.service";
import { SortService } from "./sort.service";
import { EventRequest } from "../models/event/event-request";
import { SignalRService } from "./signal-r.service";
import { StatusItem } from "../models/status/status-response";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle events and event API  */
@Injectable({
  providedIn: "root",
})
@AutoUnsubscribe()
export class EventService implements IEventService, OnDestroy {
  private readonly apiSegment = "Event";
  private readonly querySegment = "Query";
  private readonly eventHub = "event";

  private triggerForGetEvents = new Subject<boolean>();

  private _selectedEvent = new BehaviorSubject<Event>(null);
  public selectedEvent = this._selectedEvent.asObservable();

  private readonly headers = { "Api-Version": environment.actionApi.version };

  constructor(
    private httpClient: HttpClient,
    private sortService: SortService,
    private filterService: FilterService,
    private pagingService: PagingService,
    private signalRService: SignalRService,
    private urlBuilderService: UrlBuilderService
  ) {
    this.subscribeToChanges();
  }

  public selectEvent(event: Event): void {
    this._selectedEvent.next(event);
  }

  public getSelectedEvent(): Event {
    return this._selectedEvent.getValue();
  }

  //#region Public

  public connectToEventsSignalRService(connectFunction: Function[]): void {
    this.signalRService.createConnection(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path.replace("/api", ""),
        this.eventHub,
      ]),
      connectFunction,
      environment.config.actionScope
    );
  }

  public setStatusOnTrafficAction(statusItem: StatusItem): void {
    const eventActions = this._selectedEvent.getValue()?.actions;
    if (eventActions) {
      let action = eventActions.find(
        (x) => x.trafficActionId === statusItem.id
      );
      if (action) {
        action.status = statusItem.s;
      }
    }
  }

  /** Get array of events from API based on filter, sort and paging */
  getEvents(): Observable<EventsResponse> {
    const request = this.getEventQuery(
      this.sortService.getCurrentSort(),
      this.filterService.getFilter()
    );
    return this.httpClient
      .post<EventsResponse>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.apiSegment,
          this.querySegment,
        ]),
        request,
        { headers: this.headers }
      )
      .pipe(
        map((eventView) => {
          const eventResponseItem: EventsResponse = {
            totalPages: eventView.totalPages,
            totalItems: eventView.totalItems,
            currentItems: eventView.currentItems,
            currentPage: eventView.currentPage,
            events: eventView.events.map((eItem) => {
              eItem = new EventListItem(eItem);
              return eItem;
            }),
          };
          return eventResponseItem;
        })
      );
  }

  /** Create a new event */
  createEvent(event: Event): Observable<Event> {
    const eventRequest = this.convertToEventRequest(event);
    return this.httpClient.post<Event>(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.apiSegment,
      ]),
      eventRequest,
      { headers: this.headers }
    );
  }

  /** Update an already existing event */
  updateEvent(event: Event): Observable<Event> {
    const eventRequest = this.convertToEventRequest(event);
    return this.httpClient.put<Event>(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.apiSegment,
        event.id.toString(),
      ]),
      eventRequest,
      { headers: this.headers }
    );
  }

  deleteEventIfEmpty(eventId: number): Observable<object> {
    return this.httpClient.delete(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.apiSegment,
        eventId.toString(),
      ]),
      { headers: this.headers }
    );
  }

  /** Get a single event based on ID */
  getEvent(eventId: number): Observable<Event> {
    return this.httpClient.get<Event>(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.apiSegment,
        eventId.toString(),
      ]),
      { headers: this.headers }
    );
  }

  getTriggerForGetEvents(): Observable<boolean> {
    return this.triggerForGetEvents;
  }
  emitTriggerForGetEvents(value: boolean): void {
    this.triggerForGetEvents.next(value);
  }
  //#endregion

  //#region Private
  private subscribeToChanges(): void {
    this.pagingService.getPageChange().subscribe((change) => {
      //Page 1 is number 0 when getting events, therefore, the displayed pagenumber needs to be saved as number - 1
      this.pagingService.setPageNumber(change - 1);
      this.triggerForGetEvents.next(true);
    });

    this.filterService.getUpdateEventsOnFilteringChange().subscribe((x) => {
      this.triggerGetEventsOnFilter();
    });
  }

  /** Constructs a request to get an array of events */
  private getEventQuery(sorting: SortProperty, filter: Filter) {
    let sortOrder = SortOrder.Descending;
    if (sorting == SortProperty.Alphabetically) {
      sortOrder = SortOrder.Ascending;
    }
    return new EventQuery(
      filter,
      this.pagingService.getPaging(),
      new Sorting(sorting, sortOrder)
    );
  }

  private convertToEventRequest(event: Event): EventRequest {
    return {
      reasonId: event.reasonId,
      trains: event.filterGeneratedTrains,
      comment: event.comment ?? "",
      filter: event.filter,
    };
  }

  /** Resets the paging and emits event to trigger a GetEvents */
  private triggerGetEventsOnFilter(): void {
    this.pagingService.resetPaging();
    //Emit event to query for the event list again
    this.triggerForGetEvents.next(true);
  }
  //#endregion

  ngOnDestroy() {}
}
