import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
} from "@angular/core";
import { TextPlacement } from "../../../../models/enums";
import { MapNode } from "../../../../models/line-map/map-node";
import { ZoomAndPanService } from "../../../../services/zoom-and-pan.service";
import { Helper } from "../../../../shared/helper";
import { AnnouncementsModalStateService } from "../../announcements-modal/announcements-modal-state.service";

@Component({
  selector: "g[dua-station-text]",
  templateUrl: "./station-text.component.html",
  styleUrls: ["./station-text.component.scss"],
})
export class StationTextComponent implements OnInit, AfterViewInit {
  @Input() node: MapNode;

  TextPlacement = TextPlacement;

  //Size for circle and font size with initial values
  @Input() circleScale;
  fontSize = 14;
  //#endregion

  //#region Tresholds
  //Tresholds used for scaling and positioning to make sure we don't go to far.
  private displayValueTreshold = 4;
  private textTransformTresholdX = 7.8;
  //#endregion

  //#region Multipliers
  //Multiplier values that give a decent result in positioning the text after scaling the font.
  private xMultiplier = 2;
  //#endregion

  constructor(
    private zoomAndPanService: ZoomAndPanService,
    private announcementModalState: AnnouncementsModalStateService,
    private elementRef: ElementRef
  ) {}

  ngOnInit(): void {
    this.announcementModalState.isOpen.subscribe((open) => {
      if (!open) {
        this.node.expanded = false;
      }
    });
  }

  ngAfterViewInit() {
    this.fontSize = parseInt(
      Helper.getCssProperty("--data-standard-font-size")
    );
  }

  /** Returns a display value for announcement based on a treshold */


  /** Calculates the font size for the station text based on the scale from the zoom service */
  calculateFontSize() {
    return this.fontSize / this.zoomAndPanService.scale + "px";
  }

  onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.node.expanded = !this.node.expanded;

    if (
      this.announcementModalState.stationCode ===
        this.node.station.stationCode &&
      this.announcementModalState.getIsOpen()
    ) {
      this.announcementModalState.setIsOpen(false);
      return;
    }

    const namePosition = (this.elementRef
      .nativeElement as HTMLElement).getBoundingClientRect();
    const positionTop = namePosition.top;
    const positionLeft =
      namePosition.left - 400 /* width of modal */ + namePosition.width;

    this.announcementModalState.position(positionTop, positionLeft);

    this.announcementModalState.show(this.node.station.stationCode);
  }

  /** calculates text transformation as the text is otherwise moved away from the line map due to the scaling of font size */
  calculateTextTransform(): string {
    let scale = this.zoomAndPanService.scale;
    let x;

    if (this.node.textPlacement == TextPlacement.Left) {
      x =
        this.node.textTransformX +
        (scale < this.textTransformTresholdX
          ? scale * this.xMultiplier
          : this.textTransformTresholdX * this.xMultiplier);
    } else {
      x =
        this.node.textTransformX +
        (scale < this.textTransformTresholdX
          ? -scale * this.xMultiplier
          : -this.textTransformTresholdX * this.xMultiplier);
    }
    return `translate(${x},${this.node.textTransformY})`;
  }
}
