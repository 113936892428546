import { Injectable } from "@angular/core";
import { EventLogItemType, UserAction } from "../models/enums";
import { EventLog } from "../models/event/event-log";

/** Service to transform event log data to a readable format */
@Injectable({
  providedIn: "root",
})
export class EventLogToTextService {
  constructor() {}

  /** Used to convert an EventLog item to a displayable text string */
  createTextLog(logItem: EventLog) {
    let dateText = this.getDateString(logItem.timeStamp);
    let actionText =
      logItem.eventLogItemType !== EventLogItemType.Event
        ? `${this.numberToString(logItem.itemQuantity)} `
        : "";
    actionText +=
      logItem.userAction === UserAction.Add &&
      logItem.eventLogItemType !== EventLogItemType.Event
        ? logItem.itemQuantity > 1
          ? "nya "
          : "ny "
        : "";
    actionText += `${this.getLogItemTypeString(logItem)} `;
    actionText += `${this.getUserActionString(logItem.userAction)} `;
    actionText += `av ${logItem.userFullName}`;
    actionText.trim();
    actionText = actionText.substr(0, 1).toUpperCase() + actionText.substr(1);
    return `${dateText} ${actionText}.`;
  }

  /** Converts the date for the log */
  private getDateString(date: Date): string {
    date = new Date(date);
    return `${date.toLocaleDateString("sv-SE")} ${date.toLocaleTimeString(
      "sv-SE",
      {
        hour: "2-digit",
        minute: "2-digit",
      }
    )}.`;
  }

  /** Converts the log item type */
  private getLogItemTypeString(log: EventLog): string {
    if (log.eventLogItemType === EventLogItemType.Event) {
      return "händelsen";
    }
    if (log.eventLogItemType === EventLogItemType.Announcement) {
      return log.itemQuantity > 1 ? "annonseringar" : "annonsering";
    }
    if (log.eventLogItemType === EventLogItemType.TrafficAction) {
      return log.itemQuantity > 1 ? "trafikåtgärder" : "trafikåtgärd";
    }
    return "";
  }

  /** Converts the user's action */
  private getUserActionString(action: UserAction): string {
    let actionText = "";
    switch (action) {
      case UserAction.Create:
        actionText = "har skapats";
        break;
      case UserAction.Add:
        actionText = "har lagts till";
        break;
      case UserAction.Remove:
        actionText = "har tagits bort";
        break;
      case UserAction.Update:
        actionText = "har uppdaterats";
        break;
      case UserAction.Send:
        actionText = "har skickats till Trafikverket";
        break;
      default:
    }
    return actionText;
  }

  /** Converts number to text */
  private numberToString(num: number): string {
    let numberAsWords = [
      "",
      "en",
      "två",
      "tre",
      "fyra",
      "fem",
      "sex",
      "sju",
      "åtta",
      "nio",
    ];
    let numberText = numberAsWords[num] ?? "" + num;
    return numberText;
  }
}
