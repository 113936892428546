/** Enum to describe the types of action the user can perform to a popup */
export enum PopupButton {
  Ok,
  Cancel,
  Yes,
  No,
  YesDisabled
}

/** Enum for choosing the information for the popup */
export enum PopupType {
  Error,
  ReasonChange,
  TrafficActionHasChanges
}
