import { AnnouncementCategoryGroup } from "../announcement/announcement-category-group";
import { TrafficActionType } from "../enums";
import { Filter } from "../event/filter";
import { Train } from "../train";

export interface ITrafficAction {
  id?: number;
  eventId?: number;
  title: string;
  templateType: TrafficActionType;
  reasonId: number;
  trains: Train[];
  filterGeneratedTrains: Train[];
  announcementCategoryGroups: AnnouncementCategoryGroup[];
  reminderInMinutes: number;
  filter: Filter;
}

export class TrafficAction implements ITrafficAction {
  public id?: number;
  public eventId?: number;
  public title: string;
  public templateType: TrafficActionType;
  public reasonId: number;
  public trains: Train[];
  public filterGeneratedTrains: Train[];
  public announcementCategoryGroups: AnnouncementCategoryGroup[];
  public reminderInMinutes: number;
  public filter: Filter;

  constructor(trafficAction: ITrafficAction) {
    this.id = trafficAction.id;
    this.eventId = trafficAction.eventId;
    this.title = trafficAction.title;
    this.templateType = trafficAction.templateType;
    this.reasonId = trafficAction.reasonId;
    this.trains = trafficAction.trains;
    this.filterGeneratedTrains = trafficAction.filterGeneratedTrains ?? [];
    this.announcementCategoryGroups = trafficAction.announcementCategoryGroups;
    this.reminderInMinutes = trafficAction.reminderInMinutes;
    this.filter = trafficAction.filter;
  }
}
