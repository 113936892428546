import { Component, Input, OnInit } from "@angular/core";
import { ListItemService } from "src/app/services/list-item.service";
import { EventListItem } from "../../../../models/event/event-list-item";

@Component({
  selector: "dua-event-list-item",
  templateUrl: "./event-list-item.component.html",
  styleUrls: ["./event-list-item.component.scss"],
})
export class EventListItemComponent implements OnInit {
  @Input() eventListItem: EventListItem;
  constructor(private listItemService: ListItemService) {}

  ngOnInit(): void {}

  getIconFromStatus(): string {
    return this.listItemService.getIconFromStatus(this.eventListItem?.notified, this.eventListItem?.status);
  }
}
