import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { HomeComponent } from "./home/home.component";
import { NotAuthorizedComponent } from "./not-authorized/not-authorized.component";
import { RedirectPageComponent } from "./redirect-page/redirect-page.component";

const APP_ROUTES: Routes = [
  { path: "tot", component: HomeComponent, canActivate: [MsalGuard] },
  { path: "trafikinfo", component: HomeComponent, canActivate: [MsalGuard] },
  { path: "signin-oidc", redirectTo: "" },
  { path: "signout-oidc", redirectTo: "" },
  { path: "dua", component: HomeComponent, canActivate: [MsalGuard],},
  { path: "dua/popup", component: HomeComponent, canActivate: [MsalGuard],},  //Mock page to test popup design
  { path: "", component: RedirectPageComponent, pathMatch: "full", canActivate: [MsalGuard]},
  { path: "not-authorized", component: NotAuthorizedComponent },
];

const isIframe: boolean = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      // Don't perform initial navigation in iframes
      initialNavigation: !isIframe ? 'enabledNonBlocking' : 'disabled'
    }),
  ],
  exports: [RouterModule],
})

export class AppRoutingModule { }
