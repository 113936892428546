import { Component, Input, OnInit } from "@angular/core";
import { cloneDeep, isEqualWith } from "lodash";
import { Subscription } from "rxjs";
import { EditAnnouncementRequest } from "src/app/models/announcement/edit-announcement-request";
import { TrafficAction } from "src/app/models/traffic-action/traffic-action";
import { TrafficActionService } from "src/app/services/traffic-action.service";
import { Announcement } from "../../../models/announcement/announcement";
import { UserRoles } from "../../../models/enums";
import { AnnouncementService } from "../../../services/announcement.service";
import { AuthService } from "../../../services/auth.service";
import { EventContainerService } from "../../../services/event-container.service";
import { FilterService } from "../../../services/filter.service";
import { TrainService } from "../../../services/train.service";
import { RequestObjectBuilderService } from "../../../services/request-object-builder.service";
import { HttpErrorResponse } from "@angular/common/http";
import { debounceTime, switchMap } from "rxjs/operators";
import { AnnouncementState } from "src/app/models/announcement/announcement-enums";
import { AnnouncementHistory } from "src/app/models/announcement/announcement-history";
import { PopupService } from "src/app/services/popup.service";
import { PopupType } from "src/app/models/popup-enums";
import { AutoUnsubscribe } from "../../../shared/decorators";
import { ReasonService } from "src/app/services/reason.service";
import { AnnouncementProperty } from "src/app/models/announcement/announcement-property";
import { AnnouncementCategoryText } from "src/app/models/announcement/announcement-category-text";

@Component({
  selector: "dua-announcement",
  templateUrl: "./announcement.component.html",
  styleUrls: ["./announcement.component.scss"],
})
@AutoUnsubscribe()
export class AnnouncementComponent implements OnInit {
  @Input() announcement: Announcement;
  action: TrafficAction;

  originalAnnouncement: Announcement;
  useReadWriteAccess = true;
  createMode: boolean;
  trainSubscription: Subscription;
  trainTriggerSubscription: Subscription;
  stationChangeSubscription: Subscription;
  disabledForEdit: boolean;
  announcementHistory: AnnouncementHistory[];
  loading: boolean;
  showOverrideViaStationValidation: boolean;

  constructor(
    private authService: AuthService,
    private filterService: FilterService,
    private trainService: TrainService,
    private requestObjectBuilderService: RequestObjectBuilderService,
    private announcementService: AnnouncementService,
    private eventContainerService: EventContainerService,
    private trafficActionService: TrafficActionService,
    private popupService: PopupService,
    private reasonService: ReasonService
  ) {}

  ngOnInit(): void {
    this.action = this.trafficActionService.getSelectTrafficAction();
    this.createMode = !this.announcement?.id && !this.announcement?.title;
    this.disabledForEdit = !this.editableByOperator() && !this.createMode;
    this.showOverrideViaStationValidation = this.announcement.categoryType == 'ViaFrom' || 
                                            this.announcement.categoryType == 'ViaTo' || 
                                            this.announcement?.overrideViaStationValidation;
    this.announcementService.getSelectAnnouncementText()
      .subscribe((category: AnnouncementCategoryText) => {
        this.showOverrideViaStationValidation = category.code == 'ViaFrom' || category.code == 'ViaTo'
      });

    this.initiateComponent();
  }

  onSubmit() {
    if (this.announcement) {
      this.updateAnnouncementValues();
    }

    this.eventContainerService.loading = true;
    let group = this.action.announcementCategoryGroups.find(
      (x) => x.categoryType === this.announcement.categoryType
    );
    if (group) {
      let index = group.announcements.findIndex((x) => x === this.announcement);
      if (index >= 0) {
        group.announcements[index] = this.originalAnnouncement;
      }
    }

    let filterGeneratedTrains = this.action.filterGeneratedTrains;
    var request = new EditAnnouncementRequest(
      this.requestObjectBuilderService.buildTrafficActionRequest(this.action),
      this.requestObjectBuilderService.buildAnnouncementRequest(
        this.announcement,
        this.action.filter,
        this.action.filterGeneratedTrains
      ),
      this.createMode,
      this.announcement.categoryType
    );

    this.trafficActionService.addOrEditAnnouncement(request).subscribe(
      (response) => {
        response.filterGeneratedTrains = filterGeneratedTrains;
        this.trafficActionService.selectTrafficAction(response);
        this.returnToTrafficAction();
      },
      (error: HttpErrorResponse) => {
        if (error.error.overridable) {
          this.showOverrideViaStationValidation = true;
        }
        this.popupService.setErrorPopup(error);
        this.popupService.setCurrentPopup(PopupType.Error);
        this.eventContainerService.loading = false;
      }
    );
  }
  returnToTrafficAction() {
    this.announcement = null;
    this.eventContainerService.returnToTrafficAction();
  }

  updateAnnouncementValues() {
    let filter = this.filterService.getFilterCopy();
    this.announcement.filter = filter;
    this.announcement.onlyInTrv = false;
    this.announcement.active = true;
    this.announcement.state =
      (this.createMode && !this.announcement.id) ||
      this.announcement.state == AnnouncementState.New
        ? AnnouncementState.New
        : AnnouncementState.Changed;
    if (
      this.createMode &&
      this.announcementWithCancelCode() &&
      this.announcement.categoryText?.properties &&
      this.announcement.categoryText?.properties?.length > 0
    ) {
      let property = this.announcement.categoryText.properties.find(
        (x) => x.key.toLowerCase() === "reason"
      );
      if (property) {
        property.value = this.reasonService.getTrvReasonText(
          this.action.reasonId
        );
      }
    }
  }

  getAnnouncementCategoryTextProperties(): AnnouncementProperty[] {
    if (!this.announcementWithCancelCode()) {
      return this.announcement?.categoryText?.properties;
    }
  }

  announcementWithCancelCode(): boolean {
    return this.announcement?.categoryText?.code
      .toLowerCase()
      .startsWith("ins_");
  }

  cancelledAnnouncement(): boolean {
    return (
      this.announcementWithCancelCode() && this.announcement.onlyInTrv === true
    );
  }

  editableByOperator(): boolean {
    return this.announcement?.categoryText?.externalOperatorCanUse;
  }

  removeAnnouncement() {
    this.trafficActionService.removeAnnouncementFromTrafficAction(
      this.announcement
    );
    this.eventContainerService.returnToTrafficAction();
  }

  disableEdit() {
    return (
      (this.useReadWriteAccess &&
        !this.authService.userHasMinimumRole(UserRoles.Write)) ||
      !this.hasModelChanged()
    );
  }

  isModelValid(): boolean {
    return !this.disableEdit() && !this.hasMissingPropertyValues();
  }

  hasModelChanged(): boolean {
    const hasChanged = !isEqualWith(
      this.announcement,
      this.originalAnnouncement,
      (a, b, key, object) => {
        // to compare nullable booleans
        if ((a === null || a === false) && (b === null || b === false)) {
          return true;
        }
        // to compare category texts when id is null
        if (key === "id" && object["code"]) {
          return true;
        }
      }
    );
    return this.createMode || hasChanged;
  }

  hasMissingPropertyValues(): boolean {
    const value =
      !this.announcement?.categoryText ||
      (!this.announcementWithCancelCode() &&
        this.announcement.categoryText.properties &&
        this.announcement.categoryText.properties.some((x) => !x.value));

    return value;
  }

  onClickReturnButton() {
    this.announcementService.selectAnnouncement(null);
    this.eventContainerService.goToTrafficActionForm(
      this.trafficActionService.getSelectTrafficAction()
    );
  }

  arrivalChange(checked: boolean) {
    this.announcement.arrivals = checked;
    if (checked) {
      this.announcement.firstLast = false;
    }
  }

  departureChange(checked: boolean) {
    this.announcement.departures = checked;
    if (checked) {
      this.announcement.firstLast = false;
    }
  }

  firstLastChange(checked: boolean) {
    this.announcement.firstLast = checked;
    if (checked) {
      this.announcement.arrivals = false;
      this.announcement.departures = false;
    }
  }

  overrideViaStationValidationChange(checked: boolean) {
    this.announcement.overrideViaStationValidation = checked;
  }

  private initiateComponent() {
    this.originalAnnouncement = cloneDeep(this.announcement);

    if (
      this.announcement.id !== null &&
      !this.announcement.onlyInTrv &&
      this.announcement.state !== AnnouncementState.New
    ) {
      this.loading = true;
      this.announcementService
        .getAnnouncementHistory(this.announcement)
        .subscribe(
          (response) => {
            this.loading = false;
            if (
              this.announcement.id !== null &&
              (this.announcement.id === response.announcementId ||
                this.announcement.splitFromId === response.announcementId)
            ) {
              this.announcementHistory = response.history;
            }
          },
          (error: HttpErrorResponse) => {
            this.loading = false;
            console.error(error);
          }
        );
    }

    this.trainTriggerSubscription = this.filterService
      .getFetchTrainsTrigger()
      .pipe(
        debounceTime(300),
        switchMap((triggered) => {
          let filter = this.filterService.getFilter();
          return this.trainService.getTrains(filter);
        })
      )
      .subscribe(
        (trains) => {
          if (this.announcement) {
            this.announcement.filterGeneratedTrains = trains;
          }
        },
        (error) => {
          this.popupService.setErrorPopup(error);
        }
      );

    this.stationChangeSubscription = this.filterService
      .getStationCodesChange()
      .subscribe(
        (stationCodes) => (this.announcement.filter.stationCodes = stationCodes)
      );
  }

  ngOnDestroy() {
    this.announcement = null;
  }
}
