import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "dua-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = false;
  environment: string;
  trainsOnTimeUrl = environment.trainsOnTimeUrl;
  trainsOnTimeAdminUrl = environment.trainsOnTimeAdminUrl;
  trafficInformationUrl = environment.trafficInformationUrl;

  constructor(private router: Router, private authService: AuthService) {
    this.setEnvironmentString();
  }

  ngOnInit() {
    this.authService.subscribeTokenListeners();
  }

  ngOnDestroy() {
    this.authService.unsubscribeTokenListeners();
  }

  isActiveLink(url: string): boolean {
    return this.router.url === url;
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  setEnvironmentString() {
    if (environment.env.development) {
      this.environment = "DEV";
    }
    else if (environment.env.development2) {
        this.environment = "DEV2";
    } else if (environment.env.test) {
      this.environment = "TEST";
    } else if (environment.env.production) {
      this.environment = "";
    } else {
      this.environment = "LOCAL";
    }
  }
}
