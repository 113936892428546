import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { EventContainerHeaderService } from "../../../services/event-container-header.service";

@Component({
  selector: "dua-event-container-header",
  templateUrl: "./event-container-header.component.html",
  styleUrls: ["./event-container-header.component.scss"],
})
export class EventContainerHeaderComponent implements OnInit {
  @Output() backButton = new EventEmitter<boolean>();
  @Output() forwardButton = new EventEmitter<boolean>();

  constructor(private headerService: EventContainerHeaderService) {}

  ngOnInit(): void {}

  clickForward() {
    this.forwardButton.emit(true);
  }

  clickBack() {
    this.backButton.emit(true);
  }

  getHeader() {
    return this.headerService.headerText;
  }

  getForwardButtonText() {
    return this.headerService.forwardText;
  }

  getBackButtonVisible() {
    return this.headerService.backButtonVisible;
  }

  getForwardButtonVisible() {
    return this.headerService.forwardButtonVisible;
  }
}
