export enum AnnouncementState {
  Unchanged,
  New,
  Changed,
  Removed,
}

export enum AnnouncementCategoryType
{
    UNDEFINED,
    Deviation,
    Booking,
    OtherInformation,
    ProductInformation,
    Service,
    TrainComposition,
    ViaFrom,
    ViaTo,
    CancelledReplaced
}
