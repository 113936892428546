import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterType } from "../../../models/enums";
import { FilterService } from "../../../services/filter.service";

@Component({
  selector: "dua-filter-header",
  templateUrl: "./filter-header.component.html",
  styleUrls: ["./filter-header.component.scss"],
})
export class FilterHeaderComponent implements OnInit {
  @Input() header: string;
  @Input() infoText: string;
  @Output() closeFilter: EventEmitter<boolean> = new EventEmitter();
  constructor(private filterService: FilterService) {}

  ngOnInit(): void {}

  close() {
    this.filterService.setCurrentFilterType(FilterType.None);
    this.closeFilter.emit(true);
  }
}
