import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { cloneDeep, isEqual, clone, omit } from "lodash";
import { of, Subscription } from "rxjs";
import { UserRoles } from "src/app/models/enums";
import { PopupType } from "src/app/models/popup-enums";
import { AuthService } from "src/app/services/auth.service";
import { EventContainerService } from "src/app/services/event-container.service";
import { PopupService } from "src/app/services/popup.service";
import { TrainService } from "src/app/services/train.service";
import { Event } from "../../../models/event/event";
import { EventService } from "../../../services/event.service";
import { FilterService } from "../../../services/filter.service";
import { ReasonService } from "../../../services/reason.service";
import { AutoUnsubscribe } from "../../../shared/decorators";
import { TrafficActionService } from "../../../services/traffic-action.service";
import { EventContainerHeaderService } from "src/app/services/event-container-header.service";
import { ReasonChangeTrafficActionsRequest } from "src/app/models/traffic-action/reasonchange-traffic-actions-request";
import { debounceTime, first, switchMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "dua-event",
  templateUrl: "./event.component.html",
  styleUrls: ["./event.component.scss"],
})
@AutoUnsubscribe()
export class EventComponent implements OnInit, OnDestroy {
  @Input() eventItem: Event;
  @Input() createMode: boolean;
  @Input() loading: boolean;

  @Output() returnToEventList: EventEmitter<boolean> = new EventEmitter();

  originalEventItem: Event;
  trainTriggerSubscription: Subscription;
  trainSubscription: Subscription;
  stationChangeSubscription: Subscription;

  useReadWriteAccess: boolean = true;

  currentWindowHeight: number;

  eventComment: string = "";

  groupedReasons = [];

  constructor(
    private authService: AuthService,
    private eventService: EventService,
    private filterService: FilterService,
    private trainService: TrainService,
    private reasonService: ReasonService,
    private popupService: PopupService,
    private eventContainerService: EventContainerService,
    private trafficActionService: TrafficActionService,
    private eventContainerHeaderService: EventContainerHeaderService
  ) {}

  ngOnInit(): void {
    this.groupedReasons = this.reasonService.getGroupedReasons();

    this.trainTriggerSubscription = this.filterService
      .getFetchTrainsTrigger()
      .pipe(
        debounceTime(300),
        switchMap((triggered) => {
          const filter = this.filterService.getFilterCopy();
          return this.trainService.getTrains(filter);
        })
      )
      .subscribe((trains) => {
        if (this.eventItem) this.eventItem.filterGeneratedTrains = trains;

        //Following changes are not part of model changes so update
        if (this.originalEventItem) {
          this.originalEventItem.filterGeneratedTrains = clone(
            this.eventItem.filterGeneratedTrains
          );
          this.originalEventItem.actions = clone(this.eventItem.actions);
        }
      }, error => this.popupService.setErrorPopup(error));

    this.stationChangeSubscription = this.filterService
      .getStationCodesChange()
      .subscribe(
        (stationCodes) => (this.eventItem.filter.stationCodes = stationCodes)
      );

    this.initiateAffectedTrains();

    this.setTimeFilter();
  }

  private initiateAffectedTrains() {
    const filter = this.filterService.getFilterCopy();
    if (this.eventItem)
      this.trainService
        .getTrains(filter)
        .pipe(first())
        .subscribe((trains) => {
          this.eventItem.filterGeneratedTrains = trains;
          if (this.originalEventItem) {
            this.originalEventItem.filterGeneratedTrains = clone(
              this.eventItem.filterGeneratedTrains
            );
          }
        }, error => this.popupService.setErrorPopup(error));
  }

  setTimeFilter() {
    if (this.eventItem?.actions?.length > 0) {
      let maxToDateTrafficAction = this.eventItem.actions?.reduce((a, b) =>
        a.toDate > b.toDate ? a : b
      );
      let minFromDateTrafficAction = this.eventItem.actions?.reduce((a, b) =>
        a.fromDate < b.fromDate ? a : b
      );
      if (this.eventItem.filter.toDate < maxToDateTrafficAction?.toDate) {
        this.eventItem.filter.toDate = maxToDateTrafficAction?.toDate;
        this.updateEvent();
      }
      if (this.eventItem.filter.fromDate > minFromDateTrafficAction?.fromDate) {
        this.eventItem.filter.fromDate = minFromDateTrafficAction?.fromDate;
        this.updateEvent();
      }
    }
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes["eventItem"]) {
      this.eventItem = changes["eventItem"].currentValue;
      this.originalEventItem = cloneDeep(this.eventItem);
    }
  }

  updateCurrentWindowHeight() {
    //203 is the amount of px that needs to be subtracted in order to make the ddl responsive and able to show as much as possible
    this.currentWindowHeight =
      window.innerHeight -
      (document.getElementById("eventListHeaderId").clientHeight + 203);
  }

  clickReturnButton() {
    this.returnToEventList.emit(true);
  }

  disableEdit() {
    return (
      this.useReadWriteAccess &&
      !this.authService.userHasMinimumRole(UserRoles.Write)
    );
  }

  getButtonText(): string {
    return this.createMode && !this.eventItem?.id
      ? "Skapa Händelse"
      : "Spara Händelse";
  }

  isModelValid() {
    return this.eventItem?.reasonId >= 0;
  }

  hasModelChanged() {
    const hasChanged = !isEqual(
      omit(this.originalEventItem, ['trains', 'actions']),
      omit(this.eventItem, ['trains', 'actions'])
      );
    return this.createMode || hasChanged;
  }

  submit() {
    if (this.isModelValid() && !this.disableEdit()) {
      if (this.createMode && !this.eventItem?.id) {
        this.createEvent();
      } else {
        if (
          this.eventItem.reasonId != this.originalEventItem.reasonId &&
          this.eventItem.actions.length > 0
        ) {
          this.popupService.setCurrentPopup(
            PopupType.ReasonChange,
            null,
            //YesAction
            () => of(this.update(true)),
            //NoAction
            () => of(this.eventItem.reasonId, this.update(false)),
            //Cancel
            () =>
              of((this.eventItem.reasonId = this.originalEventItem.reasonId))
          );
        } else {
          this.update(false);
        }
      }
    }
    this.eventComment = this.eventItem.comment;
  }

  createEvent() {
    try {
      return this.eventService
        .createEvent(this.eventItem)
        .subscribe((eventItem) => {
          this.eventItem = eventItem;
          this.createMode = false;
          this.originalEventItem = cloneDeep(this.eventItem);
          this.eventContainerService.setEvent(eventItem);
          this.eventService.selectEvent(eventItem);
          this.eventContainerService.goToEventForm(eventItem, false);
          this.initiateAffectedTrains();
        });
    } catch (error) {
      console.error(error);
      this.popupService.setErrorPopup(error);
    }
  }

  update(reasonChangeInAction: boolean) {
    const filter = this.filterService.getFilterCopy();
    const filterId = this.eventItem.filter.id;
    this.eventItem.filter = filter;
    this.eventItem.filter.id = filterId;
    let reasonChanged = reasonChangeInAction ? true : false;
    if (reasonChanged) {
      var result = this.trafficActionService.updateReasonOnTrafficActions(
        new ReasonChangeTrafficActionsRequest(
          this.eventItem.id,
          this.eventItem.reasonId
        )
      );
      result.subscribe((res) => {
        if (res) {
          this.updateEvent();
        }
      });
    } else {
      this.updateEvent();
    }
    this.eventContainerService.loading = true;
  }

  private updateEvent() {
    return this.eventService.updateEvent(this.eventItem).subscribe(
      (response) => {
        this.createMode = false;
        this.eventContainerService.loading = false;
        this.eventContainerHeaderService.headerText = response.title;
        this.originalEventItem = cloneDeep(response);
        this.eventContainerService.setEvent(response);
        this.eventService.selectEvent(response);
        this.eventContainerService.goToEventForm(response, false);
      },
      (error: HttpErrorResponse) => {
        this.eventContainerService.loading = false;
        console.error(error);
        this.popupService.setErrorPopup(error);
        this.eventItem = this.originalEventItem;
      }
    );
  }

  ngOnDestroy() {}
}
