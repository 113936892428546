import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { Calendar } from "primeng/calendar";
import { TimeType } from "../../../../models/timeType";
import { FilterService } from "../../../../services/filter.service";
import { Helper } from "../../../../shared/helper";

@Component({
  selector: "dua-time-picker",
  templateUrl: "./time-picker.component.html",
  styleUrls: ["./time-picker.component.scss"],
})
export class TimePickerComponent implements OnInit {
  @Output() closeFilter: EventEmitter<boolean> = new EventEmitter();

  fromDate: Date;
  fromTime: Date;
  toDate: Date;
  toTime: Date;

  timeType: number;
  sv: any;

  @ViewChild("myFromDateCalendar") fromDatePicker: Calendar;
  @ViewChild("myFromTimeCalendar") fromTimePicker: Calendar;
  @ViewChild("myToDateCalendar") toDatePicker: Calendar;
  @ViewChild("myToTimeCalendar") toTimePicker: Calendar;

  constructor(
    private filterService: FilterService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.initiateComponent();
  }

  private initiateComponent() {
    let filter = this.filterService.getFilterCopy();

    this.fromDate = new Date(filter.fromDate) ?? new Date();

    this.fromTime = new Date(filter.fromDate) ?? new Date();

    this.toDate = new Date(filter.toDate) ?? new Date();

    this.toTime = new Date(filter.toDate) ?? new Date();

    this.timeType = filter.timeType.id;
  }

  toggleTimeType(event, timeType: number) {
    this.timeType = timeType;
    //emit this.timeType when backend is done
    this.filterService.emitTimeTypeChange(new TimeType(timeType, ""));
  }

  onModelChange(value: Date, property: string) {

    if (property === "fromDate" && value) {
      Helper.setDateFromOtherDate(this.fromDate, value);
      this.fromDate.setHours(this.fromTime.getHours());
      this.fromDate.setMinutes(this.fromTime.getMinutes());
    }

    if (property === "fromTime" && value) {
      this.fromDate.setHours(value.getHours());
      this.fromDate.setMinutes(value.getMinutes());
    }

    if (property === "toDate" && value) {
      Helper.setDateFromOtherDate(this.toDate, value);
      this.toDate.setHours(this.toTime.getHours());
      this.toDate.setMinutes(this.toTime.getMinutes());
    }

    if (property === "toTime" && value) {
      this.toDate.setHours(value.getHours());
      this.toDate.setMinutes(value.getMinutes());
    }

    Helper.resetSeconds(this.fromDate);
    Helper.resetSeconds(this.toDate);

    this.filterService.emitDateChange([this.fromDate, this.toDate]);
  }

  onEnter(event: KeyboardEvent) {
    //Pick out the calendar
    let element = event.target as HTMLElement;
    let calendar = element.closest("p-calendar");

    let input;

    //Compare calendar id to viewchild id
    switch (calendar.id) {
      case this.fromDatePicker.el.nativeElement.id:
        input = this.fromTimePicker.inputfieldViewChild.nativeElement;
        break;
      case this.fromTimePicker.el.nativeElement.id:
        input = this.toDatePicker.inputfieldViewChild.nativeElement;
        break;
      case this.toDatePicker.el.nativeElement.id:
        input = this.toTimePicker.inputfieldViewChild.nativeElement;
        break;
      case this.toTimePicker.el.nativeElement.id:
        //Emit close event if last input
        this.closeFilter.emit(true);
        return;
      default:
        console.error("Did not recognize the element");
    }

    //Click selected input
    input.focus();
    input.click();
  }

  close() { }
}
