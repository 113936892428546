import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { DropdownValue } from "../models/dropdown-value";
import { Filter } from "../models/event/filter";
import {
  ITrafficAction,
  TrafficAction,
} from "../models/traffic-action/traffic-action";
import { TrafficActionListItem } from "../models/traffic-action/traffic-action-list-item";
import { TrafficActionTemplate } from "../models/traffic-action/traffic-action-template";
import { AutoUnsubscribe } from "../shared/decorators";
import { ITrafficActionService } from "./interfaces/i-traffic-action.service";
import { Train } from "../models/train";
import { GenerateActionRequest } from "../models/traffic-action/generate-traffic-action-request";
import { ReasonChangeTrafficActionsRequest } from "../models/traffic-action/reasonchange-traffic-actions-request";
import { EditAnnouncementRequest } from "../models/announcement/edit-announcement-request";
import { TrafficActionRequest } from "../models/traffic-action/traffic-action-request";
import { Announcement } from "../models/announcement/announcement";
import { PopupService } from "./popup.service";
import { AnnouncementCategoryGroup } from "../models/announcement/announcement-category-group";
import { StatusItem } from "../models/status/status-response";
import { AnnouncementState } from "../models/announcement/announcement-enums";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle everything regarding traffic actions */
@Injectable({
  providedIn: "root",
})
@AutoUnsubscribe()
export class TrafficActionService implements ITrafficActionService, OnDestroy {
  public templates: DropdownValue[];
  public templatesPopulated = new Subject<boolean>();

  private _selectTrafficAction = new BehaviorSubject<TrafficAction>(null);
  public selectedTrafficAction = this._selectTrafficAction.asObservable();

  private readonly templateSegment = "ActionTemplates";
  private readonly generateActionSegment = "GenerateAction";
  private readonly getActionSegment = "GetAction";
  private readonly getActionWithFilterSegment = "GetActionWithFilter";
  private readonly getActionBasicSegment  = "GetActionBasic";
  private readonly getActionForAnnouncementSegment = "GetActionForAnnouncement";
  private readonly changeReasonSegment = "UpdateReasonOnActions";
  private readonly editAnnouncementSegment = "EditOrAddAnnouncement";
  private readonly saveSegment = "Save";
  private readonly updateSegment = "Update";
  private readonly actionSegment = "Action";
  private readonly headers = { "Api-Version": environment.actionApi.version };

  private readonly _trafficActions = new BehaviorSubject<
    TrafficActionListItem[]
  >([]);
  public readonly trafficActions = this._trafficActions.asObservable();

  constructor(
    private httpClient: HttpClient,
    private popupService: PopupService,
    private urlBuilderService: UrlBuilderService
  ) {}

  //#region Public
  /**  Gets the templates from the API and return a subject that will emit a boolean when templates are populated. */
  public initiateTrafficActionTemplateList(): Observable<boolean> {
    this.populateTrafficActionTemplates();
    return this.templatesPopulated;
  }

  /** Get stored templates */
  public getTemplates() {
    return this.templates;
  }

  public getSelectTrafficAction(): TrafficAction {
    return this._selectTrafficAction.getValue();
  }

  public selectTrafficAction(trafficAction: TrafficAction) {
    this._selectTrafficAction.next(trafficAction);
  }
  public getTrafficActions(): TrafficActionListItem[] {
    return this._trafficActions.getValue();
  }

  public setTrafficActions(value: TrafficActionListItem[]) {
    this._trafficActions.next(value);
  }

  public setStatusOnAnnouncements(statusItems: StatusItem[]) {
    const actionAnnouncements = this._selectTrafficAction
      .getValue()
      ?.announcementCategoryGroups.map(function (g) {
        return g.announcements;
      })
      .reduce(function (a, b) {
        return a.concat(b);
      });

    if (actionAnnouncements) {
      let announcementIds = actionAnnouncements
        .filter((x) => x.id > 0)
        .map((x) => x.id);
      const filteredStatuses = statusItems.filter((s) =>
        announcementIds.includes(s.id)
      );
      filteredStatuses.forEach((statusItem) => {
        this._selectTrafficAction
          .getValue()
          .announcementCategoryGroups.forEach((g) => {
            let matchingAnnouncement = g.announcements.find(
              (a) => a.id === statusItem.id
            );
            if (matchingAnnouncement) {
              matchingAnnouncement.status = statusItem.s;
            }
          });
      });
    }
  }

  public removeAnnouncementFromTrafficAction(
    announcement: Announcement
  ): TrafficAction {
    if (announcement.state == AnnouncementState.New) {
      //Announcement that only exist in client
      const index = this._selectTrafficAction
        .getValue()
        .announcementCategoryGroups.find(
          (item) => item.categoryType === announcement.categoryType
        )
        .announcements.indexOf(announcement);
      if (index < 0) {
        return;
      }
      this._selectTrafficAction
        .getValue()
        .announcementCategoryGroups.find(
          (item) => item.categoryType === announcement.categoryType
        )
        .announcements.splice(index, 1);
      this._selectTrafficAction.next(this._selectTrafficAction.getValue());
      //Mark effected trains in some way
    } else {
      let trafficActionAnnouncement = this._selectTrafficAction
        .getValue()
        .announcementCategoryGroups.find(
          (item) => item.categoryType === announcement.categoryType
        )
        .announcements.find((x) => x === announcement);
      trafficActionAnnouncement.toBeDeleted = true;
      trafficActionAnnouncement.state = AnnouncementState.Removed;

      let trafficAction = this._selectTrafficAction.getValue();

      this.setTrainsThatAreConnectedToAnnouncementToIsActivityTrain(
        trafficActionAnnouncement,
        trafficAction
      );

      this._selectTrafficAction.next(this._selectTrafficAction.getValue());
    }
  }

  //Are needed to set the color to deep blue upon removing announcements that affect trains in traffic action.
  setTrainsThatAreConnectedToAnnouncementToIsActivityTrain(
    trafficActionAnnouncement: Announcement,
    trafficAction: TrafficAction
  ) {
    trafficActionAnnouncement.trainNumbers.forEach((x) => {
      let existingTrain = trafficAction.trains.find((y) => {
        y.trainNumber == x.trainNumber;
      });

      if (!existingTrain) {
        trafficAction.trains.push(x);
      }
      trafficAction.trains.map(function (train) {
        if (train.trainNumber == x.trainNumber) {
          train.isActivityTrain = true;
        }
        return train;
      });
    });
  }

  public getTrafficAction(trafficActionId: number, filter?: Filter): Observable<TrafficAction> {
    
    let result: Observable<TrafficAction>;
    let url = this.urlBuilderService.buildUrl([
      environment.actionApi.path,
      this.actionSegment,
      filter ? this.getActionWithFilterSegment : this.getActionSegment,
      `${trafficActionId}`
    ]);

    if(filter){
      result = this.httpClient
      .post<TrafficAction>(
        url,
        filter,
        { headers: this.headers }
      );
    }
    else{
      result = this.httpClient
      .get<TrafficAction>(
        url,
        { headers: this.headers }
      );
    }
    
    return result
    .pipe(
      map((iAction: ITrafficAction) => {
        if (iAction) {
          let action = new TrafficAction(iAction);
          action.templateType = iAction.templateType;
          action.announcementCategoryGroups.forEach((x) => {
            this.sortAnnouncements(x),
              x.announcements.forEach(
                (y) => (y.categoryType = x.categoryType)
              );
            x.announcements.forEach(
              (y) => y.state == this.GetAnnouncementState(y)
            );
          });
          return action;
        }
      })
    );
  }

  public getTrafficActionBasic(trafficActionId: number): Observable<TrafficAction> {
    return this.httpClient
      .get<TrafficAction>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.actionSegment,
          this.getActionBasicSegment,
          `${trafficActionId}`,
        ]),
        { headers: this.headers }
      )
      .pipe(
        map((iAction: ITrafficAction) => {
          if (iAction) {
            let action = new TrafficAction(iAction);
            return action;
          }
        })
      );
  }

  public getTrafficActionForAnnouncement(announcementId: number): Observable<TrafficAction> {
    return this.httpClient
      .get<TrafficAction>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.actionSegment,
          this.getActionForAnnouncementSegment,
          `${announcementId}`,
        ]),
        { headers: this.headers }
      )
      .pipe(
        map((iAction: ITrafficAction) => {
          if (iAction) {
            let action = new TrafficAction(iAction);
            action.templateType = iAction.templateType;
            action.announcementCategoryGroups.forEach((x) => {
              this.sortAnnouncements(x),
                x.announcements.forEach(
                  (y) => (y.categoryType = x.categoryType)
                );
              x.announcements.forEach(
                (y) => y.state == this.GetAnnouncementState(y)
              );
            });
            return action;
          }
        })
      );
  }

  public generateTrafficAction(
    generateActionRequest: GenerateActionRequest
  ): Observable<TrafficAction> {
    return this.httpClient
      .post<TrafficAction>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.actionSegment,
          this.generateActionSegment,
        ]),
        generateActionRequest,
        { headers: this.headers }
      )
      .pipe(
        map((trafficAction: TrafficAction) => {
          trafficAction.announcementCategoryGroups.forEach((x) => {
            this.sortAnnouncements(x),
              x.announcements.forEach((y) => (y.categoryType = x.categoryType));
          });
          return trafficAction;
        })
      );
  }

  /** Create a new traffic action */
  public createTrafficAction(
    actionRequest: TrafficActionRequest
  ): Observable<TrafficAction> {
    return this.httpClient
      .post<TrafficAction>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.actionSegment,
          this.saveSegment,
        ]),
        actionRequest,
        { headers: this.headers }
      )
      .pipe(
        map((action: TrafficAction) => {
          action = new TrafficAction(action);
          action.announcementCategoryGroups.forEach((x) => {
            this.sortAnnouncements(x),
              x.announcements.forEach((y) => (y.categoryType = x.categoryType));
            x.announcements.forEach(
              (y) => y.state == this.GetAnnouncementState(y)
            );
          });
          return action;
        })
      );
  }

  /** Update a traffic action */
  public updateTrafficAction(
    actionRequest: TrafficActionRequest
  ): Observable<TrafficAction> {
    return this.httpClient
      .put<TrafficAction>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.actionSegment,
          this.updateSegment,
        ]),
        actionRequest,
        { headers: this.headers }
      )
      .pipe(
        map((action: TrafficAction) => {
          action = new TrafficAction(action);
          action.announcementCategoryGroups.forEach((x) => {
            this.sortAnnouncements(x),
              x.announcements.forEach((y) => (y.categoryType = x.categoryType));
            x.announcements.forEach(
              (y) => y.state == this.GetAnnouncementState(y)
            );
          });
          return action;
        })
      );
  }

  /** Add/edit announcement to an traffic action */
  public addOrEditAnnouncement(
    editRequest: EditAnnouncementRequest
  ): Observable<TrafficAction> {
    return this.httpClient
      .post<TrafficAction>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.actionSegment,
          this.editAnnouncementSegment,
        ]),
        editRequest,
        { headers: this.headers }
      )
      .pipe(
        map((action: TrafficAction) => {
          action = new TrafficAction(action);
          action.announcementCategoryGroups.forEach((x) => {
            this.sortAnnouncements(x),
              x.announcements.forEach((y) => (y.categoryType = x.categoryType));
            x.announcements.forEach(
              (y) => y.state == this.GetAnnouncementState(y)
            );
          });
          return action;
        })
      );
  }

  public updateReasonOnTrafficActions(
    request: ReasonChangeTrafficActionsRequest
  ): Observable<boolean> {
    return this.httpClient
      .put<ReasonChangeTrafficActionsRequest>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.actionSegment,
          this.changeReasonSegment,
        ]),
        request,
        { headers: this.headers }
      )
      .pipe(
        map(() => {
          const result: boolean = true;
          return result;
        })
      );
  }

  /** Get a new traffic action model based on event properties */
  public getNewTrafficActionModel(
    eventId: number,
    reasonId: number,
    trainNumbers: Train[],
    filter: Filter
  ) {
    return new TrafficAction({
      id: null,
      eventId: eventId,
      reasonId: reasonId,
      templateType: 1,
      filterGeneratedTrains: trainNumbers,
      trains: [],
      announcementCategoryGroups: [],
      title: null,
      reminderInMinutes: 15,
      filter: filter,
    });
  }
  //#endregion

  //#region private
  private getTrafficActionTemplates(): Observable<TrafficActionTemplate[]> {
    return this.httpClient.get<TrafficActionTemplate[]>(
      this.urlBuilderService.buildUrl([
        environment.actionApi.path,
        this.templateSegment,
      ]),
      { headers: this.headers }
    );
  }

  private createSelectValueFromTrafficActionTemplate(
    trafficActionTemplate: TrafficActionTemplate
  ) {
    return new DropdownValue(
      trafficActionTemplate.title,
      trafficActionTemplate.templateType
    );
  }

  private populateTrafficActionTemplates() {
    this.getTrafficActionTemplates().subscribe(
      (trafficActionTemplates) => {
        this.templates = trafficActionTemplates
          .map((x) => this.createSelectValueFromTrafficActionTemplate(x))
          .sort((a, b) => (a.value > b.value ? -1 : 1));
        this.templatesPopulated.next(true);
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.popupService.setErrorPopup(error);
        this.templatesPopulated.next(false);
      }
    );
  }

  sortAnnouncements(announcementCategory: AnnouncementCategoryGroup) {
    announcementCategory.announcements
      .sort((a, b) => {
        if (a.priority === 0 || a.priority > b.priority) {
          return 1;
        } else if (a.priority < b.priority) {
          return -1;
        }
        // If same priority, sort by title
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        } else {
          // nothing to split them on
          return 0;
        }
      });
  }

  GetAnnouncementState(announcement: Announcement) {
    if (announcement.toBeDeleted) return AnnouncementState.Removed;

    if (announcement.state == AnnouncementState.Changed)
      return AnnouncementState.Changed;

    if (!announcement.id && !announcement.onlyInTrv)
      return AnnouncementState.New;

    return AnnouncementState.Unchanged;
  }

  //#endregion
  ngOnDestroy() { }
}
