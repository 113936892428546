import { AnnouncementProperty } from "./announcement-property";

export interface IAnnouncementCategoryText {
  id: number;
  code: string;
  text: string;
  externalOperatorCanUse: boolean;
  properties: AnnouncementProperty[];
}
export class AnnouncementCategoryText implements IAnnouncementCategoryText {
  id: number;
  code: string;
  text: string;
  externalOperatorCanUse: boolean;
  properties: AnnouncementProperty[];

  constructor(categoryText: IAnnouncementCategoryText) {
    this.id = categoryText.id;
    this.code = categoryText.code;
    this.text = categoryText.text;
    this.externalOperatorCanUse = categoryText.externalOperatorCanUse;
    this.properties = categoryText.properties;
  }
}
