import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'dua-train-series-button',
  templateUrl: './train-series-button.component.html',
  styleUrls: ['./train-series-button.component.scss']
})
export class TrainSeriesButtonComponent implements OnInit {

  @Input() trainSerie:string;
  @Output() removeSeries: EventEmitter<string> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  remove(event: MouseEvent){
    event.stopPropagation();
    this.removeSeries.emit(this.trainSerie);
  }

}
