import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "lodash";
import { forkJoin, Observable, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import { DropdownGroup } from "../models/dropdown-group";
import { DropdownValue } from "../models/dropdown-value";
import { Application } from "../models/enums";
import { Reason } from "../models/reason";
import { IReasonService } from "./interfaces/i-reason.service";
import { PopupService } from "./popup.service";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle reasons and Reason API */
@Injectable({
  providedIn: "root",
})
export class ReasonService implements IReasonService {
  public reasonsPopulated = new Subject<boolean>();
  public loading: boolean;

  private readonly apiSegment = "reason";
  private readonly getSegment = "Get";
  private readonly headers = { "Api-Version": environment.reasonApi.version };

  private noneReason: Reason;

  private filteredReasons: DropdownValue[] = [];
  private groupedReasons: DropdownGroup[] = [];

  constructor(
    private httpClient: HttpClient,
    private popupService: PopupService,
    private urlBuilderService: UrlBuilderService
  ) {}

  //#region Public

  /**  Gets the reasons from the API and return a subject that will emit a boolean when reasons are populated. */
  initiateReasonLists(): Observable<boolean> {
    this.populateReasons();
    return this.reasonsPopulated;
  }

  /** Returns TRV reason or, if internal reason, the "none" reason */
  getMappedTrafficActionReason(reasonId: number): number {
    if (this.filteredReasons.find((x) => x.value == reasonId)) {
      return reasonId;
    }
    return this.noneReason.id;
  }

  getFilteredReasons(): DropdownValue[] {
    return this.filteredReasons;
  }

  getGroupedReasons(): DropdownGroup[] {
    return this.groupedReasons;
  }

  getTrvReasonText(reasonId: number): string {
    let matchedReason = this.filteredReasons.find((x) => x.value == reasonId);
    if (matchedReason && matchedReason.label.toLowerCase() !== "ingen") {
      return matchedReason.label;
    }
    return "";
  }

  //#region Private
  /* Populates reason arrays for use in components */
  private populateReasons() {
    const reasonObservables: Observable<Reason[]> = this.getAllReasons();

    reasonObservables.subscribe(
      (reasons) => {
        this.loading = true;
        this.populateFilteredReasons(reasons);

        this.populateGroupedReasons(reasons);
        this.reasonsPopulated.next(true);
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        console.error("error fetching reasons", error);
        this.popupService.setErrorPopup(error);
        this.reasonsPopulated.next(false);
        this.loading = false;
      }
    );
  }

  /** Populates the grouped reasons array which are grouped on Application (Internal or TRV) */
  private populateGroupedReasons(reasons: Reason[]) {
    let mtrReasons = reasons
      .filter((x) => x.applicationId == Application.MTR)
      .map((x) => this.createSelectValueFromReason(x));
    let trvReasons = reasons
      .filter((x) => x.applicationId == Application.TRV)
      .map((x) => this.createSelectValueFromReason(x));
    this.groupedReasons = [];
    this.groupedReasons.push(new DropdownGroup("Interna", mtrReasons));
    this.groupedReasons.push(new DropdownGroup("Trafikverket", trvReasons));
  }

  /** Populates the filtered reasons array which holds TRV reasons and a "none" reason */
  private populateFilteredReasons(reasons: Reason[]) {
    this.noneReason = reasons.find((x) => x.applicationId == Application.MTR);
    this.filteredReasons = reasons
      .filter(
        (x) => x.applicationId != Application.MTR || x.id == this.noneReason.id
      )
      .map((x) => this.createSelectValueFromReasonForAction(x));

    let mtrReasons = reasons
      .filter((x) => x.applicationId == Application.MTR)
      .map((x) => x.id);
    this.filteredReasons.sort((a, b) => {
      //Put none reason on top
      if (mtrReasons.lastIndexOf(a.value) != -1) {
        return -1;
      } else {
        return a.label.localeCompare(b.label);
      }
    });
  }

  public getNoneReason() {
    return this.noneReason;
  }

  /** Creates a select value that can be used in a primeNG dropdown for Event*/
  private createSelectValueFromReason(reason: Reason) {
    return new DropdownValue(
      reason.text13Characters ?? reason.description,
      reason.id
    );
  }

  /** Creates a select value that can be used in a primeNG dropdown for Action*/

  private createSelectValueFromReasonForAction(reason: Reason) {
    if (reason.applicationId == Application.MTR) {
      return new DropdownValue("Ingen", reason.id);
    }
    return new DropdownValue(
      reason.text13Characters ?? reason.description,
      reason.id
    );
  }
  /** Get all reasons available */
  private getAllReasons(): Observable<Reason[]> {
    return this.httpClient.get<Reason[]>(
      this.urlBuilderService.buildUrl([
        environment.reasonApi.path,
        this.apiSegment,
        this.getSegment,
      ]),
      { headers: this.headers }
    );
  }
}
