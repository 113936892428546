import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { protectedResourceMap } from "src/app/app.module";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private msalService: MsalService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const activeAccount = this.msalService.instance.getActiveAccount();
        if(activeAccount) {
            const scope = this.getScope(req.url)
            return this.msalService.acquireTokenSilent({
                account: activeAccount, scopes: [scope]
              })
                .pipe(
                  switchMap((authRequest) => {
                    const clonedReq = req.clone({
                      headers: req.headers.set("Authorization",`Bearer ${authRequest.accessToken}`)
                    })
                    return next.handle(clonedReq)
                  }))
        }
        return next.handle(req)
    }

    getScope(reqUrl: string) : string {
        let splitUrl: string[]
        if(reqUrl.includes(environment.apiUrl)) {
            if(!reqUrl.includes('/api/')) {
                let actionSignalRpath = environment.actionApi.path.replace('/api', '');
                let notificationSignalRpath = environment.actionApi.path.replace('/api', '');
                if(reqUrl.includes(actionSignalRpath)) {
                    return protectedResourceMap.get(environment.actionApi.path)![0]
                } else if(reqUrl.includes(notificationSignalRpath)) {
                    return protectedResourceMap.get(environment.notificationApi.path)![0]
                }
            } else {
                if(reqUrl.includes(environment.actionApi.path)) {
                    return protectedResourceMap.get(environment.actionApi.path)![0]
                } else if (reqUrl.includes(environment.reasonApi.path)) {
                    return protectedResourceMap.get(environment.reasonApi.path)![0]
                } else if (reqUrl.includes(environment.stationsApi.path)) {
                    return protectedResourceMap.get(environment.stationsApi.path)![0]
                } else if (reqUrl.includes(environment.linenumberApi.path)) {
                    return protectedResourceMap.get(environment.linenumberApi.path)![0]
                } else if (reqUrl.includes(environment.eventLogApi.path)) {
                    return protectedResourceMap.get(environment.eventLogApi.path)![0]
                } else if (reqUrl.includes(environment.notificationApi.path)) {
                    return protectedResourceMap.get(environment.notificationApi.path)![0]
                } else if (reqUrl.includes(environment.trainApi.path)) {
                    return protectedResourceMap.get(environment.trainApi.path)![0]
                } else if (reqUrl.includes(environment.versionApi.path)) {
                    return protectedResourceMap.get(environment.versionApi.path)![0]
                }
            }
        } else if (reqUrl.includes(environment.graphUrl)) {
          splitUrl = reqUrl.split("v1.0")
          return protectedResourceMap.get(splitUrl[0] + "v1.0")![0]
        }else{
          return "";
        }
      }
    
}