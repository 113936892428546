import { Injectable } from '@angular/core';
import { IListItemService } from './interfaces/i-list-item.service';

@Injectable({
  providedIn: 'root'
})
export class ListItemService implements IListItemService {

  constructor() { }

  public getIconFromStatus(notified: boolean, status: string): string {
    if(notified){
      return "notified";
    }
    return status?.toString().toLowerCase();
  }
  public getIconFromState(notified: boolean): string {
    if (notified) {
      return "notified";
    }
    return "new";
  }
}
