import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Filter } from "../models/event/filter";
import { TrafficAction } from "../models/traffic-action/traffic-action";
import { UpdateTrafficActionTrainsRequest } from "../models/traffic-action/update-traffic-action-trains";
import { Train } from "../models/train";
import { TrainStatus } from "../models/trainStatus";
import { ITrainService } from "./interfaces/i-train.service";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle train API */
@Injectable({
  providedIn: "root",
})
export class TrainService implements ITrainService {
  public removedTrainStatus: TrainStatus;
  public activeTrainStatus: TrainStatus;

  private readonly apiSegment = "Train";
  private readonly updateTrainsSegment = "UpdateTrains";
  private readonly queryTrainSegment = "query";

  private readonly actionApiHeaders = {
    "Api-Version": environment.actionApi.version,
  };
  private readonly trainApiHeaders = {
    "Api-Version": environment.trainApi.version,
  };

  constructor(
    private httpClient: HttpClient,
    private urlBuilderService: UrlBuilderService
  ) {}

  /** Get array of train numbers based on filter */
  public getTrains(filter: Filter): Observable<Train[]> {
    let request = JSON.parse(JSON.stringify(filter));
    if (filter.toDate <= filter.fromDate) {
      return of([]);
    }
    request.timeType = filter.timeType;
    return this.httpClient
      .post<string[]>(
        this.urlBuilderService.buildUrl([
          environment.trainApi.path,
          this.apiSegment,
          this.queryTrainSegment,
        ]),
        request,
        { headers: this.trainApiHeaders }
      )
      .pipe(
        map((trainNumbers: string[]) => {
          return trainNumbers.map((x) => new Train(x));
        })
      );
  }

  public updateTrains(
    updateTrainsRequest: UpdateTrafficActionTrainsRequest
  ): Observable<TrafficAction> {
    return this.httpClient
      .post<TrafficAction>(
        this.urlBuilderService.buildUrl([
          environment.actionApi.path,
          this.apiSegment,
          this.updateTrainsSegment,
        ]),
        updateTrainsRequest,
        { headers: this.actionApiHeaders }
      )
      .pipe(
        map((trafficAction: TrafficAction) => {
          trafficAction.announcementCategoryGroups.forEach((x) =>
            x.announcements.forEach((y) => (y.categoryType = x.categoryType))
          );
          return trafficAction;
        })
      );
  }
}
