import { Injectable, OnDestroy } from "@angular/core";
import { Subject, Subscription, Observable } from "rxjs";
import { debounceTime, filter, map, mergeMap, switchMap } from "rxjs/operators";
import { Announcement } from "../models/announcement/announcement";
import {
  EventCard,
  FilterAnnouncementType,
  NotificationItemType,
  TrafficActionType,
  UserRoles,
} from "../models/enums";
import { Event } from "../models/event/event";
import { EventsResponse } from "../models/event/events-response";
import { TrafficAction } from "../models/traffic-action/traffic-action";
import { AutoUnsubscribe } from "../shared/decorators";
import { AnnouncementService } from "./announcement.service";
import { AuthService } from "./auth.service";
import { EventContainerHeaderService } from "./event-container-header.service";
import { EventService } from "./event.service";
import { FilterService } from "./filter.service";
import { IEventContainerService } from "./interfaces/i-event-container.service";
import { NotificationService } from "./notification.service";
import { ReasonService } from "./reason.service";
import { PopupService } from "./popup.service";
import { SystemNotificationService } from "./system-notification.service";
import { TrafficActionService } from "./traffic-action.service";
import _ from "lodash";
import { HttpErrorResponse } from "@angular/common/http";
import { TrafficActionListItem } from "../models/traffic-action/traffic-action-list-item";
import { RequestObjectBuilderService } from "./request-object-builder.service";
import { TrainService } from "./train.service";
import { Filter } from "../models/event/filter";
import { PagingService } from "./paging.service";

/** Service to handle the event container holding the Event list, Events, Traffic Actions, Announcements and Event Log */
@Injectable({
  providedIn: "root",
})
@AutoUnsubscribe()
export class EventContainerService
  implements IEventContainerService, OnDestroy {
  public loading: boolean;

  //#region Subjects for changes
  public currentEventCardChange = new Subject<EventCard>();
  public createModeChange = new Subject<boolean>();
  public filterAnnouncementChange = new Subject<string>();
  public eventsResponseChange = new Subject<EventsResponse>();
  public trafficActionChange = new Subject<TrafficAction>();
  //#endregion

  private filterAnnouncements: { [index: number]: string } = {};
  private selectedEvent: Event;
  private selectedTrafficAction: TrafficAction;
  private selectedAnnouncement: Announcement;
  private eventsResponse: EventsResponse;

  private currentEventCard: EventCard;
  private createMode: boolean;

  private newEventTitle = "Ny händelse";
  private newAnnouncementTitle = "Ny annonsering";
  private newTrafficActionTitle = "Ny Trafikåtgärd";
  public currentTrafficAction: TrafficAction;

  constructor(
    private requestObjectBuilderService: RequestObjectBuilderService,
    private filterService: FilterService,
    private headerService: EventContainerHeaderService,
    private authService: AuthService,
    private announcementService: AnnouncementService,
    private trafficActionService: TrafficActionService,
    private eventService: EventService,
    private reasonService: ReasonService,
    private popupService: PopupService,
    private trainService: TrainService,
    private notificationService: NotificationService,
    private pagingService: PagingService
  ) {
    this.initiateService();

    this.eventService.selectedEvent.subscribe(
      (event) => (this.selectedEvent = event)
    );
    this.trafficActionService.selectedTrafficAction.subscribe(
      (trafficAction) => (this.selectedTrafficAction = trafficAction)
    );
    this.announcementService.selectedAnnouncement.subscribe(
      (announcement) => (this.selectedAnnouncement = announcement)
    );
  }

  //#region Public

  public getTrafficActionChangeTrigger(): Observable<TrafficAction> {
    return this.trafficActionChange;
  }

  /** Returns a boolean saying if it's create mode or not (if not, it's update mode) */
  public isCreateMode() {
    return this.createMode;
  }

  /** Goes back to the event list from the current event card */
  public returnToEventList() {
    this.eventService.selectEvent(null);
    this.goToEventList();
    this.eventService.emitTriggerForGetEvents(true);
  }

  public setEventId(id: number) {
    this.selectedEvent.id = id;
  }

  public setEvent(event: Event) {
    this.selectedEvent = event;
  }

  /** Goes back to the Event from the current event card */
  public returnToEvent() {
    if (this.selectedEvent.id) {
      this.populateEvent(this.selectedEvent.id);
    } else {
      this.goToEventForm(this.selectedEvent);
    }
  }

  /** Goes back to the traffic action from the current event card */
  public returnToTrafficAction() {
    this.announcementService.selectAnnouncement(null);
    this.announcementService.selectedAnnouncementCategoryText = null;
    this.goToTrafficActionForm(
      this.trafficActionService.getSelectTrafficAction()
    );
  }

  /** Solution to be able to cancel calls within 300ms to /query if spamming changes to the filters. */
  private _populateEventsSubject: Subject<void>;
  public populateEvents() {
    if (!this._populateEventsSubject) {
      this._populateEventsSubject = new Subject();
      this._populateEventsSubject
        .pipe(
          debounceTime(300),
          switchMap(() => {
            this.loading = true;
            return this.eventService.getEvents();
          })
        )
        .subscribe(
          (response: EventsResponse) => {
            this.selectEventsResponse(response);

            this.goToEventList(true);
            if (!response || response.events.length == 0) {
              this.emitFilterAnnouncement(FilterAnnouncementType.EventList);
            }

            this.loading = false;
          },
          (error: HttpErrorResponse) => {
            this.loading = false;
            this.goToEventList(true);
            this.emitFilterAnnouncement(FilterAnnouncementType.Error);
            this.popupService.setErrorPopup(error);
            console.error(error);
          }
        );
    }

    this._populateEventsSubject.next();
  }

  /** Populate data for an existing event and move to the event card */
  populateEvent(id: number) {
    this.loading = true;
    this.eventService.getEvent(id).subscribe(
      (event) => {
        this.eventService.selectEvent(event);
        this.goToEventForm(event, false);
        this.loading = false;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.popupService.setErrorPopup(error);
        console.error(error);
      }
    );
  }

  populateTrafficAction(id: number) {
    this.loading = true;
    this.trafficActionService
      .getTrafficAction(id)
      .pipe(
        map(
          (trafficAction) => {
            this.trafficActionService.selectTrafficAction(trafficAction);
            this.goToTrafficActionForm(trafficAction);
            this.loading = false;

            return trafficAction;
          },
          (error: HttpErrorResponse) => {
            this.popupService.setErrorPopup(error);
          }
        ),
        mergeMap((trafficAction) =>
          this.eventService.getEvent(trafficAction.eventId)
        ),
        map((event) => {
          this.eventService.selectEvent(event);
        })
      )
      .subscribe();
  }

  populateTrafficActionForAnnouncement(announcementId: number) {
    this.loading = true;
    this.trafficActionService.getTrafficActionForAnnouncement(announcementId)
      .pipe(
        map(
          (trafficAction) => {
            this.trafficActionService.selectTrafficAction(trafficAction);
            // Extracting announcement object from "trafficAction"
            let announcement = trafficAction.announcementCategoryGroups
              .find(m => m.announcements.some(n => n.id == announcementId))
              .announcements.find(m => m.id == announcementId);
            this.openExistingAnnouncement(announcement);
            this.loading = false;

            return trafficAction;
          },
          (error: HttpErrorResponse) => {
            this.popupService.setErrorPopup(error);
          }
        ),
        mergeMap((trafficAction) =>
          this.eventService.getEvent(trafficAction.eventId)
        ),
        map((event) => {
          this.eventService.selectEvent(event);
        })
      )
      .subscribe();
  }

  /** Open the traffic action card for a new traffic action */
  initiateNewTrafficAction() {
    let trafficAction = new TrafficAction({
      id: null,
      eventId: this.selectedEvent.id,
      reasonId: this.getTrafficActionReason(),
      templateType: TrafficActionType.OtherAdjustment,
      title: "",
      reminderInMinutes: null,
      filterGeneratedTrains: this.selectedEvent?.filterGeneratedTrains,
      trains: [],
      announcementCategoryGroups: [],
      filter: _.cloneDeep(this.selectedEvent.filter),
    });
    trafficAction.filter.id = 0; //Do not take the id from the event's filter
    let generateTrafficActionRequest = this.requestObjectBuilderService.buildGenerateTrafficActionRequest(
      trafficAction
    );
    this.trafficActionService
      .generateTrafficAction(generateTrafficActionRequest)
      .subscribe((generatedTrafficAction) => {
        trafficAction.id = generatedTrafficAction.id;
        trafficAction.title = generatedTrafficAction.title;
        trafficAction.reasonId = generatedTrafficAction.reasonId;
        trafficAction.eventId = generatedTrafficAction.eventId;
        trafficAction.templateType = generatedTrafficAction.templateType;
        trafficAction.reminderInMinutes =
          generatedTrafficAction.reminderInMinutes;
        trafficAction.announcementCategoryGroups =
          generatedTrafficAction.announcementCategoryGroups;
        trafficAction.filter = generatedTrafficAction.filter;
        this.trafficActionChange.next(trafficAction);
      });
    this.trafficActionService.selectTrafficAction(trafficAction);
    this.goToTrafficActionForm(trafficAction);
  }

  initiateEditTrafficAction(trafficActionId: number, notificationId: number) {
    this.trafficActionService.getTrafficAction(trafficActionId).subscribe(
      (trafficAction) => {
        if (notificationId) {
          this.notificationService.setReadStatus([notificationId]);
        }
        const filter:Filter = trafficAction.filter;
        this.trainService.getTrains(filter).subscribe(
          (trains) => {
            trafficAction.filterGeneratedTrains = trains;
            this.trafficActionService.selectTrafficAction(trafficAction);
            this.currentTrafficAction = _.cloneDeep(trafficAction);
            this.goToTrafficActionForm(trafficAction);
          },
          (error: HttpErrorResponse) => {
            this.popupService.setErrorPopup(error);
          }
        );
      },
      (error: HttpErrorResponse) => {
        this.popupService.setErrorPopup(error);
      }
    );
  }

  initiateNewEvent() {
    const filter = this.filterService.getFilterCopy();
    const event = new Event({
      id: null,
      comment: "",
      reasonId: -1,
      title: null,
      filter: filter,
      filterGeneratedTrains: [],
      trains: [],
      actions: [],
    });

    this.eventService.selectEvent(event);
    this.goToEventForm(event, true);
  }

  /** Switch to announcement form card and create a new announcement object based on dates from filter and stations and train numbers from selected traffic action */
  openAnnouncementForm() {
    this.goToAnnouncementForm();
  }

  /** Switch to announcement form card and open with the selectedannouncement*/
  openExistingAnnouncement(selectedAnnouncement: Announcement) {
    this.announcementService.emitSelectAnnouncementText(
      selectedAnnouncement?.categoryText
    );
    this.announcementService.selectAnnouncement(selectedAnnouncement);
    this.goToAnnouncementForm();
  }

  /** After an event has been saved, trigger this to switch create mode to false or go back to event list */
  afterSaveEvent(event: Event) {
    this.eventService.selectEvent(event);
    if (!event) {
      this.populateEvents();
    } else {
      this.goToEventForm(event, false);
    }
  }

  //#region Switch event card

  /** Goes to event form, using currently selected event as data.
   * @param createMode - if a value is passed, create mode is changed, create mode means that the forms will act as if the object has not yet been saved to the database. If no value is passed, the previous state continues.
   */
  goToEventForm(event: Event, createMode?: boolean) {
    if (createMode != null && createMode != undefined) {
      this.switchCreateMode(createMode);
    }
    this.filterService.switchFilterState(
      EventCard.EventForm,
      true,
      event.filter
    );
    this.switchEventCard(EventCard.EventForm);
    this.loading = false;
    this.headerService.setContainerHeader(
      this.currentEventCard,
      this.createMode ? this.newEventTitle : event?.title,
      !this.createMode
    );
  }

  /** Populates the selected traffic action if a traffic action is passed. Goes to traffic action form, using currently selected traffic action as data.
   */
  goToTrafficActionForm(trafficAction?: TrafficAction) {
    this.filterService.switchFilterState(
      EventCard.TrafficActionForm,
      true,
      trafficAction.filter
    );
    this.switchEventCard(EventCard.TrafficActionForm);
    this.loading = false;
    this.headerService.setContainerHeader(
      this.currentEventCard,
      trafficAction?.title ? trafficAction.title : this.newTrafficActionTitle
    );
  }

  private initiateService() {
    this.populateFilterAnnouncementTexts();

    SystemNotificationService.getOpenEventCardSubject().subscribe(
      (notification) => {
        const itemType: NotificationItemType = (typeof(notification.itemType) === 'string') ? (<any>NotificationItemType)[notification.itemType] : notification.itemType;
        switch (itemType) {
          case NotificationItemType.Event:
            this.populateEvent(notification.itemId);
            break;
          case NotificationItemType.TrafficAction:
            this.openTrafficActionFromNotification(notification.itemId);
            break;
          case NotificationItemType.Announcement:
            this.openAnnouncementFromNotification(notification.itemId);
            break;
        }
        this.notificationService.setReadStatus([notification.id]);
      }
    );
  }

  /** Populates filtering announcements to be shown in the GUI for easy access */
  private populateFilterAnnouncementTexts() {
    this.filterAnnouncements[FilterAnnouncementType.Error] =
      "Något gick fel vid hämtning av händelser. Ladda om sidan och försök igen.";
    this.filterAnnouncements[FilterAnnouncementType.EventList] =
      "Ditt urval matchar inte några händelser.";
  }

  /** Checks that the user has write access */
  private hasWriteAccess() {
    return this.authService.userHasMinimumRole(UserRoles.Write);
  }

  /** Get mapped traffic action reason from selected event */
  private getTrafficActionReason() {
    return this.reasonService.getMappedTrafficActionReason(
      this.selectedEvent.reasonId
    );
  }

  /** populates currentEventCard with the passed event card and emits event card to subscribers of scurrentEventCardChange */
  private switchEventCard(newEventCard: EventCard) {
    this.currentEventCard = newEventCard;
    this.currentEventCardChange.next(this.currentEventCard);
  }

  /** populates createMode with the passed boolean and emits boolean to subscribers of createModeChange */
  private switchCreateMode(createMode: boolean) {
    this.createMode = createMode;
    this.createModeChange.next(this.createMode);
  }

  /** selects a filter announcement and emits it to subscribers of filterAnnouncementChange */
  private emitFilterAnnouncement(
    filterAnnouncementType: FilterAnnouncementType
  ) {
    const filterAnnouncement = this.getFilterAnnouncement(
      filterAnnouncementType
    );

    this.filterAnnouncementChange.next(filterAnnouncement);
  }

  private selectEventsResponse(response: EventsResponse) {
    this.eventsResponse = response;
    this.eventsResponseChange.next(this.eventsResponse);
  }

  //#endregion

  /** Get filter announcement for specified filter announcement type */
  private getFilterAnnouncement(announcementType: FilterAnnouncementType) {
    return this.filterAnnouncements[announcementType] ?? "";
  }

  /** Goes to event list, resetting create mode to false.
   */
  private goToEventList(removeOldFilter = false) {
    this.filterService.switchFilterState(EventCard.EventList, removeOldFilter);
    this.switchCreateMode(false);
    this.switchEventCard(EventCard.EventList);
    this.headerService.setContainerHeader(
      this.currentEventCard,
      `Händelser (${this.eventsResponse?.totalItems ?? 0})`,
      this.hasWriteAccess()
    );
    // Due to the removing filter requirement when go back to the list,
    // page number should also reset to page 1 (which is index 0)
    if (removeOldFilter) {
      this.pagingService.setPageNumber(0);
    }
  }

  /** Goes to announcement form, using currently selected announcement as data.
   */
  private goToAnnouncementForm() {
    this.filterService.switchFilterState(
      EventCard.Announcement,
      true,
      this.selectedAnnouncement.filter
    );
    this.switchEventCard(EventCard.Announcement);
    this.headerService.setContainerHeader(
      this.currentEventCard,
      this.selectedAnnouncement?.title
        ? this.selectedAnnouncement.title
        : this.newAnnouncementTitle
    );
  }

  /** Open an announcement from a notification
   * @todo remove hardcoded values when there is backend support to get traffic action without knowing event
   */
  private openTrafficActionFromNotification(trafficActionId: number) {
    //remove currently selected event as it may not match the event from the traffic action
    this.selectedEvent = null;

    //get traffic action
    this.trafficActionService.getTrafficAction(trafficActionId).subscribe(
      (trafficAction) => {
        this.goToTrafficActionForm(trafficAction);
      },
      (error: HttpErrorResponse) => {
        this.popupService.setErrorPopup(error);
      }
    );
  }

  /** Open an announcement from a notification
   * @todo remove hardcoded values when there is backend support to get announcment without knowing event or traffic action
   */
  private openAnnouncementFromNotification(announcementId: number) {
    //remove currently selected event as it may not match the event from the announcement
    this.selectedEvent = null;
    //remove currently selected traffic action as it may not match the traffic action from the announcement
    this.selectedTrafficAction = null;
    //get announcement
    // this.announcementService
    //   .getAnnouncement(announcementId)
    //   .subscribe((announcement) => {
    //     this.announcementService.selectAnnouncement(announcement);
    //     this.goToAnnouncementForm();
    //   });
  }

  //#endregion

  ngOnDestroy() {}
}
