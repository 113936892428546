/* File to contain the MSAL configs to keep it out of app.module.ts */

import { environment } from "../../environments/environment";
import {
  IPublicClientApplication,
  PublicClientApplication,
  LogLevel,
  BrowserCacheLocation,
  InteractionType
} from "@azure/msal-browser";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from "@azure/msal-angular";

const scopeUserRead: string = "user.read";
const protectedResourceMap: Map<string, Array<string>> = new Map<string, Array<string>>([
  [environment.apiUrl, environment.config.apiScopes],
  ["https://graph.microsoft.com/v1.0/me", [scopeUserRead]],
  ["https://graph.microsoft.com/v1.0", [scopeUserRead]],
]);

const isIE =
  window.navigator.userAgent.indexOf("MSIE ") > -1 ||
  window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.config.clientID,
      authority:
        "https://login.microsoftonline.com/" + environment.config.tenantID,
      redirectUri: environment.config.redirectUrl,
      postLogoutRedirectUri: environment.config.url,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.config.scopes, ...environment.config.apiScopes]
    }
  };
}
