import { AfterContentInit, Component, OnDestroy, OnInit } from "@angular/core";
import { forkJoin, Subscription } from "rxjs";
import { StatusItem, StatusResponse } from "src/app/models/status/status-response";
import { TrafficAction } from "src/app/models/traffic-action/traffic-action";
import { AnnouncementService } from "src/app/services/announcement.service";
import { NotificationService } from "src/app/services/notification.service";
import { Announcement } from "../../models/announcement/announcement";
import {
  EventCard,
  NotificationItemType,
} from "../../models/enums";
import { Event } from "../../models/event/event";
import { EventsResponse } from "../../models/event/events-response";
import { EventContainerHeaderService } from "../../services/event-container-header.service";
import { EventContainerService } from "../../services/event-container.service";
import { EventService } from "../../services/event.service";
import { ReasonService } from "../../services/reason.service";
import { SortService } from "../../services/sort.service";
import { TrafficActionService } from "../../services/traffic-action.service";
import { AutoUnsubscribe } from "../../shared/decorators";
import { PopupService } from "../../services/popup.service";
import { FilterService } from "../../services/filter.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "dua-event-container",
  templateUrl: "./event-container.component.html",
  styleUrls: ["./event-container.component.scss"],
})
@AutoUnsubscribe()
export class EventContainerComponent
  implements OnInit, AfterContentInit, OnDestroy {
  selectedEvent: Event;
  selectedTrafficAction: TrafficAction;
  selectedAnnouncement: Announcement;

  eventsResponse: EventsResponse;
  createMode = false;
  currentEventCard: EventCard;

  EventCard = EventCard;

  private filterSubscription: Subscription;
  private sortSubscription: Subscription;
  private populateViewObservables = [];

  filterAnnouncement: string;

  constructor(
    private eventContainerService: EventContainerService,
    private eventService: EventService,
    private sortService: SortService,
    private reasonService: ReasonService,
    private trafficActionService: TrafficActionService,
    private headerService: EventContainerHeaderService,
    private notificationService: NotificationService,
    private announcementService: AnnouncementService,
    private popupService: PopupService,
    private filterService: FilterService,
  ) {}

  ngOnInit(): void {
    this.eventService.selectedEvent.subscribe(
      (event) => (this.selectedEvent = event)
    );
    this.trafficActionService.selectedTrafficAction.subscribe(
      (trafficAction) => (this.selectedTrafficAction = trafficAction)
    );
    this.announcementService.selectedAnnouncement.subscribe(
      (announcement) => (this.selectedAnnouncement = announcement)
    );
  }

  ngAfterContentInit(): void {
    this.initiateComponent();
    this.ConnectToSignalrAndRegisterCallbacks();
  }

  //#region Public
  enterEventLog() {
    this.currentEventCard = EventCard.EventLog;
    this.headerService.setContainerHeader(this.currentEventCard);
  }

  goForward() {
    switch (this.currentEventCard) {
      case EventCard.EventList:
        this.enterCreateMode();
        break;
      case EventCard.EventForm:
        this.enterEventLog();
        break;
      default:
        console.error("Not implemented");
    }
  }

  goBack() {
    switch (this.currentEventCard) {
      case EventCard.EventForm:
        this.eventContainerService.returnToEventList();
        break;
      case EventCard.TrafficActionForm:
        this.eventContainerService.returnToEvent();
        break;
      case EventCard.EventLog:
        this.eventContainerService.returnToEvent();
        break;
      case EventCard.Announcement:
        this.eventContainerService.returnToTrafficAction();
        break;
      default:
        console.error("Not implemented");
    }
  }

  isLoading() {
    return this.eventContainerService.loading;
  }

  //#endregion

  private ConnectToSignalrAndRegisterCallbacks() {
    let callbackFunctions: Function[] = [];
    callbackFunctions.push(this.ReceiveStatusUpdate.bind(this));
    callbackFunctions.push(this.FetchUpdatedEventsList.bind(this));

    this.eventService.connectToEventsSignalRService(callbackFunctions);
  }

  private FetchUpdatedEventsList() {
    if (this.currentEventCard === EventCard.EventList) {
      this.eventContainerService.populateEvents();
    }
  }

  private ReceiveStatusUpdate(statusResponse: StatusResponse) {
    switch (this.currentEventCard) {
      case EventCard.EventList:
        this.setStatusOnEvent(statusResponse.e);
        break;
        case EventCard.EventForm:
        this.eventService.setStatusOnTrafficAction(statusResponse.t);
        if (statusResponse.e.id > 0 && statusResponse.e.id === this.selectedEvent?.id && statusResponse.a.length === 0 && statusResponse.t.id === 0) {
          this.eventContainerService.populateEvent(this.selectedEvent.id);
        }
        break;
      case EventCard.TrafficActionForm:
        this.trafficActionService.setStatusOnAnnouncements(statusResponse.a);
        break;
      default:
    }
  }

  private setStatusOnEvent(statusItem: StatusItem) {
    if (this.eventsResponse) {
      let event = this.eventsResponse.events.find((e) => e.id === statusItem.id);
      if (event) {
        event.status = statusItem.s;
      }
    }
  }

  private notifyEvent = (
    eventId: number,
    notificationId: number | undefined,
    unread: boolean,
    itemType: NotificationItemType
  ) => {
    const event = this.eventsResponse?.events.find(
      (e) => e.id === eventId
    );
    if (event) {
      //TODO: create service for fetching statuses from db
      if (event.status.toLocaleLowerCase() !== "rejected" && unread === true) {
        event.notificationId = notificationId;
        event.notified = true;
        event.notificationType = itemType;
      }
    }
  };

  private notifyEventByTrafficAction = (trafficActionId: number, notificationId: number, unread: boolean, itemType: NotificationItemType) => {
    this.trafficActionService.getTrafficActionBasic(trafficActionId).subscribe(
      (ta) => {
        const event = this.eventsResponse?.events.find((e) => e.id == ta.eventId);
        if (event && !event.notificationId) {
          this.notifyEvent(ta.eventId, notificationId, true, itemType);
        }
      },
      (error: HttpErrorResponse) => {
        console.error(error);
        this.popupService.setErrorPopup(error);
      }
    );
  };

  private notifyEventByAnnouncement = (announcementId: number) => {
    // this.announcementService.getAnnouncement(announcementId).subscribe(
    //   (a) => {
    //     this.notifyEvent(a.eventId, undefined, true);
    //   },
    //   (error) => {
    //     console.error(error);
    //   }
    // );
  };

  private enrichWithNotificationsDot() {
    // Reset before applying new dots
    this.eventsResponse?.events
      .filter((e) => e.notified)
      .forEach((e) => {
        e.notified = false;
        e.notificationId = undefined;
      });

    this.notificationService
      .getNotifications()
      .filter((n) => !n.read)
      .forEach((notification) => {
        const itemType: NotificationItemType = (typeof(notification.itemType) === 'string') ? (<any>NotificationItemType)[notification.itemType] : notification.itemType;
        switch (itemType) {
          case NotificationItemType.Event:
            this.notifyEvent(notification.itemId, notification.id, true, NotificationItemType.Event);
            break;
          case NotificationItemType.TrafficAction:
            this.notifyEventByTrafficAction(notification.itemId, notification.id, true, NotificationItemType.TrafficAction);
            break;
          case NotificationItemType.Announcement:
            this.notifyEventByAnnouncement(notification.itemId);
            break;
          default:
            break;
        }
      });
  }

  //#region Private
  private initiateComponent() {
    this.currentEventCard = EventCard.EventList;

    this.notificationService.notifications.subscribe(() =>
      this.enrichWithNotificationsDot()
    );

    this.eventContainerService.currentEventCardChange.subscribe((change) => {
      this.currentEventCard = change;
    });

    this.eventContainerService.createModeChange.subscribe((change) => {
      this.createMode = change;
    });

    this.eventContainerService.eventsResponseChange.subscribe((change) => {
      this.eventsResponse = change;
      this.enrichWithNotificationsDot();
    });

    this.eventContainerService.filterAnnouncementChange.subscribe((change) => {
      this.filterAnnouncement = change;
    });

    this.initiateDropdownValues();

    this.eventService.selectEvent(null);
    this.filterSubscription = this.eventService
      .getTriggerForGetEvents()
      .subscribe(() => {
        if (!this.createMode && !this.eventService.getSelectedEvent())
          this.eventContainerService.populateEvents();
      });

    this.sortSubscription = this.sortService.currentSortChanged.subscribe(
      () => {
        this.eventContainerService.populateEvents();
      }
    );

    this.selectedEvent = null;
    this.filterService.resetFilter();

    this.eventContainerService.populateEvents();
  }

  private initiateDropdownValues() {
    this.eventContainerService.loading = true;

    this.populateViewObservables.push(this.reasonService.initiateReasonLists());

    this.populateViewObservables.push(
      this.trafficActionService.initiateTrafficActionTemplateList()
    );

    //When all subscriptions are done, set loading to false
    forkJoin(this.populateViewObservables).subscribe(
      (result) => {
        this.eventContainerService.loading = false;
      },
      (error) => (this.eventContainerService.loading = false)
    );
  }

  private enterCreateMode() {
    this.eventContainerService.initiateNewEvent();
  }

  //#endregion

  ngOnDestroy() {}
}
