import { Component, OnInit } from '@angular/core';
import { FilterService } from "../../../services/filter.service";

@Component({
  selector: "dua-reset-filter",
  templateUrl: "./reset-filter.component.html",
  styleUrls: ["./../filters.component.scss", "./reset-filter.component.scss"],
})
export class ResetFilterComponent implements OnInit {
  constructor(private filterService: FilterService) {}

  ngOnInit(): void {}

  resetFilter(){
    this.filterService.resetFilter();
  }

  isDisabled():boolean{
    return this.filterService.isDisabled();
  }
}
