import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FilterType } from "../../../models/enums";
import { FilterService } from "../../../services/filter.service";

@Component({
  selector: "dua-filter-button",
  templateUrl: "./filter-button.component.html",
  styleUrls: ["./../filters.component.scss", "./filter-button.component.scss"],
})
export class FilterButtonComponent implements OnInit {
  @Input() displayValue: string;
  @Input() filterType: FilterType;
  @Input() additionalClass: string;
  @Input() open: boolean;
  @Output() toggleFilter: EventEmitter<boolean> = new EventEmitter();

  constructor(private filterService: FilterService) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  private initiateComponent() {
    this.filterService.getCurrentOpenedFilterChanged().subscribe((change) => {
      this.open = change == this.filterType;
    });
  }
  
  isDisabled():boolean{
    return this.filterService.isDisabled();
  }

  toggleOpen() {
    this.open = !this.open;
    this.toggleFilter.emit(this.open);
  }
}
