import { AnnouncementCategoryText } from "./announcement-category-text";
import { Filter } from "../event/filter";
import { Train } from "../train";
import { AnnouncementState } from "./announcement-enums";

export interface IAnnouncement {
  id?: number;
  active: boolean;
  split: boolean;
  trafficActionId: number;
  eventId: number;
  title: string;
  categoryType: string;
  categoryText: AnnouncementCategoryText;
  arrivals: boolean;
  departures: boolean;
  firstLast: boolean;
  refId: string;
  trainNumbers: Train[];
  filterGeneratedTrains: Train[];
  history: string[];
  announcementActivityIds: string[];
  filter: Filter;
  state: AnnouncementState;
  status: string;
  priority: number;
  notificationId?: number;
  notified: boolean;
  onlyInTrv: boolean;
  toBeDeleted: boolean;
  percentageReplacedByAnother: number;
  containsExceedsTrvBatchLimits: boolean;
  tempId: string;
  stations: string[];
  splitFromId: number;
  overrideViaStationValidation: boolean;
}

export class Announcement implements IAnnouncement {
  public id?: number;
  public active: boolean;
  public split: boolean;
  public trafficActionId: number;
  public eventId: number;
  public title: string;
  public categoryType: string;
  public categoryText: AnnouncementCategoryText;
  public arrivals: boolean;
  public departures: boolean;
  public firstLast: boolean;
  public refId: string;
  public trainNumbers: Train[];
  public filterGeneratedTrains: Train[];
  public history: string[];
  public announcementActivityIds: string[];
  public filter: Filter;
  public state: AnnouncementState;
  public status: string;
  public priority: number;
  public notificationId?: number;
  public notified: boolean;
  public onlyInTrv: boolean;
  public toBeDeleted: boolean;
  public percentageReplacedByAnother: number;
  public containsExceedsTrvBatchLimits: boolean;
  public tempId: string;
  public stations: string[];
  public splitFromId: number;
  public overrideViaStationValidation: boolean;

  constructor(announcement: IAnnouncement) {
    this.id = announcement.id;
    this.active = announcement.active;
    this.split = announcement.split;
    this.tempId = announcement.tempId;
    this.trafficActionId = announcement.trafficActionId;
    this.eventId = announcement.eventId;
    this.title = announcement.title;
    this.categoryType = announcement.categoryType;
    this.categoryText = announcement.categoryText;
    this.arrivals = announcement.arrivals;
    this.departures = announcement.departures;
    this.firstLast = announcement.firstLast;
    this.refId = announcement.refId;
    this.trainNumbers = announcement.trainNumbers;
    this.filterGeneratedTrains = announcement.filterGeneratedTrains;
    this.history = announcement.history;
    this.announcementActivityIds = announcement.announcementActivityIds;
    this.filter = announcement.filter;
    this.state = announcement.state;
    this.status = announcement.status;
    this.priority = announcement.priority;
    this.notificationId = announcement.notificationId;
    this.notified = announcement.notified;
    this.onlyInTrv = announcement.onlyInTrv;
    this.toBeDeleted = announcement.toBeDeleted;
    this.percentageReplacedByAnother = announcement.percentageReplacedByAnother;
    this.containsExceedsTrvBatchLimits = announcement.containsExceedsTrvBatchLimits;
    this.stations = announcement.stations;
    this.splitFromId = announcement.splitFromId;
    this.overrideViaStationValidation = announcement.overrideViaStationValidation;
  }
}
