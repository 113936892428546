import { Component, OnDestroy, OnInit } from "@angular/core";
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "./../environments/environment";

@Component({
  selector: "dua-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "DUA";
  isIframe: boolean = false;

  constructor(private config: PrimeNGConfig, private translateService: TranslateService) {
    translateService.setDefaultLang('sv');
  }

  ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;
    this.translate('sv');
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  ngOnDestroy() {  }
}
