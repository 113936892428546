import { Component, forwardRef, Input, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { UserRoles } from "src/app/models/enums";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "dua-text-area",
  templateUrl: "./text-area.component.html",
  styleUrls: ["./text-area.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaComponent),
      multi: true,
    },
  ],
})
export class TextAreaComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() useReadWriteAccess: boolean;
  @Output() textAreaChanged: EventEmitter<string> = new EventEmitter<string>();

  isFocused = false;
  isHovered = false;

  propagateChange: (_: string) => {};

  value: string;

  constructor(private authService: AuthService) {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  onChange(value: string) {
    this.textAreaChanged.emit(value);
    this.propagateChange(value);
  }

  disableEdit() {
    return (
      this.useReadWriteAccess &&
      !this.authService.userHasMinimumRole(UserRoles.Write)
    );
  }

  onFocus() {
    this.isFocused = true;
    this.isHovered = false;
  }

  onBlur() {
    this.isFocused = false;
  }

  onHover() {
    if (!this.isFocused && !this.disableEdit()) {
      this.isHovered = true;
    }
  }

  onLeave() {
    this.isHovered = false;
  }
}
