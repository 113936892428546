import { TimeType } from "../timeType";

export interface IFilter {
  id?: number;
  fromDate: Date;
  toDate: Date;
  timeType: TimeType;
  stationCodes: string[];
  lines: string[];
  trainNumbers: string[];
  southGoing: boolean;
  northGoing: boolean;
  arrivingTrains: boolean;
  departingTrains: boolean;
  announcementCategories: number[];
}
export class Filter implements IFilter {
  id?: number;
  fromDate: Date;
  toDate: Date;
  timeType: TimeType;
  stationCodes: string[];
  lines: string[];
  trainNumbers: string[];
  southGoing: boolean;
  northGoing: boolean;
  arrivingTrains: boolean;
  departingTrains: boolean;
  announcementCategories: number[] = [0];

  constructor(filter: IFilter) {
    (this.id = filter.id), (this.fromDate = filter.fromDate);
    this.toDate = filter.toDate;
    this.timeType = filter.timeType;
    this.stationCodes = filter.stationCodes;
    this.lines = filter.lines;
    this.trainNumbers = filter.trainNumbers;
    this.southGoing = filter.southGoing;
    this.northGoing = filter.northGoing;
    this.arrivingTrains = filter.arrivingTrains;
    this.departingTrains = filter.departingTrains;
    this.announcementCategories = filter.announcementCategories
  }
}
