import { Component, Input, OnInit } from "@angular/core";
import { EventContainerService } from "src/app/services/event-container.service";
import { NotificationService } from "src/app/services/notification.service";
import { AnnouncementCategoryGroup } from "../../../../models/announcement/announcement-category-group";
import { NotificationItemType, UserRoles } from "../../../../models/enums";
import { AnnouncementService } from "../../../../services/announcement.service";
import { AuthService } from "../../../../services/auth.service";
import { Announcement } from "../../../../models/announcement/announcement";
import { AutoUnsubscribe } from "../../../../shared/decorators";
import { AnnouncementCategoryType, AnnouncementState } from "src/app/models/announcement/announcement-enums";
import { FilterService } from "src/app/services/filter.service";

@Component({
  selector: "dua-announcement-list",
  templateUrl: "./announcement-list.component.html",
  styleUrls: ["./announcement-list.component.scss"],
})
@AutoUnsubscribe()
export class AnnouncementListComponent implements OnInit {
  @Input() announcementCategory: AnnouncementCategoryGroup;
  @Input() useReadWriteAccess: boolean;

  constructor(
    private authService: AuthService,
    private announcementService: AnnouncementService,
    private notificationService: NotificationService,
    private eventContainerService: EventContainerService,
    private filterService: FilterService,
  ) {}

  ngOnInit(): void {
    this.notificationService.notifications.subscribe((notifications) => {
      this.announcementCategory.announcements?.forEach((announcement) => {
        announcement.notificationId = notifications.find(
          (n) =>
            n.itemType === NotificationItemType.Announcement &&
            n.itemId === announcement.id &&
            !n.read
        )?.id;
      });
    });
  }

  getAnnouncementsCategoryTitle(): string {
    return this.announcementService
      .getAnnouncementCategories()
      .find((x) => x.categoryType == this.announcementCategory.categoryType)!=null ? this.announcementService
        .getAnnouncementCategories()
        .find((x) => x.categoryType == this.announcementCategory.categoryType)
        .displayName
      : "";

  }

  showAnnouncementCategory(announcementCategory: AnnouncementCategoryGroup): boolean {
    let showCategory = announcementCategory
      && announcementCategory.announcements
      && announcementCategory.announcements.length > 0
      && !(announcementCategory.categoryType === AnnouncementCategoryType[AnnouncementCategoryType.CancelledReplaced] 
        && announcementCategory.announcements.every(x => x.toBeDeleted));

    if (showCategory) {
      return announcementCategory.announcements.some(x => !this.hideAnnouncement(x));
    }

    return false;
  }

  hideAnnouncement(announcement: Announcement)
  {
    return (announcement.categoryType === AnnouncementCategoryType[AnnouncementCategoryType.CancelledReplaced]  &&
      announcement.toBeDeleted) ||
        announcement.announcementActivityIds.length == 0 ||
        announcement.active == false ||
        (announcement.status === "Accepted" && announcement.toBeDeleted);
  }

  containsAnnouncementThatExceedsLimit(announcementCategory: AnnouncementCategoryGroup): boolean {
    return announcementCategory?.announcements
      ?.some((x: Announcement) => x?.containsExceedsTrvBatchLimits);
  }

  disableEdit() {
    return (
      this.useReadWriteAccess &&
      !this.authService.userHasMinimumRole(UserRoles.Write)
    );
  }

  editAnnouncement(announcement: Announcement) {
    if (announcement.notificationId) {
      this.notificationService.setReadStatus([announcement.notificationId]);
    }

    this.announcementService.selectAnnouncement(announcement);

    if(!announcement?.filter){
      announcement.filter = this.filterService.getFilter();
    }
    
    announcement.filter.lines = [];
    
    this.eventContainerService.openExistingAnnouncement(announcement);
  }

  reorderAnnouncements(event: Event) {
    this.announcementCategory.announcements.forEach(
      (a, index) => {
        let oldPriority = a.priority;
        a.priority = index + 1;

        if (a.state !== AnnouncementState.New && oldPriority !== a.priority) {
          a.state = AnnouncementState.Changed;
          a.onlyInTrv = false;
        } 
      }
    );
  }
}
