import { Injectable } from "@angular/core";
import { AnnouncementCategoryGroup } from "../models/announcement/announcement-category-group";
import { AnnouncementCategoryGroupRequest } from "../models/announcement/announcement-category-request";
import { AnnouncementRequest } from "../models/announcement/announcement-request";
import { TrafficActionRequest } from "../models/traffic-action/traffic-action-request";
import { TrafficAction } from "../models/traffic-action/traffic-action";
import { Announcement } from "../models/announcement/announcement";
import { UpdateTrafficActionTrainsRequest } from "../models/traffic-action/update-traffic-action-trains";
import { Filter } from "../models/event/filter";
import { Train } from "../models/train";
import { GenerateActionRequest } from "../models/traffic-action/generate-traffic-action-request";

@Injectable({
  providedIn: "root",
})
export class RequestObjectBuilderService {
  constructor() {}

  buildGenerateTrafficActionRequest(
    trafficAction: TrafficAction
  ): GenerateActionRequest {
    const generateActionRequest: GenerateActionRequest = {
      eventId: trafficAction.eventId,
      reasonId: trafficAction.reasonId,
      templateType: trafficAction.templateType,
      affectedTrains: trafficAction.filterGeneratedTrains?.map(
        (t) => t.trainNumber
      ),
      filter: trafficAction.filter,
    };

    return generateActionRequest;
  }

  buildTrafficActionRequest(
    trafficAction: TrafficAction
  ): TrafficActionRequest {
    const trafficActionRequest: TrafficActionRequest = {
      id: trafficAction.id,
      eventId: trafficAction.eventId,
      templateType: trafficAction.templateType,
      reasonId: trafficAction.reasonId,
      trainNumbers: trafficAction.filterGeneratedTrains ?? trafficAction.trains,
      reminderInMinutes: trafficAction.reminderInMinutes,
      announcementCategoryGroups: this.buildAnnouncementCategoryGroupRequest(
        trafficAction.announcementCategoryGroups,
        trafficAction.filter,
        trafficAction.filterGeneratedTrains ?? trafficAction.trains
      ),
      filter: trafficAction.filter
    };

    return trafficActionRequest;
  }

  buildUpdateTrafficActionTrainsRequest(
    trafficAction: TrafficAction
  ): UpdateTrafficActionTrainsRequest {
    const trafficActionRequest: UpdateTrafficActionTrainsRequest = {
      id: trafficAction.id,
      eventId: trafficAction.eventId ?? 0,
      templateType: trafficAction.templateType,
      reasonId: trafficAction.reasonId,
      reminderInMinutes: trafficAction.reminderInMinutes,
      announcementCategoryGroups: this.buildAnnouncementCategoryGroupRequest(
        trafficAction.announcementCategoryGroups,
        trafficAction.filter,
        trafficAction.filterGeneratedTrains
      ),
      filter: trafficAction.filter,
    };

    return trafficActionRequest;
  }

  buildAnnouncementRequest(
    a: Announcement,
    actionFilter: Filter,
    filterGeneratedTrains: Train[]
  ): AnnouncementRequest {
    const announcementRequest: AnnouncementRequest = {
      id: a.id,
      active: a.active,
      split: a.split,
      priority: a.priority,
      arrivals: a.arrivals,
      departures: a.departures,
      firstLast: a.firstLast,
      refId: a.refId,
      trainNumbers: a.filterGeneratedTrains ?? a.trainNumbers,
      categoryText: a.categoryText,
      announcementActivityIds: a.announcementActivityIds,
      filter: a.filter,
      onlyInTrv: a.onlyInTrv,
      title: a.title,
      toBeDeleted: a.toBeDeleted,
      tempId: a.tempId,
      status: a.status,
      state: a.state,
      splitFromId: a.splitFromId,
      overrideViaStationValidation: a.overrideViaStationValidation
    };

    return announcementRequest;
  }

  buildAnnouncementCategoryGroupRequest(
    announcementCategoryGroups: AnnouncementCategoryGroup[],
    actionFilter: Filter,
    filterGeneratedTrains: Train[]
  ): AnnouncementCategoryGroupRequest[] {
    let announcementCategoryGroupRequests: AnnouncementCategoryGroupRequest[] = [];
    announcementCategoryGroups.forEach((acg) => {
      announcementCategoryGroupRequests.push({
        id: acg.id,
        categoryType: acg.categoryType,
        announcements: this.buildAnnouncementRequests(
          acg.announcements,
          actionFilter,
          filterGeneratedTrains
        ),
      });
    });

    return announcementCategoryGroupRequests;
  }

  buildAnnouncementRequests(
    announcements: Announcement[],
    actionFilter: Filter,
    filterGeneratedTrains: Train[]
  ): AnnouncementRequest[] {
    let announcementRequests: AnnouncementRequest[] = [];
    announcements.forEach((a) => {
      announcementRequests.push(
        this.buildAnnouncementRequest(a, actionFilter, filterGeneratedTrains)
      );
    });

    return announcementRequests;
  }
}
