import { NotificationItemType } from "../enums";

export interface IEventListItem {
  id: number;
  title: string;
  status: string;
  lastUpdated: Date;
  fromTime: Date;
  notificationId: number | undefined;
  notified: boolean;
  notificationType: NotificationItemType;
}

export class EventListItem implements IEventListItem {
  public id: number;
  public title: string;
  public status: string;
  public lastUpdated: Date;
  public fromTime: Date;
  public notificationId: number | undefined;
  public notified: boolean;
  public notificationType: NotificationItemType;

  constructor(eventListItem: IEventListItem) {
    this.id = eventListItem.id;
    this.title = eventListItem.title;
    this.status = eventListItem.status;
    this.lastUpdated = eventListItem.lastUpdated;
    this.fromTime = eventListItem.fromTime;
    this.notified = eventListItem.notified;
  }
}
