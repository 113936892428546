export class KeyValues {
  public key: string;
  public values: string[];

  public static IsKeyValues(object: any): object is KeyValues
  {
    if (!object || typeof object !== 'object' || Array.isArray(object)) {
      return false;
    }

    if ('key' in object && 'values' in object &&
      typeof object.key === 'string' && (!object.errors || Array.isArray(object.values) &&
      (object.values.length <= 0 || typeof object.values[0] === 'string'))) {
      return true;
    }
    return false;
  }
}
