import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dua-notification-dot',
  templateUrl: './notification-dot.component.html',
  styleUrls: ['./notification-dot.component.scss']
})
export class NotificationDotComponent implements OnInit {

  @Input() active:boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
