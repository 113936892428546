import { TrafficActionListItem } from "../traffic-action/traffic-action-list-item";
import { Train } from "../train";
import { Filter } from "./filter";

export interface IEvent {
  id?: number;
  title: string;
  reasonId: number;
  trains: Train[];
  filterGeneratedTrains: Train[];
  actions: TrafficActionListItem[];
  comment: string;
  filter: Filter;
}

export class Event implements IEvent {
  public id?: number;
  public title: string;
  public reasonId: number;
  public trains: Train[];
  public filterGeneratedTrains: Train[];
  public actions: TrafficActionListItem[];
  public comment: string;
  public filter: Filter;

  constructor(event: IEvent) {
    this.id = event.id;
    this.title = event.title;
    this.reasonId = event.reasonId;
    this.trains = event.trains;
    this.filterGeneratedTrains = event.filterGeneratedTrains;
    this.actions = event.actions;
    this.comment = event.comment ?? "";
    this.filter = event.filter;
  }
}
