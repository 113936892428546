import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { KeyValuePair } from "../../../../models/key-value-pair";
import { FilterService } from "../../../../services/filter.service";
import { Helper } from "../../../../shared/helper";

@Component({
  selector: "dua-line-number-picker",
  templateUrl: "./line-number-picker.component.html",
  styleUrls: ["./line-number-picker.component.scss"],
})
export class LineNumberPickerComponent implements OnInit {
  @Output() closeFilter: EventEmitter<boolean> = new EventEmitter();

  _lineNumbers: string[];
  /** Line numbers without checked status */
  @Input() lineNumbers: string[];
  checkedStatusForLineNumbers: KeyValuePair<string, boolean>[] = [];
  allChecked: boolean = true;
  constructor(private filterService: FilterService) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    let value = changes["lineNumbers"].currentValue;
    let filterLines = this.filterService.getFilter().lines;

    this.checkedStatusForLineNumbers = value?.map(
      (x) =>
        new KeyValuePair(
          x,
          filterLines?.includes(x) || filterLines?.length == 0
        )
    );
  }

  private initiateComponent() {
    this.checkIfAllLineNumbersAreChecked();
  }

  close() {
    this.closeFilter.emit(true);
  }

  onChange(lineNumber: string) {
    //If "alla" checkbox is checked, only keep the mark on the clicked checkbox and deselect the rest
    if (this.allChecked) {
      this.allChecked = !this.allChecked;

      this.checkedStatusForLineNumbers.forEach((x) => {
        if (x.key != lineNumber) {
          x.value = !x.value;
        }
      });
    } else {
      //If not "alla" checkbox is checked, toggle currently clicked checkbox
      this.checkedStatusForLineNumbers.forEach((x) => {
        if (x.key == lineNumber) {
          x.value = !x.value;
        }
      });

      this.checkIfAllLineNumbersAreChecked();
    }
    this.emitActiveValues();
  }

  /** Toggles all line number checkboxes */
  toggleAll() {
    this.allChecked = !this.allChecked;

    this.checkedStatusForLineNumbers.forEach((x) => {
      x.value = this.allChecked;
    });

    this.emitActiveValues();
  }

  //#region Private
  /**Compares the current selection to all line numbers available to see if all are checked*/
  private checkIfAllLineNumbersAreChecked() {
    this.allChecked = Helper.compareStringArrays(
      this.checkedStatusForLineNumbers
        .filter((x) => x.value == true)
        .map((x) => x.key),
      this.lineNumbers
    );
  }

  /** Emits line number changes for other components to react to. I.e. Line map or display value in line number filter button*/
  private emitActiveValues() {
    let activeLineNumbers = this.checkedStatusForLineNumbers
      .filter((x) => x.value == true)
      .map((x) => x.key);
    this.filterService.emitLineNumbersChange(activeLineNumbers);
  }

  //#endregion
}
