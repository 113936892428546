import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable, of, throwError, timer } from "rxjs";
import { mergeMap, retryWhen, tap } from "rxjs/operators";

/** Interceptor that handles retries for HTTP requests as a workaround for temporary failures. */
export class RetryInterceptor implements HttpInterceptor {
  private retryDelay = 2000;
  private retryMaxAttempts = 1;
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(this.retryAfterDelay());
  }

  private retryAfterDelay(): any {
    return retryWhen((errors) => {
      return errors.pipe(
        mergeMap((err, count) => {
          if (count === this.retryMaxAttempts || err.status == 404) {
            return throwError(err);
          }
          return of(err).pipe(
            tap((error: HttpErrorResponse) =>
              console.warn(
                `Retrying ${error.url}. Retry count ${count + 1} of ${
                  this.retryMaxAttempts
                }`
              )
            ),
            mergeMap(() => timer(this.retryDelay))
          );
        })
      );
    });
  }
}
