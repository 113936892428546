import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash";
import { Observable, Subject, Subscription } from "rxjs";
import { EventCard, FilterType } from "../models/enums";
import { Filter } from "../models/event/filter";
import { TimeType } from "../models/timeType";
import { AutoUnsubscribe } from "../shared/decorators";
import { IFilterService } from "./interfaces/i-filter.service";
import { AnnouncementCategory } from "../models/announcement/announcement-category";

/** Service to handle filtering and filter state switching */
@Injectable({
  providedIn: "root",
})
@AutoUnsubscribe()
export class FilterService implements IFilterService {
  public currentState: EventCard;

  private filter: Filter;
  private categoryFilter: string[];

  /** Save states for each event card to make access easy when trying to populate new filter */
  private filterStates: { [index: number]: Filter } = {};

  //#region change detection for each filter type
  private dateChange = new Subject<Date[]>();
  private timeTypeChange = new Subject<TimeType>();
  private trainNumbersChange = new Subject<string[]>();
  private lineNumbersChange = new Subject<string[]>();
  private southGoingChange = new Subject<boolean>();
  private northGoingChange = new Subject<boolean>();
  private stationCodesChange = new Subject<string[]>();
  private categoryChange = new Subject<string[]>();
  private announcementCategoryChange = new Subject<AnnouncementCategory[]>

  //#endregion

  private resetFilterTrigger = new Subject<boolean>();
  private fetchTrainsTrigger = new Subject<boolean>();
  private changeCategoryFilterTrigger = new Subject<boolean>();
  private allUpdatedFilters = new Subject<Filter>();
  private updateEventListOnFiltering = new Subject<boolean>();
  private currentOpenedFilterChanged = new Subject<FilterType>();

  /* Used for AutoUnsubscribe*/
  private dateChangeSubscription: Subscription;
  private timeTypeChangeSubscription: Subscription;
  private trainNumberChangeSubscription: Subscription;
  private lineNumberChangeSubscription: Subscription;
  private southGoingChangeSubscription: Subscription;
  private northGoingChangeSubscription: Subscription;
  private stationCodesChangeSubscription: Subscription;
  private categoryChangeSubscription: Subscription;
  private announcementCategorySubscription: Subscription;

  constructor() {
    this.subscribeToFilterChanges();
    this.setDefaultFilter();
  }
  //#region Public

  /** Get the currently active filter */
  public getFilter(): Filter {
    return this.filter;
  }

  public getFilterCopy() {
    let filterCopy = JSON.parse(JSON.stringify(this.filter));
    filterCopy.id = 0; //When copying a filter to ex an announcement it shouldn't keep the id
    return filterCopy;
  }

  public getCategoryFilter() {
    return this.categoryFilter;
  }

  /** Get the currently active filter with modified station codes */
  public getFilterWithStations(stations: string[]) {
    let modifiedFilter = cloneDeep(this.filter);
    modifiedFilter.stationCodes = stations;
    return modifiedFilter;
  }

  /** Switch filter to use.
   * @param newCard - The new card type that is going to be used
   * @param removeOldFilter - Specify if the old values for the new filter should be removed, default is false
   * @param newFilter - (optional) Pass an object to this function if the filter should inherit timestamps, stations and trains from the object.
   * Accepts EventView, TrafficActionView or Announcment
   */
  public switchFilterState(
    newCard: EventCard,
    removeOldFilter: boolean = false,
    newFilter?: Filter
  ): void {
    this.currentState = newCard;
    if (newFilter) {
      this.setFilter(newFilter);
    }

    if (newCard == EventCard.EventList && !removeOldFilter) {
      this.setDefaultFilter();
    }
    this.emitAllUpdatedFilters(this.filter);
  }

  public isDisabled():boolean{
    if(this.currentState == EventCard.Announcement) return true;
    return false;
  }

  public getCurrentOpenedFilterChanged(): Observable<FilterType> {
    return this.currentOpenedFilterChanged;
  }

  public setCurrentFilterType(filter: FilterType) {
    this.currentOpenedFilterChanged.next(filter);
  }

  public getUpdateEventsOnFilteringChange() {
    return this.updateEventListOnFiltering;
  }

  /** Resets the filter to default and tells all filter components that the filter should reset */
  public resetFilter() {
    this.setDefaultFilter();
    this.resetFilterTrigger.next(true);
    this.updateEventListOnFiltering.next(true);
    this.emitFromFiltering();
    this.emitCategoryChange(this.categoryFilter);
  }

  public getDateChange(): Observable<Date[]> {
    return this.dateChange;
  }
  public emitDateChange(value: Date[]): void {
    this.dateChange.next(value);
  }
  public getTimeTypeChange(): Observable<TimeType> {
    return this.timeTypeChange;
  }
  public emitTimeTypeChange(value: TimeType): void {
    this.timeTypeChange.next(value);
  }
  public getTrainNumbersChange(): Observable<string[]> {
    return this.trainNumbersChange;
  }
  public emitTrainNumbersChange(value: string[]): void {
    this.trainNumbersChange.next(value);
  }
  public getLineNumbersChange(): Observable<string[]> {
    return this.lineNumbersChange;
  }
  public emitLineNumbersChange(value: string[]): void {
    this.lineNumbersChange.next(value);
  }
  public getCategoryChange(): Observable<string[]> {
    return this.categoryChange;
  }
  public emitCategoryChange(value: string[]): void {
    this.categoryChange.next(value);
  }
  public getAnnouncementCategoryChange(): Observable<AnnouncementCategory[]> {
    return this.announcementCategoryChange;
  }
  public emitAnnouncementCategoryChange(value: AnnouncementCategory[]): void {
    this.announcementCategoryChange.next(value);
  }
  public getCategoryChangeTrigger(): Observable<boolean> {
    return this.changeCategoryFilterTrigger;
  }
  public getSouthGoingChange(): Observable<boolean> {
    return this.southGoingChange;
  }
  public emitSouthGoingChange(value: boolean): void {
    this.southGoingChange.next(value);
  }
  public getNorthGoingChange(): Observable<boolean> {
    return this.northGoingChange;
  }
  public emitNorthGoingChange(value: boolean): void {
    this.northGoingChange.next(value);
  }
  public getStationCodesChange(): Observable<string[]> {
    return this.stationCodesChange;
  }
  public emitStationCodesChange(value: string[]): void {
    this.stationCodesChange.next(value);
  }
  public getResetFilterTrigger(): Observable<boolean> {
    return this.resetFilterTrigger;
  }
  public emitResetFilterTrigger(value: boolean): void {
    this.resetFilterTrigger.next(value);
  }
  public getFetchTrainsTrigger(): Observable<boolean> {
    return this.fetchTrainsTrigger;
  }

  public emitFetchTrainsTrigger(value: boolean): void {
    this.fetchTrainsTrigger.next(value);
  }

  public getAllUpdatedFilters(): Observable<Filter> {
    return this.allUpdatedFilters;
  }

  public emitAllUpdatedFilters(value: Filter): void {
    this.allUpdatedFilters.next(value);
  }
  //#endregion

  //#region Private

  /** Set current filter from a specified card item */

  private setFilter(filter: Filter) {
    this.filter = filter;
  }

  /** Set default filter */
  private setDefaultFilter() {
    const MS_PER_MINUTE = 60000;

    let now = new Date();
    now.setSeconds(0);
    now.setMilliseconds(0);

    let fromTime = new Date(now.getTime());
    let toTime = new Date(now.getTime() + 1 * 60 * MS_PER_MINUTE);
    this.filter = new Filter({
      id: null,
      fromDate: fromTime,
      toDate: toTime,
      timeType: new TimeType(1, "Planned"),
      stationCodes: [],
      lines: [],
      trainNumbers: [],
      southGoing: true,
      northGoing: true,
      arrivingTrains: false,
      departingTrains: true,
      announcementCategories: [0]
    });
    this.categoryFilter = [];
  }

  /** Subscribe to the filter changes to capture the correct data for the filter and emit the correct event so the right component can act */
  private subscribeToFilterChanges() {
    this.dateChangeSubscription = this.dateChange.subscribe((change) => {
      this.filter.fromDate = change[0];
      this.filter.toDate = change[1];
      this.emitFromFiltering();
    });

    this.timeTypeChangeSubscription = this.timeTypeChange.subscribe(
      (change) => {
        this.filter.timeType = change;
        this.emitFromFiltering();
      }
    );

    this.trainNumberChangeSubscription = this.trainNumbersChange.subscribe(
      (change) => {
        this.filter.trainNumbers = change;
        this.emitFromFiltering();
      }
    );

    this.lineNumberChangeSubscription = this.lineNumbersChange.subscribe(
      (change) => {
        this.filter.lines = change;
        this.emitFromFiltering();
      }
    );

    this.categoryChangeSubscription = this.categoryChange.subscribe(
      (change) => {
        this.categoryFilter = change;
        this.changeCategoryFilterTrigger.next(true);
      }
    );
    this.southGoingChangeSubscription = this.southGoingChange.subscribe(
      (change) => {
        this.filter.southGoing = change;
        this.emitFromFiltering();
      }
    );

    this.northGoingChangeSubscription = this.northGoingChange.subscribe(
      (change) => {
        this.filter.northGoing = change;
        this.emitFromFiltering();
      }
    );

    this.stationCodesChangeSubscription = this.stationCodesChange.subscribe(
      (change) => {
        this.filter.stationCodes = change;
        this.emitFromFiltering();
      }
    );

    this.announcementCategorySubscription = this.announcementCategoryChange.subscribe(
      (change) => {
        let id: number[];
        if(change.length > 0) {
          id = change.map(c => c.id);
        } else {
          id = [0]
        }
        this.filter.announcementCategories = id
        this.emitFromFiltering();
      }
    )
  }

  /** Emit an event on the correct subject based on the current filter state */
  private emitFromFiltering() {
    switch (this.currentState) {
      case EventCard.EventList:
        this.updateEventListOnFiltering.next(true);
        break;
      case EventCard.EventForm:
      case EventCard.TrafficActionForm:
      case EventCard.Announcement:
        this.fetchTrainsTrigger.next(true);
      default:
        //Do nothing
        break;
    }
  }
  //#endregion

  ngOnDestroy() {}
}
