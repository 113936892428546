import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AnnouncementCategoryText } from "../../../../../../models/announcement/announcement-category-text";
import { AnnouncementService } from "../../../../../../services/announcement.service";

@Component({
  selector: "dua-announcement-text-list-item",
  templateUrl: "./announcement-text-list-item.component.html",
  styleUrls: ["./announcement-text-list-item.component.scss"],
})
export class AnnouncementTextListItemComponent implements OnInit {
  @Input() announcementText: AnnouncementCategoryText;
  @Input() currentText: boolean = false;
  @Input() disabled: boolean;

  @Output() announcementTextChange = new EventEmitter<
    AnnouncementCategoryText
  >();

  constructor(private announcementService: AnnouncementService) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  initiateComponent() {
    this.currentText =
      this.announcementService.selectedAnnouncementCategoryText &&
      this.announcementService.selectedAnnouncementCategoryText.code ==
        this.announcementText?.code;
    this.announcementService
      .getSelectAnnouncementText()
      .subscribe((selected) => {
        this.currentText =
          selected && this.announcementText?.code == selected.code;
      });
  }

  selectAnnouncementText() {
    if (this.disabled) {
      return;
    }

    if (
      this.announcementService.selectedAnnouncementCategoryText?.code !==
      this.announcementText?.code
    ) {
      this.currentText = true;
      this.announcementService.emitSelectAnnouncementText(
        this.announcementText
      );
    }

    this.announcementTextChange.emit(this.announcementText);
  }
}
