import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FilterType } from "../../../models/enums";
import { Filter } from "../../../models/event/filter";
import { FilterService } from "../../../services/filter.service";
import { LineService } from "../../../services/line.service";
import { Helper } from "../../../shared/helper";

@Component({
  selector: "dua-line-number-filter",
  templateUrl: "./line-number-filter.component.html",
  styleUrls: [
    "./../filters.component.scss",
    "./line-number-filter.component.scss",
  ],
})
export class LineNumberFilterComponent implements OnInit {
  lineNumberDisplayValue: string;
  defaultTitle = "Samtliga linjer";
  open: boolean;
  filterType = FilterType.Line;
  lineNumbers: string[];

  resetSubscription: Subscription;
  lineNumberChangeSubscription: Subscription;
  linesSubscription: Subscription;
  currentOpenSubscription: Subscription;
  filterUpdatedSubscription: Subscription;
  constructor(
    private filterService: FilterService,
    private lineService: LineService
  ) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  private initiateComponent() {
    this.linesSubscription = this.lineService.getLines().subscribe((lines) => {
      this.lineService.setLineNumbers(lines);
      this.lineNumbers = lines.map((x) => x.name);
    });
    this.lineNumberDisplayValue = this.defaultTitle;
    this.subscribeToChanges();
    this.resetFilter();
  }

  subscribeToChanges() {
    this.lineNumberChangeSubscription = this.filterService
      .getLineNumbersChange()
      .subscribe((change) => {
        this.setDisplayValue(change);
      });

    this.filterUpdatedSubscription = this.filterService
      .getAllUpdatedFilters()
      .subscribe((change: Filter) => {
        this.setDisplayValue(change?.lines);
      });
    this.currentOpenSubscription = this.filterService
      .getCurrentOpenedFilterChanged()
      .subscribe((filterType) => {
        if (filterType != this.filterType) {
          this.close();
        }
      });
  }

  resetFilter() {
    this.resetSubscription = this.filterService
      .getResetFilterTrigger()
      .subscribe(() => {
        this.lineNumberDisplayValue = this.defaultTitle;
      });
  }

  isAllLineNumbers(lines: string[]) {
    return Helper.compareStringArrays(lines, this.lineNumbers);
  }

  toggleOpen(event) {
    this.open = event;
    if (this.open) {
      this.filterService.setCurrentFilterType(this.filterType);
    }
  }

  close() {
    this.open = false;
  }

  setDisplayValue(lines: string[]) {
    if (this.isAllLineNumbers(lines) || !lines || lines.length == 0) {
      this.lineNumberDisplayValue = this.defaultTitle;
    } else {
      this.lineNumberDisplayValue = lines.join(",");
    }
  }
}
