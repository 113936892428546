import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive({
    selector: '[clickOutside]'
})
export class ClickOutsideDirective {
    @Output() clickOutside = new EventEmitter<void>();
    constructor(private elementRef: ElementRef) {
    }

    @HostListener('document:click', ['$event.target'])
     onClick(target: HTMLElement): void {
        if (!target) {
            return;
        }

        const inside: boolean = this.elementRef.nativeElement.contains(target);
        if (!inside) {
            this.clickOutside.emit();
        }
    }
}