import { KeyValuesException } from "./key-values-exception";

export class AggregatedKeyValueException {
  public title: string;
  public errors: KeyValuesException[];

  public static IsAggregatedKeyValueException(object: any): object is AggregatedKeyValueException
  {
    if (!object || typeof object !== 'object' || Array.isArray(object)) {
      return false;
    }

    if ('title' in object && 'errors' in object &&
      typeof object.title === 'string' && (!object.errors || Array.isArray(object.errors) &&
      (object.errors.length <= 0 || KeyValuesException.IsKeyValuesException(object.errors[0])))) {
      return true;
    }
    return false;
  }
}
