import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs";
import { PopupButton, PopupType } from "../../models/popup-enums";
import { PopupInformation } from "../../models/popup-information";
import { PopupService } from "../../services/popup.service";

@Component({
  selector: "dua-popup",
  templateUrl: "./popup.component.html",
  styleUrls: ["./popup.component.scss"],
})
export class PopupComponent implements OnInit {
  popupInformation: PopupInformation;
  show: boolean;

  //#region Show buttons
  showCancel: boolean;
  showOk: boolean;
  showYes: boolean;
  showNo: boolean;
  showYesDisabled: boolean;
  //#endregion

  PopupButton = PopupButton;

  constructor(private popupService: PopupService) {}

  ngOnInit(): void {
    this.popupService.shouldShow.subscribe((show) => {
      this.show = show;

      if (show) {
        this.popupInformation = this.popupService.getCurrentPopup();
        this.evaluateButtonVisibility();
      }
    });
  }

  close(buttonPressed: PopupButton) {
    let callback: Observable<unknown>;

    switch (buttonPressed) {
      case PopupButton.Ok:
        callback = this.popupService.okCallback();
        break;
      case PopupButton.Yes:
        callback = this.popupService.yesCallback();
        break;
      case PopupButton.No:
        callback = this.popupService.noCallback();
        break;
      case PopupButton.Cancel:
        callback = this.popupService.cancelCallback();
        break;
      default:
        new Error("Missing callback");
    }

    callback.subscribe(() => (this.show = false));
    this.popupService.resetCallbacks();
    this.popupService.resetErrorMessages();
  }

  evaluateButtonVisibility() {
    const buttons = this.popupInformation.buttons;

    this.showCancel = buttons.includes(PopupButton.Cancel);
    this.showOk = buttons.includes(PopupButton.Ok);
    this.showYes = buttons.includes(PopupButton.Yes);
    this.showNo = buttons.includes(PopupButton.No);
    this.showYesDisabled = buttons.includes(PopupButton.YesDisabled);
  }
}
