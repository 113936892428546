import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OrderListModule } from 'primeng/orderlist';
import { SharedModule } from "../../shared/shared.module";
import { EventContainerHeaderComponent } from "./event-container-header/event-container-header.component";
import { EventContainerComponent } from "./event-container.component";
import { EventListItemComponent } from "./event-list/event-list-item/event-list-item.component";
import { EventListSorterComponent } from "./event-list/event-list-sorter/event-list-sorter.component";
import { EventListComponent } from "./event-list/event-list.component";
import { PaginatorComponent } from "./event-list/paginator/paginator.component";
import { EventLogComponent } from "./event-log/event-log.component";
import { EventComponent } from "./event/event.component";
import { TrafficActionListItemComponent } from "./event/traffic-action-list/traffic-action-list-item/traffic-action-list-item.component";
import { TrafficActionListComponent } from "./event/traffic-action-list/traffic-action-list.component";
import { AnnouncementListItemComponent } from "./traffic-action/announcement-list/announcement-list-item/announcement-list-item.component";
import { AnnouncementListComponent } from "./traffic-action/announcement-list/announcement-list.component";
import { TrafficActionComponent } from "./traffic-action/traffic-action.component";
import { AnnouncementComponent } from "./announcement/announcement.component";
import { AnnouncementTextComponent } from "./announcement/announcement-text/announcement-text.component";
import { AnnouncementTextListComponent } from "./announcement/announcement-text/announcement-text-list/announcement-text-list.component";
import { AnnouncementTextListItemComponent } from "./announcement/announcement-text/announcement-text-list/announcement-text-list-item/announcement-text-list-item.component";
import { AnnouncementPropertyComponent } from "./announcement/announcement-property/announcement-property.component";
import { AnnouncementHistoryListComponent } from "./announcement/announcement-history-list/announcement-history-list.component";

const exportedDeclarations = [
  EventContainerComponent,
  EventContainerHeaderComponent,
  EventListComponent,
  EventLogComponent,
  TrafficActionListComponent,
  TrafficActionListItemComponent,
  EventListItemComponent,
  EventListSorterComponent,
  PaginatorComponent,
  AnnouncementListComponent,
  AnnouncementListItemComponent,
  EventComponent,
  TrafficActionComponent,
  AnnouncementComponent,
  AnnouncementTextComponent,
  AnnouncementTextListComponent,
  AnnouncementTextListItemComponent,
  AnnouncementHistoryListComponent,
  AnnouncementPropertyComponent,
];
@NgModule({
  declarations: exportedDeclarations,
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, OrderListModule],
  exports: exportedDeclarations,
})
export class EventModule {}
