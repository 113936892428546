import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FilterType } from "../../../models/enums";
import { FilterService } from "../../../services/filter.service";
import { AutoUnsubscribe } from "../../../shared/decorators";

@Component({
  selector: "dua-time-filter",
  templateUrl: "./time-filter.component.html",
  styleUrls: ["./../filters.component.scss", "./time-filter.component.scss"],
})
@AutoUnsubscribe()
export class TimeFilterComponent implements OnInit, OnDestroy {
  open: boolean;
  fromDate: Date;
  toDate: Date;
  filterType = FilterType.Time;
  displayDates: string;

  dateChangeSubscription: Subscription;
  currentOpenFilterSubscription: Subscription;
  resetSubscription: Subscription;
  filterUpdateSubscription: Subscription;

  constructor(
    private filterService: FilterService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  private initiateComponent() {
    this.populateDateFilter();
    this.subscribeToChanges();
    this.resetFilter();
  }
  
  isDisabled():boolean{
    return this.filterService.isDisabled();
  }

  populateDateFilter() {
    this.fromDate = this.filterService.getFilter().fromDate;
    this.toDate = this.filterService.getFilter().toDate;
    this.generateDisplayValue();
  }

  subscribeToChanges() {
    this.dateChangeSubscription = this.filterService
      .getDateChange()
      .subscribe((change) => {
        this.updateDates(change);
      });

    this.filterUpdateSubscription = this.filterService
      .getAllUpdatedFilters()
      .subscribe((change) => {
        this.updateDates([change.fromDate, change.toDate]);
      });
    this.currentOpenFilterSubscription = this.filterService
      .getCurrentOpenedFilterChanged()
      .subscribe((filterType) => {
        if (filterType != this.filterType) {
          this.close();
        }
      });
  }

  resetFilter() {
    this.resetSubscription = this.filterService
      .getResetFilterTrigger()
      .subscribe(() => {
        this.populateDateFilter();
      });
  }

  toggleOpen(event) {
    this.open = event;
    if (this.open) {
      this.filterService.setCurrentFilterType(this.filterType);
    }
  }

  updateDates(dates: Date[]) {
    this.fromDate = dates[0];
    this.toDate = dates[1];
    this.generateDisplayValue();
  }

  generateDisplayValue() {
    let from = this.fromDate
      ? this.datePipe.transform(this.fromDate, "shortTime")
      : "från";
    let to = this.toDate
      ? this.datePipe.transform(this.toDate, "shortTime")
      : "till";

    this.displayDates = `${from}-${to}`;
  }

  close() {
    this.open = false;
  }

  ngOnDestroy() {}
}
