import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { Subscription } from "rxjs";
import { AnnouncementCategory } from "../../../../../models/announcement/announcement-category";
import { AnnouncementCategoryText } from "../../../../../models/announcement/announcement-category-text";
import { AnnouncementService } from "../../../../../services/announcement.service";

@Component({
  selector: "dua-announcement-text-list",
  templateUrl: "./announcement-text-list.component.html",
  styleUrls: ["./announcement-text-list.component.scss"],
})
export class AnnouncementTextListComponent implements OnInit {
  @Input() announcementCategoryText: AnnouncementCategoryText;
  @Input() announcementTexts: AnnouncementCategoryText[];
  @Input() announcementCategory: AnnouncementCategory;
  @Input() disabled: boolean;

  @Output() announcementCategoryTextChange = new EventEmitter<
    AnnouncementCategoryText
  >();

  filteredAnnouncementTexts: AnnouncementCategoryText[];
  currentAnnouncementText: AnnouncementCategoryText;

  selectAnnouncementSubscription: Subscription;
  showAll: boolean = false;
  defaultDisplayedNumberOfTexts = 6;

  @Input() filterText: string;

  constructor(private announcementService: AnnouncementService) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["filterText"] || changes["announcementTexts"]) {
      this.filterAnnouncementTexts();
    }
  }

  initiateComponent() {
    this.selectAnnouncementSubscription = this.announcementService
      .getSelectAnnouncementText()
      .subscribe((change) => {
        this.currentAnnouncementText = change;
      });
  }

  announcementTextChange(announcementCategoryText: AnnouncementCategoryText) {
    this.announcementCategoryTextChange.emit(announcementCategoryText);
  }

  showAllTexts() {
    this.showAll = true;
  }

  showLess() {
    this.showAll = false;
  }

  filterAnnouncementTexts() {
    this.filteredAnnouncementTexts = this.announcementTexts.filter(
      (x) =>
        !this.filterText ||
        x.text.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }
}
