import { environment } from "src/environments/environment";

export const AzureUserRoles = {
  Read: [],
  Write: ["write"],
};

export const userReadWriteGroups = {
  DLI: environment.DLI // Kvitter Writeaccess
};
