import { Component, Input, OnInit, SimpleChange, SimpleChanges } from "@angular/core";
import { Train } from "src/app/models/train";

@Component({
  selector: "dua-affected-train-list",
  templateUrl: "./affected-train-list.component.html",
  styleUrls: ["./affected-train-list.component.scss"],
})
export class AffectedTrainListComponent implements OnInit {
  @Input() activityTrains: Train[] = [];
  @Input() filterGeneratedTrains: Train[] = [];
  @Input() label: string;
  showAll: boolean = false;
  defaultDisplayedNumberOfTrains: number = 18;

  allTrains: Train[] = [];

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(): void {
    this.populateTrains();
  }

  populateTrains() {
    this.allTrains=[];
    let redTrains: Train[] = [];
    let blueTrains: Train[] = [];

    if(this.activityTrains) {
      this.activityTrains.sort(function(x,y){ return x.trainNumber > y.trainNumber ? 1 : -1; });
      redTrains = this.activityTrains.filter(x => x.toBeDeleted === true);
      blueTrains = this.activityTrains.filter(x => x.toBeDeleted === false);
    }
    
    //Put the red "affected-trains" first
    redTrains.forEach(val => {
      val.isActivityTrain = true;
      this.allTrains.push(Object.assign({}, val));
    });
    
    //Then the grey trains
    if(this.filterGeneratedTrains) {
      this.filterGeneratedTrains.sort(function(x,y){ return x.trainNumber > y.trainNumber ? 1 : -1; });
      this.filterGeneratedTrains.forEach(element => {
        if(this.activityTrains?.map(t => t.trainNumber).indexOf(element.trainNumber) == -1) {
          this.allTrains.push(new Train(element.trainNumber, false, true, false, element.toBeDeleted));
        }
      }); 
    }
    
    //Then the blue trains
    blueTrains.forEach(val => {
      val.isActivityTrain = true;
      this.allTrains.push(Object.assign({}, val));
    });
  }

  showAllAffectedTrains() {
    this.showAll = true;
  }

  showLess() {
    this.showAll = false;
  }
}
