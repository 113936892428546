import { TrafficActionRequest } from "../traffic-action/traffic-action-request";
import { AnnouncementRequest } from "./announcement-request";

export class EditAnnouncementRequest {
  public originalAction: TrafficActionRequest;
  public editRequest: AnnouncementRequest;
  public newAnnouncement: boolean;
  public categoryType: string;

  constructor(action: TrafficActionRequest, request: AnnouncementRequest, isNew: boolean, categoryType: string){
    this.originalAction = action;
    this.editRequest = request;
    this.newAnnouncement = isNew;
    this.categoryType = categoryType;
  }
}

