import { KeyValuePair } from "../models/key-value-pair";
import { MapNode } from "../models/line-map/map-node";

/** Class focused on calculating the closest path between two nodes given a set of node pairs with connections */
export class ClosestPath {
  /** Calculates the closest path between two map nodes, using an array of node pairs  */
  static calculateClosestPath(
    initial: MapNode,
    destination: MapNode,
    allNodePairs: KeyValuePair<MapNode, MapNode>[]
  ) {
    // make a queue array to see which nodes to check
    let queue: MapNode[] = [];

    //make a path array to keep track of checked nodes.
    let path: KeyValuePair<MapNode, MapNode[]>[] = [];

    // populate queue with the node that will be the initial search node
    queue.push(initial);
    var count = 0;
    // search the queue until it is empty
    while (queue.length > 0) {
      count++;

      //fail-safe so the while loop has a definite end even if something goes wrong
      if (count >= 100000) {
        break;
      }
      // assign the top of the queue to initial node
      let currentNode = queue[0];
      let previousNode: MapNode = null;

      // if currentNode is the node we're searching for, the destination has been reached
      if (currentNode.id === destination.id) {
        //add current node to path
        path.push(new KeyValuePair(currentNode, [currentNode]));
        //return a reduced path only containing the nodes to reach from initial to destination
        //return this.reducePath(path);
        return this.findShortestPath(path, initial);
      }

      // if currentNode has an id node matching a key value pair, add it to the queue.
      if (currentNode.id !== null) {
        let filteredNodePairs: MapNode[] = [];
        //Add nodes based on key == node
        let filteredByKey = allNodePairs.filter(
          (x) =>
            x.key &&
            x.value &&
            x.key.id == currentNode.id &&
            (!previousNode || x.value.id != previousNode.id)
        );
        for (let pair of filteredByKey) {
          queue.push(pair.value);
          filteredNodePairs.push(pair.value);
        }

        //Add nodes based on value == node
        let filteredByValue = allNodePairs.filter(
          (x) =>
            x.value &&
            x.key &&
            x.value.id == currentNode.id &&
            (!previousNode || x.key.id != previousNode.id)
        );
        for (let pair of filteredByValue) {
          queue.push(pair.key);
          filteredNodePairs.push(pair.key);
        }

        //Add current node and all connections to the path
        path.push(new KeyValuePair(currentNode, filteredNodePairs));
      }

      // remove the currentNode from the queue, to check next node;
      previousNode = queue.shift();
      queue = [...new Set(queue)];
    }
    console.error("No path to node found", count);

    return [];
  }

  /** Reduces the path found between two nodes to find the shortest route instead of including all searched paths */
  /*private static reducePath(path: KeyValuePair<MapNode, MapNode[]>[]) {
    let previousNode = path.pop();
    let truePath = [previousNode.key];

    let reversedPath = path.reverse();
    for (let pathStep of reversedPath) {
      let nextPreviousNode = pathStep.value.find(
        (x) => x?.id == previousNode?.key?.id
      );
      if (nextPreviousNode) {
        truePath.push(pathStep.key);
        previousNode = pathStep;
      }
    }

    return truePath;
  }*/

  private static findShortestPath(path: KeyValuePair<MapNode, MapNode[]>[], initial: MapNode) {
    let previousNode = path.pop();
    let truePath = [previousNode.key];

    let reversedPath = path.reverse();
    
    return this.findShortestRecursively(previousNode?.key, reversedPath, truePath, initial);
  }

  private static findShortestRecursively(previousNode: MapNode, reversedPath: KeyValuePair<MapNode, MapNode[]>[], truePath: MapNode[], initial: MapNode) : MapNode[] {
    let prevs = [];
    for (let pathStep of reversedPath) {
      let nextPreviousNode = pathStep.value.find(
        (x) => x?.id == previousNode?.id
      );
      if (nextPreviousNode) {
        prevs.push(pathStep.key);
      }
    }
    let setOfPrevs = new Set(prevs);
    for (let pathNode of truePath) {
      setOfPrevs.delete(pathNode);
    }
    prevs = Array.from(setOfPrevs);
    for (let prev of prevs) {
      if (prev?.id == initial.id) {
        truePath.push(prev);
        return truePath;
      }
    }
    let result = [];
    for (let prev of prevs) {
      let truePathForPrev = truePath.concat([prev]);
      let currentResult = this.findShortestRecursively(prev, reversedPath, truePathForPrev, initial);
      if (result.length == 0 || result.length > currentResult.length) {
        result = currentResult;
      }
    }
    return result;
  }
}
