import { HttpClient } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Station } from "../models/station";
import { AutoUnsubscribe } from "../shared/decorators";
import { IStationService } from "./interfaces/i-station.service";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle stations API */
@Injectable({
  providedIn: "root",
})
@AutoUnsubscribe()
export class StationService implements IStationService, OnDestroy {
  public stations: Station[];

  private readonly stationsSegment = "stations";
  private readonly unorderedSegment = "Unordered";
  private readonly headers = { "Api-Version": environment.stationsApi.version };

  constructor(
    private httpClient: HttpClient,
    private urlBuilderService: UrlBuilderService
  ) {}

  /** Get stations from API */
  public getStationData(): Observable<Station[]> {
    return this.httpClient
      .get<Station[]>(
        this.urlBuilderService.buildUrl([
          environment.stationsApi.path,
          this.stationsSegment,
          this.unorderedSegment,
        ]),
        { headers: this.headers }
      )
      .pipe(map((stations) => (this.stations = stations)));
  }

  ngOnDestroy() {}
}
