import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { NotificationService } from "src/app/services/notification.service";
import { environment } from "../../../environments/environment";
import { NotificationItem } from "../../models/notification/notification-item";
import { VersionData } from "../../models/version-data";
import { AuthService } from "../../services/auth.service";
import { SystemNotificationService } from "../../services/system-notification.service";
import { AutoUnsubscribe } from "../../shared/decorators";

@Component({
  selector: "dua-user-menu",
  templateUrl: "./user-menu.component.html",
  styleUrls: ["./user-menu.component.scss"],
})
@AutoUnsubscribe()
export class UserMenuComponent implements OnInit, OnDestroy {
  public showUserMenu: boolean = false;
  public showAll: boolean = false;
  public hasUnreadNotifications: boolean;

  public notifications: NotificationItem[] = [];
  public versionTexts: VersionData[] = [];
  public productionEnvironment: boolean;

  private notificationsSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  //#region Public

  /** Toggles the dropdown user menu and resets the "Visa fler"-button to show */
  toggleUserMenu() {
    this.showUserMenu = !this.showUserMenu;
    if (!this.showUserMenu) {
      this.showAll = false;
    }
  }

  /** Closes the user menu */
  closeUserMenu() {
    this.showUserMenu = false;
    this.showAll = false;
  }

  /** Closes the menu and marks the notification as read if it is currently not  */
  selectNotification(chosenNotification: NotificationItem) {
    this.closeUserMenu();
    if (!chosenNotification.read) this.markAsRead([chosenNotification]);
  }

  /** Marks all notifications that are not currently read by the user as read */
  markAllAsRead() {
    this.markAsRead(this.notifications.filter((x) => x.read == false));
  }

  /** Shows all notification in list, instead of the "Visa fler"-button */
  showAllNotifications(event: Event) {
    event.stopPropagation(); // This will stop the dropdown from self closing
    this.showAll = true;
  }

  /** Gets the user name from the authService */
  getUserName() {
    return this.authService.getUserName();
  }

  /** Triggers a logout action in the authService */
  logout() {
    this.authService.logout();
  }
  //#endregion

  //#region Private
  /** Initiate the component with needed subscriptions and values */
  private initiateComponent() {
    this.notificationService.connectToNotificationSignalRService();
    this.populateNotifications();
    this.productionEnvironment = environment.env.production;
  }

  /** Sends request to mark notifications as read  */
  private markAsRead(notifications: NotificationItem[]) {
    this.notificationService.setReadStatus(
      notifications.map((notification) => notification.id)
    );
  }

  /** Fills the notification list and subscribes to notification updates */
  private populateNotifications() {
    this.notificationsSubscription = this.notificationService.notifications.subscribe(
      (response: NotificationItem[]) => {
        this.notifications = response;
        this.setHasUnreadNotifications();
        this.sendSystemNotifications();
        this.cdr.detectChanges();
      }
    );
    this.notificationService.fetchNotifications();
  }

  /** Sets the hasUnreadNotifications to true if any notifications have read as false */
  private setHasUnreadNotifications() {
    this.hasUnreadNotifications = this.notifications.some(
      (n) => n.read == false
    );
  }

  private async sendSystemNotifications() {
    var permission: NotificationPermission;
    if (this.hasUnreadNotifications) {
      let sortedAndFilteredNotifications = this.notifications
        .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
        .filter((x) => !x.read);
      for (let notification of sortedAndFilteredNotifications) {
        if (!permission || permission != "granted") {
          permission = await SystemNotificationService.askPermissionAndSendNotification(
            notification
          );
        } else {
          SystemNotificationService.sendNotification(notification);
        }
      }
    }
  }

  //#endregion
  ngOnDestroy() {}
}
