import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "dua-redirect-page",
  templateUrl: "./redirect-page.component.html",
  styleUrls: ["./redirect-page.component.scss"],
})
export class RedirectPageComponent implements OnInit {
  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    if (this.authService.getIsLoggedIn()) this.router.navigateByUrl("/dua");
  }
}
