import { Component, Input, OnInit } from "@angular/core";
import { EventLogService } from "src/app/services/event-log.service";

@Component({
  selector: "dua-event-log",
  templateUrl: "./event-log.component.html",
  styleUrls: ["./event-log.component.scss"],
})
export class EventLogComponent implements OnInit {
  @Input() eventId: number;
  eventTextLogs: string[] = [];

  constructor(private eventLogService: EventLogService) {}

  ngOnInit(): void {
    this.getEventLogs();
  }

  getEventLogs() {
    this.eventLogService
      .getEventLogs(this.eventId)
      .subscribe((logs: string[]) => {
        this.eventTextLogs = logs;
      });
  }
}
