import { Injectable, OnDestroy } from "@angular/core";
import { PhysicalLinePosition } from "../models/enums";
import { KeyValuePair } from "../models/key-value-pair";
import { MapNode } from "../models/line-map/map-node";
import { Station } from "../models/station";
import { AutoUnsubscribe } from "../shared/decorators";
import nodeData from "./../../assets/data/map-nodes.json";
import specialNodePairData from "./../../assets/data/special-node-pairs.json";
import { ILineMapService } from "./interfaces/i-line-map.service";

/** Service to handle line map and map nodes */
@Injectable({
  providedIn: "root",
})
@AutoUnsubscribe()
export class LineMapService implements ILineMapService, OnDestroy {
  public mapNodes: MapNode[] = [];
  private nodePairs: KeyValuePair<MapNode, MapNode>[] = [];
  private stationData: Station[] = [];
  private specialNodePairs: KeyValuePair<
    KeyValuePair<MapNode, MapNode>,
    KeyValuePair<string, string>[]
  >[] = [];

  constructor() {}

  //#region Public

  /** Toggle a single map node between active and not active */
  public toggleMapNode(node: MapNode): MapNode {
    node.active = !node.active;
    return node;
  }

  /** Set an array of map nodes to active */
  public activateMapNodes(nodes: MapNode[]): MapNode[] {
    nodes.forEach((x) => (x.active = true));
    return nodes;
  }

  /** Set a single map node to active */
  public activateMapNode(node: MapNode): MapNode {
    node.active = true;
    return node;
  }

  /** Get all normal paths (node pairs) from a single map node */
  public getPathsFromNode(node: MapNode) {
    return this.nodePairs.filter(
      (x) => x.key.id == node.id || x.value.id == node.id
    );
  }

  /** Get special paths (node pairs with special drawing data) from a single map node */
  public getSpecialPathsFromNode(
    node: MapNode
  ): KeyValuePair<
    KeyValuePair<MapNode, MapNode>,
    KeyValuePair<string, string>[]
  >[] {
    return this.specialNodePairs.filter(
      (x) => x.key.key.id == node.id || x.key.value.id == node.id
    );
  }

  /** Get all normal paths (node pairs) from an array of nodes */
  public getPathsFromNodes(nodes: MapNode[]): KeyValuePair<MapNode, MapNode>[] {
    return this.nodePairs.filter(
      (x) =>
        nodes.some((node) => node.id == x.key.id) ||
        nodes.some((node) => node.id == x.value.id)
    );
  }

  /**Get special paths (node pairs with special drawing data) from an array of nodes */
  public getSpecialPathsFromNodes(
    nodes: MapNode[]
  ): KeyValuePair<
    KeyValuePair<MapNode, MapNode>,
    KeyValuePair<string, string>[]
  >[] {
    return this.specialNodePairs.filter(
      (x) =>
        nodes.some((node) => x.key.key.id == node.id) ||
        nodes.some((node) => x.key.value.id == node.id)
    );
  }

  /** Get all normal paths (node pairs) */
  public getAllPaths(): KeyValuePair<MapNode, MapNode>[] {
    let paths = [...this.nodePairs];
    return paths;
  }
  /**Get all special paths (node pairs with special drawing data)  */
  public getAllSpecialPaths(): KeyValuePair<
    KeyValuePair<MapNode, MapNode>,
    KeyValuePair<string, string>[]
  >[] {
    let paths = [...this.specialNodePairs];
    return paths;
  }

  /** Get paths (node pairs) to highlight from line number data */
  public getPathsToHighlight(
    lineNumbers: string[]
  ): KeyValuePair<MapNode, MapNode>[] {
    let nodeList = this.mapNodes.filter((x) =>
      x.lines.some((r) => lineNumbers.includes(r))
    );

    return this.nodePairs.filter(
      (x) => nodeList.includes(x.key) && nodeList.includes(x.value)
    );
  }

  /** Get special paths (node pairs with special drawing data) to highlight from line number data */
  public getSpecialPathsToHighlight(
    lineNumbers: string[]
  ): KeyValuePair<
    KeyValuePair<MapNode, MapNode>,
    KeyValuePair<string, string>[]
  >[] {
    let nodeList = this.mapNodes.filter((x) =>
      x.lines.some((r) => lineNumbers.includes(r))
    );
    return this.specialNodePairs.filter(
      (x) => nodeList.includes(x.key.key) && nodeList.includes(x.key.value)
    );
  }



  /** Get all available nodes to use for finding paths */
  public getAllNodesForPathfinding(initialLinePosition: PhysicalLinePosition, destinationLinePosition: PhysicalLinePosition): KeyValuePair<MapNode, MapNode>[]
  {
    let allNodePairs = [...this.nodePairs, ...this.specialNodePairs.map((x) => x.key)];

    let linePositions = [initialLinePosition, destinationLinePosition];
    let extraStation: string;

    if (
      linePositions.includes(PhysicalLinePosition.NorthEast) &&
      linePositions.includes(PhysicalLinePosition.NorthWest)
    ) {
      // extraStation = "SOD";
    } else if (
      linePositions.includes(PhysicalLinePosition.SouthEast) &&
      linePositions.includes(PhysicalLinePosition.SouthWest)
    ) {
      // extraStation = "ÄS";
    } else if (
      (linePositions.includes(PhysicalLinePosition.NorthWest) &&
        linePositions.includes(PhysicalLinePosition.SouthWest)) ||
      (linePositions.includes(PhysicalLinePosition.SouthEast) &&
        linePositions.includes(PhysicalLinePosition.NorthEast)) ||
      (linePositions.includes(PhysicalLinePosition.SouthEast) &&
        linePositions.includes(PhysicalLinePosition.NorthWest)) ||
      (linePositions.includes(PhysicalLinePosition.SouthWest) &&
        linePositions.includes(PhysicalLinePosition.NorthEast)) ||
      (linePositions.includes(PhysicalLinePosition.SouthWest) &&
      linePositions.includes(PhysicalLinePosition.None)) ||
      (linePositions.includes(PhysicalLinePosition.SouthEast) &&
      linePositions.includes(PhysicalLinePosition.None))
    ) {
      linePositions.push(PhysicalLinePosition.Center);
    }

    allNodePairs = allNodePairs.filter(
      (x) => 
        linePositions.includes(x.key.physicalPosition) ||
        linePositions.includes(x.value.physicalPosition) ||
        (extraStation &&
          (x.value.station.stationCode == extraStation ||
            x.key.station.stationCode == extraStation))
    );

    return allNodePairs;
  }

  /** Create map nodes from station and line data and return them  */
  public getMapNodesFromStations(
    stations: Station[],
    linesWithStations: { [line: string]: string[] }
  ): MapNode[] {
    this.stationData = stations;
    this.createMapNodes(linesWithStations);
    this.createNodePairs();
    return this.mapNodes;
  }
  //#endregion

  //#region Private
  /** Create map node based on param for line data with stations and map-nodes.json file which contains position for map nodes in GUI */
  private createMapNodes(linesWithStations: { [line: string]: string[] }) {
    this.mapNodes = nodeData
      .map((x) => {
        let station = this.stationData.find(
          (station) => station.stationCode == x.stationCode
        );
        if (station) {
          let lines = this.getLinesForStation(station, linesWithStations);
          return new MapNode(
            station,
            x.positions,
            x.textPlacement,
            lines,
            x.physicalPosition
          );
        } else {
          console.error(`No station with code ${x.stationCode} found`);
          return;
        }
      })
      .filter((x) => this.isExistingObject(x));
  }

  /** Get lines which pass through the station */
  private getLinesForStation(
    station: Station,
    linesWithStations: { [line: string]: string[] }
  ): string[] {
    const lines = [];
    for (let line of Object.keys(linesWithStations)) {
      if (linesWithStations[line].includes(station.stationCode)) {
        lines.push(line);
      }
    }
    return lines;
  }

  /** Create normal and special paths (node pairs)  */
  private createNodePairs() {
    this.specialNodePairs = specialNodePairData.map((x) => {
      let nodePair = x.key;

      let key = nodePair.key;
      let keyNode = this.mapNodes.find(
        (n) => n.station.stationCode == key.stationCode
      );
      let value = nodePair.value;
      let valueNode = this.mapNodes.find(
        (n) => n.station.stationCode == value.stationCode
      );

      if (
        keyNode &&
        valueNode &&
        keyNode.station.connectedTo.includes(value.stationCode)
      ) {
        let positions = x.value;
        return new KeyValuePair(
          new KeyValuePair(keyNode, valueNode),
          positions
        );
      } else {
        console.warn(
          `Missing connection between ${key.stationCode} and ${value.stationCode}. Will not create special node pair`
        );
      }
    });

    this.specialNodePairs = this.specialNodePairs.filter((nodePair) =>
      this.isExistingObject(nodePair)
    );

    for (let node of this.mapNodes) {
      node.station.connectedTo.map((station) => {
        //Check if special node pair exists. Return, without creating node pair if it does.
        if (
          this.checkIfSpecialNodePairExists(node.station.stationCode, station)
        ) {
          return;
        }

        //Check if node pair already exists. If node pair doesn't exist, create it.
        if (!this.checkIfNodePairExists(node.station.stationCode, station)) {
          let connectedNode = this.mapNodes.find(
            (cNode) => cNode.station.stationCode == station
          );

          //If connected node exists, add pair to node pairs
          if (connectedNode)
            this.nodePairs.push(new KeyValuePair(node, connectedNode));
        }
      });
    }
  }

  /** Check if there is a normal path between two stations */
  private checkIfNodePairExists(
    currentStation: string,
    connectedStation: string
  ): boolean {
    let filteredPairs = this.nodePairs.filter(
      (element) =>
        element.key.station.stationCode == currentStation &&
        connectedStation == element.key.station.stationCode
    );

    return filteredPairs && filteredPairs.length > 0;
  }

  /** Check if there is a special path between two stations */
  private checkIfSpecialNodePairExists(
    currentStation: string,
    connectedStation: string
  ): boolean {
    let filteredPairs = this.specialNodePairs.filter((element) => {
      let keyStation = element.key.key.station.stationCode;
      let valueStation = element.key.value.station.stationCode;
      if (keyStation && valueStation) {
        return (
          (keyStation == currentStation && valueStation == connectedStation) ||
          (keyStation == connectedStation && valueStation == currentStation)
        );
      }
    });

    return filteredPairs && filteredPairs.length > 0;
  }

  /** Returns true is object is not null or undefined.
   * Used to sort out non-existing nodes and node pairs*/
  private isExistingObject(x: any) {
    return x != null && x != undefined;
  }
  //#endregion

  ngOnDestroy() {}
}
