import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { cloneDeep } from "lodash";
import { Observable, of, Subject } from "rxjs";
import { PopupButton, PopupType } from "../models/popup-enums";
import { PopupInformation } from "../models/popup-information";
import { AggregatedKeyValueException } from "../models/error/aggregated-key-value-exception";

/** Service to handle custom popup with preconstructed messages and interaction types */
@Injectable({
  providedIn: "root",
})
export class PopupService {
  shouldShow = new Subject<boolean>();

  private generalErrorMessage = "Kunde inte ansluta till Trafikverket, vänligen kontrollera nätverk och försök igen."; //This is a default-value that is overriden if there exist an error from the backend

  private errorWarning = new PopupInformation(
    "Fel från server",
    this.generalErrorMessage, //This is a default-value that is overriden if there exist an error from the backend
    [PopupButton.Ok],
    PopupType.Error
  );

  private trafficActionHasChanges = new PopupInformation("Du har ändringar registrerade på din trafikåtgärd", "Vill du fortsätta?",
    [PopupButton.No, PopupButton.Yes], PopupType.TrafficActionHasChanges)

  private reasonChangeConfirmation = new PopupInformation(
    "Ändringen påverkar underliggande annonsering!",
    "Vill du även applicera dessa ändringar på all underliggande annonsering och skicka till trafikverket?",
    [PopupButton.No, PopupButton.Cancel, PopupButton.Yes],
    PopupType.ReasonChange
  );

  private popups: PopupInformation[];

  private currentPopup: PopupInformation;

  okCallback: () => Observable<unknown>;
  yesCallback: () => Observable<unknown>;
  cancelCallback: () => Observable<unknown>;
  noCallback: () => Observable<unknown>;

  constructor() {
    this.popups = [
      this.errorWarning,
      this.reasonChangeConfirmation,
      this.trafficActionHasChanges
    ];
  }

  resetCallbacks() {
    this.okCallback = null;
    this.yesCallback = null;
    this.noCallback = null;
    this.cancelCallback = null;
  }
  resetErrorMessages(){
    this.errorWarning.header ="";
    this.errorWarning.keyValueErrors = null;
    this.errorWarning.information = "";
  }

  public setErrorPopup(response: HttpErrorResponse): void {
    if (AggregatedKeyValueException.IsAggregatedKeyValueException(response.error))
      this.setKeyValueError(response.error as AggregatedKeyValueException);
    else
      this.setGenericError(response);

    this.setCurrentPopup(
      PopupType.Error,
      null,
    );
  }

  private setKeyValueError(error: AggregatedKeyValueException): void {
    this.errorWarning.header = error.title;
    this.errorWarning.keyValueErrors = error.errors;
    this.errorWarning.information = "";
  }

  private setGenericError(response: HttpErrorResponse): void {
    if (response.error && typeof response.error !== 'object')
      this.errorWarning.information = response.error;
    else if (response.error?.message && typeof response.error?.message !== 'object')
      this.errorWarning.information = response.error.message;
    else if (response.message && typeof response.message !== 'object')
      this.errorWarning.information = response.message;
    //If no error in either of these, the default-value is left
    else
      this.errorWarning.information = this.generalErrorMessage;
  }

  setCurrentPopup(
    type: PopupType,
    okCallback?: () => Observable<unknown>,
    yesCallback?: () => Observable<unknown>,
    noCallback?: () => Observable<unknown>,
    cancelCallback?: () => Observable<unknown>
  ) {
    const emptyFunction = () => of({});
    const popupToSelect = this.popups.find((x) => x.type == type);

    this.okCallback = okCallback || emptyFunction;
    this.yesCallback = yesCallback || emptyFunction;
    this.noCallback = noCallback || emptyFunction;
    this.cancelCallback = cancelCallback || emptyFunction;

    if (popupToSelect) {
      this.currentPopup = cloneDeep(popupToSelect);
    } else {
      console.error(`No popup available for type ${PopupType[type] ?? type}`);
    }

    this.showPopup(true);
  }

  getCurrentPopup() {
    return this.currentPopup;
  }

  private showPopup(show: boolean) {
    this.shouldShow.next(show);
  }
}
