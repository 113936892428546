import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "dua-checkbox",
  templateUrl: "./checkbox.component.html",
  styleUrls: ["./checkbox.component.scss"],
})
export class CheckboxComponent implements OnInit {
  @Input() checked: boolean = true;
  @Input() disabled: boolean;
  @Input() label: string = "";
  @Input() image: string = "";
  @Input() overrideToggle: boolean = false;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  toggle() {
    //If toggling is done from the parent component, override the toggling
    if (!this.overrideToggle) {
      this.checked = !this.checked;
    }

    this.checkedChange.emit(this.checked);
  }
}
