import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { EventListItem } from "src/app/models/event/event-list-item";
import { NotificationService } from "src/app/services/notification.service";
import { SortService } from "src/app/services/sort.service";
import { NotificationItemType, SortProperty } from "../../../models/enums";
import { EventsResponse } from "../../../models/event/events-response";
import { EventContainerService } from "../../../services/event-container.service";
import { AutoUnsubscribe } from "../../../shared/decorators";

@Component({
  selector: "dua-event-list",
  templateUrl: "./event-list.component.html",
  styleUrls: ["./event-list.component.scss"],
})
@AutoUnsubscribe()
export class EventListComponent implements OnInit, OnDestroy {
  @Input() eventsResponse: EventsResponse;
  @Input() filterAnnouncement: string;

  currentSortSubscription: Subscription;
  currentSort = SortProperty.Alphabetically;

  constructor(
    private sortService: SortService,
    private eventContainerService: EventContainerService,
    private notificationService: NotificationService
  ) {
    this.currentSort = this.sortService.getCurrentSort();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  choseEvent(eventItem: EventListItem) {
    if (eventItem.notificationId && eventItem.notificationType == NotificationItemType.Event) {
      this.notificationService.setReadStatus([eventItem.notificationId]);
    }
    this.eventContainerService.populateEvent(eventItem.id);
  }
}
