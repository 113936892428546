import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Announcement } from "../../../../models/announcement/announcement";
import { AnnouncementCategoryText } from "../../../../models/announcement/announcement-category-text";
import { DropdownValue } from "../../../../models/dropdown-value";
import { AnnouncementService } from "../../../../services/announcement.service";
import { EventContainerService } from "../../../../services/event-container.service";
import { PopupService } from "../../../../services/popup.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "dua-announcement-text",
  templateUrl: "./announcement-text.component.html",
  styleUrls: ["./announcement-text.component.scss"],
})
export class AnnouncementTextComponent implements OnInit {
  @Input() announcement: Announcement;
  @Input() createMode: boolean;
  @Input() disabledForEdit: boolean;

  announcementCategories: DropdownValue[];
  announcementTexts: AnnouncementCategoryText[];
  open = true;
  filterText: string;
  announcementReplacementText: string;

  constructor(
    private announcementService: AnnouncementService,
    private eventContainerService: EventContainerService,
    private popupService: PopupService
  ) {}

  ngOnInit(): void {
    this.populateCategories();
    this.populateAnnouncementTexts();
    this.setAnnouncementReplacementText();
  }

  ngOnChanges(changes: SimpleChanges) {}

  populateCategories() {
    this.announcementCategories = this.announcementService
      .getAnnouncementCategories()
      .map((x) => new DropdownValue(x.displayName, x.categoryType));
  }

  announcementCategoryChange(category: DropdownValue) {
    this.announcement.categoryType = category.value;
    let categoryObject = new AnnouncementCategoryText({
      id: null,
      code: category.value,
      text: category.label,
      externalOperatorCanUse: null,
      properties: null
    });
    this.announcementService.emitSelectAnnouncementText(categoryObject);
    this.populateAnnouncementTexts();

    if (this.announcement?.categoryText?.properties) {
      this.announcement.categoryText.properties = [];
    }
  }

  inputValueChange(event) {
    this.filterText = event;
  }

  populateAnnouncementTexts() {
    const prefix = this.announcementService.getAnnouncementCategoryByType(
      this.announcement?.categoryType
    )?.prefix;

    if (prefix) {
      this.announcementService.getAnnouncementTextList(prefix).subscribe(
        (textList) => {
          this.eventContainerService.loading = false;
          this.announcementTexts = textList;
        },
        (error: HttpErrorResponse) => {
          console.error(error);
          this.popupService.setErrorPopup(error);
          this.eventContainerService.loading = false;
        }
      );
    } else {
      this.announcementTexts = [];
    }
  }

  setAnnouncementReplacementText() {
    this.announcementReplacementText = this.announcementService.getAnnouncementReplacementText(this.announcement);
  }
}
