import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { SortProperty } from "../models/enums";

/** Service to handle sorting of event list */
@Injectable({
  providedIn: "root",
})
export class SortService {
  private currentSort = SortProperty.StartTime;
  currentSortChanged = new Subject<SortProperty>();

  constructor() {}

  getCurrentSort(): SortProperty {
    return this.currentSort;
  }

  setCurrentSort(sort: SortProperty) {
    this.currentSort = sort;
    this.currentSortChanged.next(sort);
  }
}
