import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { cloneDeep } from "lodash";
import { asapScheduler, Observable, scheduled, Subject, Subscription } from "rxjs";
import {
  catchError,
  debounceTime,
  map,
  mergeMap,
  switchMap,
} from "rxjs/operators";
import { Event } from "src/app/models/event/event";
import { Train } from "src/app/models/train";
import { EventContainerHeaderService } from "src/app/services/event-container-header.service";
import { EventContainerService } from "src/app/services/event-container.service";
import { EventService } from "src/app/services/event.service";
import { AnnouncementCategoryGroup } from "../../../models/announcement/announcement-category-group";
import { DropdownValue } from "../../../models/dropdown-value";
import { EventCard, TrafficActionType, UserRoles } from "../../../models/enums";
import { PopupType } from "../../../models/popup-enums";
import { TrafficAction } from "../../../models/traffic-action/traffic-action";
import { AnnouncementService } from "../../../services/announcement.service";
import { AuthService } from "../../../services/auth.service";
import { FilterService } from "../../../services/filter.service";
import { PopupService } from "../../../services/popup.service";
import { ReasonService } from "../../../services/reason.service";
import { TrafficActionService } from "../../../services/traffic-action.service";
import { TrainService } from "../../../services/train.service";
import { AutoUnsubscribe } from "../../../shared/decorators";
import { AnnouncementCategory } from "src/app/models/announcement/announcement-category";
import { TrafficActionListItem } from "src/app/models/traffic-action/traffic-action-list-item";
import { RequestObjectBuilderService } from "src/app/services/request-object-builder.service";
import _ from "lodash";
import {
  AnnouncementCategoryType,
  AnnouncementState,
} from "src/app/models/announcement/announcement-enums";

@Component({
  selector: "dua-traffic-action",
  templateUrl: "./traffic-action.component.html",
  styleUrls: ["./traffic-action.component.scss"],
})
@AutoUnsubscribe()
export class TrafficActionComponent implements OnInit, OnDestroy {
  @Input() eventItem: Event;
  @Input() trafficAction: TrafficAction;

  originalTrafficAction: TrafficAction;
  trafficActionTemplates: DropdownValue[] = [];
  reasons: DropdownValue[] = [];
  reminderTimes: DropdownValue[] = [];
  useReadWriteAccess: boolean = false;
  trainTriggerSubscription: Subscription;
  trafficActionChangeSubscription: Subscription;
  categoryChangeSubscription: Subscription;
  stationChangeSubscription: Subscription;
  currentWindowHeight: number;
  createMode: boolean;
  hasReminder: boolean;
  announcementCategoryGroups: AnnouncementCategoryGroup[];
  announcementCategories: AnnouncementCategory[];
  loading: boolean;
  ableToSave: boolean = true;

  constructor(
    private authService: AuthService,
    private trafficActionService: TrafficActionService,
    private reasonService: ReasonService,
    private filterService: FilterService,
    private trainService: TrainService,
    private announcementService: AnnouncementService,
    private popupService: PopupService,
    private eventContainerService: EventContainerService,
    private eventContainerHeaderService: EventContainerHeaderService,
    private eventService: EventService,
    private requestObjectBuilderService: RequestObjectBuilderService
  ) {}

  ngOnInit(): void {
    this.createMode = !this.trafficAction?.id;
    this.initiateComponent();

    this.originalTrafficAction = cloneDeep(this.trafficAction);
  }

  private initiateComponent(): void {
    this.populateNotificationTimes();
    this.populateCategoriesFromAnnouncements();
    this.reasons = this.reasonService.getFilteredReasons();
    this.trafficActionTemplates = this.trafficActionService.getTemplates();
    this.filterAnnouncementsView();

    if (this.trafficAction) {
      this.trafficAction.templateType = this.trafficAction?.templateType;
      this.trafficAction.reasonId = this.trafficAction.reasonId || -1;
      if (this.trafficAction.reminderInMinutes) {
        this.hasReminder = true;
      }
    }

    this.trainTriggerSubscription = this.filterService
      .getFetchTrainsTrigger()
      .pipe(
        debounceTime(300),
        switchMap((triggered) => {
          const filter = _.cloneDeep(this.filterService.getFilter());
          this.loading = true;
          return this.trainService.getTrains(filter).pipe(
            mergeMap((trains) => {
              this.trafficAction.filterGeneratedTrains = trains;
              this.trafficAction.filter = filter;

              if (
                this.createMode &&
                (parseInt(TrafficActionType[this.trafficAction.templateType]) !=
                  TrafficActionType.OtherAdjustment || this.trafficAction.announcementCategoryGroups.length == 0)
              )
                return this.generateTrafficAction();

              if (this.shouldUpdateTrains(trains))
                return this.updateTrains(trains);

              // This is added for a specific condition when there is a TrainNumber in the original filter
              // while inside a traffic action, changing LineNumber in the filter 
              if (!this.createMode && filter.trainNumbers.length > 0) 
                return this.trafficActionService.getTrafficAction(this.trafficAction.id, filter);

              if (this.createMode && _.isEmpty(trains)) {
                this.announcementCategoryGroups = [];
                this.trafficAction.announcementCategoryGroups = [];
              }

              return scheduled([this.trafficAction], asapScheduler); // Return existing action
            })
          );
        })
      )
      .subscribe(
        (trafficAction) => {
          this.updateCurrentTrafficAction(trafficAction);
        },
        (error) => {
          this.popupService.setErrorPopup(error);
          this.loading = false;
        }
      );

    this.stationChangeSubscription = this.filterService
      .getStationCodesChange()
      .subscribe(
        (stationCodes) =>
          (this.trafficAction.filter.stationCodes = stationCodes)
      );

    this.categoryChangeSubscription = this.filterService
      .getCategoryChangeTrigger()
      .subscribe(() => {
        this.filterAnnouncementsView();
      });

    this.trafficActionChangeSubscription = this.eventContainerService
      .getTrafficActionChangeTrigger()
      .subscribe(() => {
        this.filterAnnouncementsView();
      });
  }

  private generateTrafficAction(): Observable<TrafficAction> {
    const affectedTrains = this.trafficAction.filterGeneratedTrains;
    let generateTrafficActionRequest = this.requestObjectBuilderService.buildGenerateTrafficActionRequest(
      this.trafficAction
    );
    return this.trafficActionService
      .generateTrafficAction(generateTrafficActionRequest)
      .pipe(
        map((trafficAction) => {
          trafficAction.filterGeneratedTrains = affectedTrains;
          this.ableToSave = true;
          return trafficAction;
        }),
        catchError(async (error) => {
          this.ableToSave = false;
          this.popupService.setErrorPopup(error);
          if (this.createMode) {
            if (this.trafficAction.filterGeneratedTrains && this.trafficAction.filterGeneratedTrains.length === 0) {
              this.trafficAction.trains = [];
            }
            this.announcementCategoryGroups = [];
            this.trafficAction.announcementCategoryGroups = [];
          }
          return this.trafficAction;
        })
      );
  }

  private updateTrains(trains: Train[]): Observable<TrafficAction> {
    const updateTrainsRequest = this.requestObjectBuilderService.buildUpdateTrafficActionTrainsRequest(
      this.trafficAction
    );
    return this.trainService.updateTrains(updateTrainsRequest).pipe(
      map((response) => {
        response.filterGeneratedTrains = trains;
        this.ableToSave = true;
        return response;
      }),
      catchError(async (error) => {
        this.ableToSave = false;
        this.popupService.setErrorPopup(error);
        return this.trafficAction;
      })
    );
  }

  isLoading(): boolean {
    return this.loading;
  }

  isAbleToSave(): boolean {
    return this.ableToSave;
  }

  filterAnnouncementsView(): void {
    let categoryFilter = this.filterService.getCategoryFilter();
    this.announcementCategoryGroups = this.trafficAction.announcementCategoryGroups.filter(
      (x) =>
        categoryFilter.includes(x.categoryType) || categoryFilter.length === 0
    );
  }

  updateCurrentWindowHeight(): void {
    //310 is the amount of px that needs to be subtracted in order to make the ddl responsive and able to show as much as possible
    this.currentWindowHeight = window.innerHeight - 310;
  }

  onCheckboxChange(checked: boolean): void {
    if (checked) {
      this.hasReminder = true;
      if (!this.trafficAction.reminderInMinutes) {
        this.trafficAction.reminderInMinutes = this.trafficAction.reminderInMinutes || 15;
      }
    } else {
      this.trafficAction.reminderInMinutes = null;
      this.hasReminder = false;
    }
  }

  private shouldUpdateTrains(trains: Train[]): boolean {
    let trafficActionTrains = this.trafficAction.trains.filter(
      (x) => !x.toBeDeleted
    );
    let trafficActionTrainNumbers = trafficActionTrains.map(x => x.trainNumber);
    let trainNumbers = trains.map(x => x.trainNumber);

    let addedTrains = !_.isEmpty(_.difference(trainNumbers, trafficActionTrainNumbers));
    let removedTrains = !_.isEmpty(_.difference(trafficActionTrainNumbers, trainNumbers));

    if (addedTrains || removedTrains) {
      return true;
    }

    return false;
  }

  private updateCurrentTrafficAction(
    trafficActionResponse: TrafficAction
  ): void {
    this.trafficAction = trafficActionResponse;
    this.announcementCategoryGroups =
      trafficActionResponse.announcementCategoryGroups;
    this.trafficActionService.selectTrafficAction(trafficActionResponse);
    this.filterService.switchFilterState(
      EventCard.TrafficActionForm,
      true,
      this.trafficAction.filter
    );
    this.loading = false;
  }

  onTemplateTypeChange(event: any): void {
    const templateType = event.value;
    if (this.createMode && templateType != TrafficActionType.OtherAdjustment) {
      this.loading = true;
      this.generateTrafficAction()
        .pipe(switchMap(async (trafficAction) => trafficAction))
        .subscribe((trafficAction) => {
          this.updateCurrentTrafficAction(trafficAction);
        });
    }
  }

  onReasonChange(event: any) {
    let reasonId = event.value;
    var cancelCategory = this.announcementCategoryGroups.find(
      (x) =>
        x.categoryType ===
        AnnouncementCategoryType[AnnouncementCategoryType.CancelledReplaced]
    );
    if (cancelCategory) {
      cancelCategory.announcements.forEach((x) => {
        if (
          !x.onlyInTrv &&
          x.categoryText?.properties &&
          x.categoryText?.properties?.length > 0
        ) {
          let property = x.categoryText.properties.find(
            (x) => x.key.toLowerCase() === "reason"
          );
          if (property) {
            property.value = this.reasonService.getTrvReasonText(reasonId);
            if (x.state !== AnnouncementState.New) {
              x.state = AnnouncementState.Changed;
            }
          }
        }
      });
    }
  }

  private populateCategoriesFromAnnouncements() {
    const categoryTypesFromGroups = this.trafficAction.announcementCategoryGroups?.map(
      (x) => x.categoryType
    );
    const distinctCategories = [...new Set(categoryTypesFromGroups)];
    const allCategories = this.announcementService.getAnnouncementCategories();
    this.announcementCategories = allCategories.filter((x) =>
      distinctCategories.includes(x.categoryType)
    );
    this.filterAnnouncementsView();
  }

  addAnnouncement() {
    const trains =
      this.trafficAction.filterGeneratedTrains &&
      this.trafficAction.filterGeneratedTrains.length > 0
        ? this.trafficAction.filterGeneratedTrains
        : this.trafficAction.trains;
    const announcement = this.announcementService.getNewAnnouncement(
      trains,
      this.trafficAction.id,
      this.trafficAction.eventId,
      this.trafficAction.filter
    );
    this.announcementService.selectAnnouncement(announcement);
    this.eventContainerService.openAnnouncementForm();
  }

  disableEdit() {
    return (
      this.useReadWriteAccess &&
      !this.authService.userHasMinimumRole(UserRoles.Write)
    );
  }

  onSubmit() {
    if (!this.isModelValid() || this.disableEdit()) {
      return;
    }

    if (this.createMode) {
      this.createAction();
    } else {
      this.popupService.setCurrentPopup(
        PopupType.TrafficActionHasChanges,
        null,
        // Yes
        () => scheduled([this.updateAction()], asapScheduler),
        // No, let user continue
        null,
        null
      );
    }
  }

  isModelValid(): boolean {
    return (
      this.trafficAction.templateType != TrafficActionType.UNDEFINED &&
      this.trafficAction.reasonId >= 0
    );
  }

  isActionEditable() {
    let reasonChanged = !_.isEqual(
      this.trafficAction?.reasonId,
      this.eventContainerService.currentTrafficAction?.reasonId
    );
    let filterChanged = !_.isEqual(
      this.trafficAction?.filter,
      this.eventContainerService.currentTrafficAction?.filter
    );
    let reminderChanged = !_.isEqual(
      this.trafficAction?.reminderInMinutes,
      this.eventContainerService.currentTrafficAction?.reminderInMinutes
    );

    if (this.trafficAction.announcementCategoryGroups.length === 0)
      return this.createMode;

    let announcements = this.trafficAction.announcementCategoryGroups
      .map((x) => x.announcements)
      .reduce((a, b) => a.concat(b));
    let announcementsChanged =
      announcements.filter(
        (x) =>
          x.state == AnnouncementState.New ||
          x.state == AnnouncementState.Changed ||
          x.state == AnnouncementState.Removed
      ).length > 0;
    return (
      reasonChanged || filterChanged || reminderChanged || announcementsChanged
    );
  }

  createAction() {
    this.eventContainerService.loading = true;
    if (!this.hasReminder) {
      this.trafficAction.reminderInMinutes = null;
    }
    scheduled([this.eventItem], asapScheduler)
      .pipe(
        mergeMap((eventItem) => {
          if (!eventItem?.id) {
            return this.eventService.createEvent(this.eventItem);
          } else {
            return scheduled([this.eventItem], asapScheduler); // Return existing event
          }
        }),
        map((eventItem) => {
          this.eventService.selectEvent(eventItem);
          this.eventItem = eventItem;
          this.trafficAction.eventId = eventItem.id;

          return eventItem;
        }),
        map((eventItem) => {
          this.trafficActionService
            .createTrafficAction(
              this.requestObjectBuilderService.buildTrafficActionRequest(
                this.trafficAction
              )
            )
            .subscribe(
              (trafficAction) => {
                this.trafficAction = trafficAction;
                this.createMode = false;
                this.eventContainerHeaderService.headerText = this.trafficAction.title;

                //TODO: Add trafficActionListItem to parent event
                const trafficActionListItem: TrafficActionListItem = {
                  trafficActionId: this.trafficAction.id,
                  title: this.trafficAction.title,
                  status: "Unsent",
                  trains: this.trafficAction.trains,
                  notificationId: null,
                  notified: false,
                  fromDate: this.trafficAction.filter.fromDate,
                  toDate: this.trafficAction.filter.toDate,
                };

                this.eventItem.actions.push(trafficActionListItem);
                this.returnToEvent();
              },
              (error: HttpErrorResponse) => {
                this.eventService
                  .deleteEventIfEmpty(eventItem.id)
                  .subscribe(),
                  (error) => {
                    console.error(error);
                  };
                console.error(error);
                this.popupService.setErrorPopup(error);
                this.eventContainerService.loading = false;
              }
            );
        })
      )
      .subscribe();
  }

  updateAction() {
    this.eventContainerService.loading = true;
    if(!this.hasReminder) {
      this.trafficAction.reminderInMinutes = null;
    }
    scheduled([this.eventItem], asapScheduler)
      .pipe(
        mergeMap((eventItem) => {
          return scheduled([this.eventItem], asapScheduler); // Return existing event
        }),
        map((eventItem) => {
          this.eventService.selectEvent(eventItem);
          this.eventItem = eventItem;
          this.trafficAction.eventId = eventItem.id;
          return eventItem;
        }),
        map((eventItem) => {
          this.trafficActionService
            .updateTrafficAction(
              this.requestObjectBuilderService.buildTrafficActionRequest(
                this.trafficAction
              )
            )
            .subscribe(
              (trafficAction) => {
                this.trafficAction = trafficAction;
                this.createMode = false;
                this.eventContainerHeaderService.headerText = this.trafficAction.title;

                const trafficActionListItem: TrafficActionListItem = {
                  trafficActionId: this.trafficAction.id,
                  title: this.trafficAction.title,
                  status: "Unsent",
                  trains: this.trafficAction.trains,
                  notificationId: null,
                  notified: false,
                  fromDate: this.trafficAction.filter.fromDate,
                  toDate: this.trafficAction.filter.toDate,
                };
                const idx = this.eventItem.actions.findIndex(
                  (x) =>
                    x.trafficActionId == trafficActionListItem.trafficActionId
                );
                this.eventItem.actions[idx] = trafficActionListItem;
                this.returnToEvent();
                this.eventContainerService.loading = false;
              },
              (error: HttpErrorResponse) => {
                console.error(error);
                this.popupService.setErrorPopup(error);
                this.eventContainerService.loading = false;
              }
            );
        })
      )
      .subscribe();
  }

  onClickReturnButton() {
    this.returnToEvent();
  }

  returnToEvent() {
    this.trafficActionService.selectTrafficAction(null);
    this.trafficAction = null;
    this.eventContainerService.returnToEvent();
  }

  populateNotificationTimes() {
    for (let i = 0; i <= 15; i += 5) {
      this.reminderTimes.push(new DropdownValue(`${i} min innan`, i));
    }
  }

  ngOnDestroy() {
    this.trafficAction = null;
  }
}
