import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { Paging } from "../models/event/paging";

/** Service to handle paging */
@Injectable({
  providedIn: "root",
})
export class PagingService {
  private defaultNumber: number = 0;
  private defaultLimit = 10;

  private paging = new Paging(this.defaultLimit, this.defaultNumber);
  private pageChange = new Subject<number>();

  constructor() {}

  public getPaging(): Paging {
    return this.paging;
  }

  public getPageNumber(): number {
    return this.paging.page;
  }

  public getPageLimit(): number {
    return this.paging.limit;
  }

  public resetPaging() {
    this.paging = new Paging(this.defaultLimit, this.defaultNumber);
  }

  public setPageNumber(page: number) {
    this.paging.page = page;
  }

  public getPageChange(): Observable<number> {
    return this.pageChange;
  }

  public emitPageChange(value: number): void {
    this.pageChange.next(value);
  }
}
