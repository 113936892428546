import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
import { NotificationItem } from "../models/notification/notification-item";
import { NotificationResponse } from "../models/notification/notification-response";
import { INotificationService } from "./interfaces/i-notification.service";
import { SignalRService } from "./signal-r.service";
import { PopupService } from "./popup.service";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle notification list and notification API */
@Injectable({
  providedIn: "root",
})
export class NotificationService implements INotificationService {
  private readonly notificationSegment = "Notification";
  private readonly notificationSignalRSegment = "notification";
  private readonly setToReadSegment = "SetToRead";

  private _notificationsSource = new BehaviorSubject<NotificationItem[]>([]);
  public readonly notifications = this._notificationsSource.asObservable();

  private readonly headers = { "Api-Version": environment.notificationApi.version };

  constructor(
    private httpClient: HttpClient,
    private signalRService: SignalRService,
    private popupService: PopupService,
    private urlBuilderService: UrlBuilderService
  ) {}

  public getNotifications(): NotificationItem[] {
    return this._notificationsSource.getValue();
  }

  public connectToNotificationSignalRService(): void {
    let callbackFunctions: Function[] = [];
    callbackFunctions.push(this.newNotification.bind(this));

    this.signalRService.createConnection(
      this.urlBuilderService.buildUrl([
        environment.notificationApi.path.replace("/api", ""),
        this.notificationSignalRSegment
      ]),
      callbackFunctions,
      environment.config.notificationScope
    );
  }

  public fetchNotifications(): void {
    this.httpClient
      .get<NotificationItem[]>(
        this.urlBuilderService.buildUrl([
          environment.notificationApi.path,
          this.notificationSegment,
        ]),
        { headers: this.headers }
      )
      .subscribe((notifications) => {
        this._notificationsSource.next(notifications);
      });
  }

  private newNotification(id: number): void {
    this.fetchNotifications();
  }

  public setReadStatus(notificationIds: number[]): void {
    // Mark as read in local source & publish
    const notifications = this.getNotifications().map((notification) => {
      if (notificationIds.includes(notification.id)) {
        notification.read = true;
      }
      return notification;
    });
    this._notificationsSource.next(notifications);

    // Send to server
    this.httpClient
      .post<NotificationResponse>(
        this.urlBuilderService.buildUrl([
          environment.notificationApi.path,
          this.notificationSegment,
          this.setToReadSegment,
        ]),
        notificationIds,
        { headers: this.headers, }
      )
      .subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          console.error(error);
          this.popupService.setErrorPopup(error);
        }
      );
  }
}
