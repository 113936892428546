/** Static class for helping with calculations and transformations that can be used throughout the system */
export class Helper {
  /**Round a value to a decimal precision
   * @param value The value that you want a rounded value from
   * @param precision the precision you want to use
   */
  static round(value: number, precision: number): number {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  /** Custom function to implement a C#-like selectMany functionality */
  static selectMany(arr: any[], property) {
    return arr.reduce((pn, u) => [...pn, ...u[property]], []);
  }

  /** Checks if a date is actually instance of Date */
  static isValidDate(d): boolean {
    return d instanceof Date && d.getTime && !isNaN(d.getTime());
  }

  /** Returns a new date with the added hours */
  static addHours(date: Date, hours: number): Date {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  }

  static resetSeconds(date: Date): void {
    if (date) {
      date.setSeconds(0);
      date.setMilliseconds(0);
    }
  }

  /** Sets day, month and year from one Date object to another as JavaScript Date object is missing
   * @param dateToTransform - The date that will get a new day, month and year
   * @param dataDate - The date that has the wanted day, month and year
   */
  static setDateFromOtherDate(dateToTransform: Date, dataDate: Date) {
    dateToTransform.setDate(dataDate.getDate()); //day of month
    dateToTransform.setMonth(dataDate.getMonth());
    dateToTransform.setFullYear(dataDate.getFullYear());
  }

  /** Get color from document */
  static getCssProperty(propertyName: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(
      propertyName
    );
  }

  /** Compares two string arrays by sorting them alphabetically and converting them into strings to see if they match */
  static compareStringArrays(arr1: string[], arr2: string[]) {
    arr1 = arr1?.sort((a, b) => {
      return a.localeCompare(b);
    });
    arr2 = arr2?.sort((a, b) => {
      return a.localeCompare(b);
    });
    return JSON.stringify(arr1) == JSON.stringify(arr2);
  }

  /** Convets an enum to another enum and returns the value. */
  static convertEnumToEnum<T1, T2>(value: T1): T2 {
    return (<any>value) as T2;
  }

  static isEvenNumber(number: number): boolean {
    return number % 2 === 0;
  }

  static isOddNumber(number: number): boolean {
    return !this.isEvenNumber(number);
  }

  static isNumber(value: string) {
    return /^\d*$/.test(value);
  }
}
