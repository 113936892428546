import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NotificationItemType, UserRoles } from "src/app/models/enums";
import { Event } from "src/app/models/event/event";
import { AuthService } from "src/app/services/auth.service";
import { NotificationService } from "src/app/services/notification.service";
import { EventContainerService } from "src/app/services/event-container.service";
import { TrafficActionListItem } from "src/app/models/traffic-action/traffic-action-list-item";

@Component({
  selector: "dua-traffic-action-list",
  templateUrl: "./traffic-action-list.component.html",
  styleUrls: ["./traffic-action-list.component.scss"],
})

export class TrafficActionListComponent implements OnInit {
  @Input() useReadWriteAccess: boolean;
  @Input() event: Event;


  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private eventContainerService: EventContainerService,
  ) {}

  ngOnChanges(): void {}

  ngOnInit(): void {
    this.notificationService.notifications.subscribe(() =>
      this.enrichWithNotificationsDot()
    );

    this.eventContainerService.eventsResponseChange?.subscribe((change) => {
      const eventChange = change.events.find(
        (e) => e.id === this.event.id
      );
      if (eventChange) {
        this.enrichWithNotificationsDot();
      }
    });
  }

  private enrichWithNotificationsDot() {
    const notifications = this.notificationService.getNotifications();

    this.event.actions?.forEach((trafficAction) => {
      const trafficActionNotification = notifications?.find(
        (notification) => {
          const itemType: NotificationItemType = (typeof(notification.itemType) === 'string') ? (<any>NotificationItemType)[notification.itemType] : notification.itemType;
          if (itemType === NotificationItemType.TrafficAction &&
          notification.itemId === trafficAction.trafficActionId &&
          !notification.read) {
            return notification;
          }
        }
      );
      trafficAction.notificationId =
        trafficActionNotification != null ? trafficActionNotification.id : null;
    });
  }

  disableEdit() {
    return (
      (this.useReadWriteAccess &&
        !this.authService.userHasMinimumRole(UserRoles.Write)) ||
      !this.event ||
      !this.event.reasonId ||
      this.event.reasonId <= 0
    );
  }

  hasWriteAccess() {}

  addTrafficAction() {
    this.eventContainerService.initiateNewTrafficAction();
  }

  editTrafficAction(trafficAction: TrafficActionListItem) {
    this.eventContainerService.initiateEditTrafficAction(
      trafficAction.trafficActionId,
      trafficAction.notificationId
    );
  }
}
