import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { PagingService } from "../../../../services/paging.service";

@Component({
  selector: "dua-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.scss"],
})
export class PaginatorComponent implements OnInit {
  @Input() totalNumberOfPages: number;
  @Input() pageLimit: number;
  @Input() currentPage: number;

  pages: number[] = [];
  constructor(
    private ref: ChangeDetectorRef,
    private pagingService: PagingService
  ) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.addPages();
  }

  addPages() {
    this.pages = [];
    for (var i = 1; i <= this.totalNumberOfPages; i++) {
      this.pages.push(i);
    }
    this.ref.detectChanges();
  }

  numberToShow(pageNumber: number) {
    if (this.showPageNo(pageNumber)) {
      return pageNumber;
    } else if (this.showDots(pageNumber)) {
      return "..";
    } else {
      return "";
    }
  }

  showPageNo(pageNumber: number) {
    if (
      pageNumber == 1 ||
      pageNumber == this.currentPage ||
      pageNumber == this.currentPage + 1 ||
      pageNumber == this.currentPage - 1 ||
      pageNumber == this.pages[this.pages.length - 1]
    ) {
      return true;
    } else {
      return false;
    }
  }

  showDots(pageNumber: number) {
    if (
      (!this.showPageNo(pageNumber) && pageNumber == this.currentPage + 2) ||
      pageNumber == this.currentPage - 2
    ) {
      return true;
    } else {
      return false;
    }
  }

  onClickPreviousPage() {
    if (this.currentPage > 1) {
      this.pagingService.emitPageChange(this.currentPage - 1);
    }
  }

  onClickNextPage() {
    if (this.currentPage < this.totalNumberOfPages) {
      this.pagingService.emitPageChange(this.currentPage + 1);
    }
  }

  onClickPageNumber(pageNumber: number) {
    if (pageNumber == this.currentPage) return;
    this.pagingService.emitPageChange(pageNumber);
  }
}
