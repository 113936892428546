import { Component, Input, OnInit } from "@angular/core";
import { AnnouncementHistory } from "../../../../models/announcement/announcement-history";

@Component({
  selector: "dua-announcement-history-list",
  templateUrl: "./announcement-history-list.component.html",
  styleUrls: ["./announcement-history-list.component.scss"],
})
export class AnnouncementHistoryListComponent implements OnInit {
  @Input() history: AnnouncementHistory[];

  ngOnInit(): void {
   this.history = this.history?.sort(
     (a,b) => b.createdTime.getTime() - a.createdTime.getTime());
  }
}
