export const environment = {
  env: {
    production: false,
    development: true,
    development2: false,
    test: false,
    local: false,
  },
  config: {
    clientID: "8ea17b40-c4b0-4551-95b3-b28feaf96d4a",
    tenantID: "2146c4ae-6471-40a8-b821-2774c8057963",
    apiScopes: ["api://8ea17b40-c4b0-4551-95b3-b28feaf96d4a/access_as_user"],
    actionScope: ["api://655e179c-1fd5-4a24-8b17-30a786f972bb/access_as_user"],
    reasonScope: ["api://17489ed9-f72e-4e7e-b850-c05c96396ff4/access_as_user"],
    stationsScope: ["api://327173e3-b6b3-41bf-b22e-abe94b230031/access_as_user"],
    linenumberScope: ["api://1d0472a4-1570-4381-99b6-0e4eb7941b08/access_as_user"],
    eventlogScope: ["api://47071e22-1824-4006-bf9d-db1c0ddf70e9/access_as_user"],
    notificationScope: ["api://a96f76ba-65d8-4d8d-9e47-e150a4c9708c/access_as_user"],
    trainScope: ["api://2999abe2-e531-49da-85b3-7baa3f04c9b6/access_as_user"],
    url: "https://dev-kvitter.stockholmstag.sj.se",
    redirectUrl: "https://dev-kvitter.stockholmstag.sj.se/signin-oidc",
    scopes: ["user.read", "openid", "profile"],
  },
  graphUrl: "https://graph.microsoft.com/v1.0",
  apiUrl: "https://dev-api.stockholmstag.sj.se/public",
  eventApi: {
    path: "dua-dev/api",
    version: "1.0",
  },
  actionApi: {
    path: "actionapi/api",
    version: "1.0",
  },
  reasonApi: {
    path: "reasonsapi/api",
    version: "1.0",
  },
  stationsApi: {
    path: "stationsapi/api",
    version: "1.0",
  },
  linenumberApi: {
    path: "linenumbersapi/api",
    version: "1.0",
  },
  eventLogApi: {
    path: "eventlogapi/api",
    version: "1.0",
  },
  notificationApi: {
    path: "notification/api",
    version: "1.0",
  },
  announcementApi: {
    path: "announcements-api-dev/api",
    version: "1.0",
  },
  trainApi: {
    path: "trainapi/api",
    version: "1.0",
  },
  versionApi: {
    path: "versions/dua",
    version: "1.0",
  },
  webVersion: "#{Build.SourceVersion}# #{Build.BuildId}# #{Build.BuildNumber}#",
  trainsOnTimeUrl: "https://dev-pendeltag.stockholmstag.sj.se/",
  trainsOnTimeAdminUrl: "https://dev-pendeltag.stockholmstag.sj.se/admin",
  trafficInformationUrl: "https://dev-amigo.stockholmstag.sj.se/",
  DLI: ["0279585f-54d4-4716-896d-965f725ce38e", "56f10bdb-af99-4eb3-a29a-98c3cfac118f"],
};
