import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Announcement } from "src/app/models/announcement/announcement";
import { AnnouncementService } from "src/app/services/announcement.service";
import { FilterService } from "src/app/services/filter.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AnnouncementsModalStateService {
  private _isOpen = new BehaviorSubject<boolean>(false);
  public isOpen = this._isOpen.asObservable();

  public announcements: Announcement[] = [];
  public positionTop = 0;
  public positionLeft = 0;
  public loading = false;
  public stationCode: string;

  constructor(
    private httpClient: HttpClient,
    private filterService: FilterService,
    private announcementService: AnnouncementService
  ) {}

  getIsOpen() {
    return this._isOpen.getValue();
  }

  setIsOpen(value: boolean): void {
    this._isOpen.next(value);
  }

  position(positionTop: number, positionLeft: number) {
    this.positionLeft = positionLeft;
    this.positionTop = positionTop;
  }

  reload() {
    this.show(this.stationCode);
  }

  show(stationCode: string) {
    this.announcements = [];
    this.stationCode = stationCode;

    this.loading = true;
    this._isOpen.next(true);

    this.announcementService.getAnnouncementsForStation(stationCode).subscribe((announcements) => {
      this.announcements = announcements;
      this.loading = false;
    });
  }
}
