export interface ITrain {
  trainNumber: string;
  inKvitter: boolean;
  isFilterTrain: boolean;
  isActivityTrain: boolean;
  toBeDeleted: boolean;
}

export class Train implements ITrain {
  public trainNumber: string;
  public inKvitter: boolean;
  public isFilterTrain: boolean;
  public isActivityTrain: boolean;
  public toBeDeleted: boolean;

  constructor(trainNumber: string, inKvitter?: boolean,isFilterTrain?: boolean, isActivityTrain?: boolean, toBeDeleted?: boolean){
    this.trainNumber = trainNumber;
    this.inKvitter = inKvitter ?? false;
    this.isFilterTrain = isFilterTrain ?? false;
    this.isActivityTrain = isActivityTrain ?? false;
    this.toBeDeleted = toBeDeleted ?? false;
  }
}