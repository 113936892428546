import { Filter } from "./filter";
import { Paging } from "./paging";
import { Sorting } from "./sorting";

export class EventQuery {
  constructor(
    public filter: Filter,
    public paging: Paging,
    public sorting: Sorting
  ) {}
}
