import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "dua-input-field",
  templateUrl: "./input-field.component.html",
  styleUrls: ["./input-field.component.scss"],
})
export class InputFieldComponent implements OnInit {
  @Input() label: string;
  @Input() adjusted: boolean = false;
  @Input() isValidCallback: (value: string) => boolean;
  @Input() saveOnEnter: boolean = true;
  @Input() disabled: boolean;
  @Output() save: EventEmitter<string> = new EventEmitter();
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  @Input() text: string;
  constructor() {}

  hasErrors: boolean;

  ngOnInit(): void {}

  saveInput(event) {
    const value = event.target.value;

    if (this.isValidCallback && !this.isValidCallback(value)) {
      this.hasErrors = true;
      return;
    }

    if (this.saveOnEnter) {
      this.save.emit(value);
      event.target.value = "";
    }
  }

  onChange(event) {
    this.hasErrors = false;
    this.valueChange.emit(event);
  }
}
