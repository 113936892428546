import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { FilterType } from "../../../models/enums";
import { Filter } from "../../../models/event/filter";
import { FilterService } from "../../../services/filter.service";
import { AutoUnsubscribe } from "../../../shared/decorators";

@Component({
  selector: "dua-train-number-filter",
  templateUrl: "./train-number-filter.component.html",
  styleUrls: [
    "./../filters.component.scss",
    "./train-number-filter.component.scss",
  ],
})
@AutoUnsubscribe()
export class TrainNumberFilterComponent implements OnInit, OnDestroy {
  open: boolean;
  defaultTitle = "Samtliga tåg";
  trainNumbers: string;
  filterType = FilterType.TrainNumber;

  trainNumberChangeSubscription: Subscription;
  currentOpenedFilterSubscription: Subscription;
  filterUpdatedSubscription: Subscription;
  resetSubscription: Subscription;

  constructor(private filterService: FilterService) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  private initiateComponent() {
    this.trainNumbers = this.defaultTitle;
    this.subscribeToChanges();
    this.resetFilter();
  }

  subscribeToChanges() {
    this.trainNumberChangeSubscription = this.filterService
      .getTrainNumbersChange()
      .subscribe((change) => {
        this.setDisplayValue(change);
      });

    this.filterUpdatedSubscription = this.filterService
      .getAllUpdatedFilters()
      .subscribe((change: Filter) => {
        this.setDisplayValue(change?.trainNumbers);
      });

    this.currentOpenedFilterSubscription = this.filterService
      .getCurrentOpenedFilterChanged()
      .subscribe((filterType) => {
        if (filterType != this.filterType) {
          this.close();
        }
      });
  }

  resetFilter() {
    this.resetSubscription = this.filterService
      .getResetFilterTrigger()
      .subscribe(() => {
        this.trainNumbers = this.defaultTitle;
      });
  }

  toggleOpen(event) {
    this.open = event;
    if (this.open) {
      this.filterService.setCurrentFilterType(this.filterType);
    }
  }

  close() {
    this.open = false;
  }

  private setDisplayValue(trains: string[]) {
    if (!trains || trains.length == 0) {
      this.trainNumbers = this.defaultTitle;
    } else {
      this.trainNumbers = trains.join(",");
    }
  }

  ngOnDestroy() {}
}
