import { Helper } from "../../shared/helper";
import { TextPlacement, PhysicalLinePosition } from "../enums";
import { KeyValuePair } from "../key-value-pair";
import { Station } from "../station";

export class MapNode {
  public id: string;
  public station: Station;
  public active: boolean = false;
  public lines: string[];
  public physicalPosition: PhysicalLinePosition;
  public textPlacement: TextPlacement;
  public cx: string;
  public cy: string;
  public transform: string;
  public textTransformX: number;
  public textTransformY: number;
  public expanded: boolean;

  constructor(
    station: Station,
    positions: KeyValuePair<string, number>[],
    textPlacement: TextPlacement,
    lines: string[],
    physicalPosition: PhysicalLinePosition
  ) {
    this.station = station;
    this.id = this.getIdFromStationCode(station.stationCode);
    this.active = false;
    this.textPlacement = textPlacement;
    this.setPositions(positions, textPlacement);
    this.lines = lines;
    this.physicalPosition = physicalPosition;
  }

  private getIdFromStationCode(stationCode: string) {
    var id = stationCode.toUpperCase();
    return id
      .replace("Ö", "OO")
      .replace("Å", "AO")
      .replace("Ä", "AE")
      .replace("/Ö/g", "OO")
      .replace("/Å/g", "AO")
      .replace("/Ä/g", "AE");
  }

  private setPositions(
    positions: KeyValuePair<string, number>[],
    textPlacement: TextPlacement
  ) {
    let cx = positions.find((x) => x.key == "cx")?.value;
    let cy = positions.find((x) => x.key == "cy")?.value;
    if (!cx || !cy) {
      return;
    }

    this.cx = Helper.round(cx, 4).toString();
    this.cy = Helper.round(cy, 4).toString();

    this.transform = `translate(${Helper.round(cx - 10, 4)},${Helper.round(
      cy - 10,
      4
    )})`;

    let xAdjustment;
    if (textPlacement == TextPlacement.Right) {
      xAdjustment = 20;
    } else {
      xAdjustment = -20;
    }

    this.textTransformX = Helper.round(cx + xAdjustment, 4);
    this.textTransformY = Helper.round(cy, 4);
  }
}
