import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NotificationItemType } from "../../../models/enums";
import { NotificationItem } from "../../../models/notification/notification-item";
import { EventContainerService } from "../../../services/event-container.service";

@Component({
  selector: "dua-notification-item",
  templateUrl: "./notification-item.component.html",
  styleUrls: ["./notification-item.component.scss"],
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: NotificationItem;
  @Output() selectNotification = new EventEmitter<NotificationItem>();

  constructor(private eventContainerService: EventContainerService) {}

  ngOnInit(): void {}

  /** Changes the event container to contain the notified item and emits event for the user menu to use */
  clickNotificationItem() {
    if (this.notification.itemId) {
      const itemType: NotificationItemType = (typeof(this.notification.itemType) === 'string') ? (<any>NotificationItemType)[this.notification.itemType] : this.notification.itemType;
      switch (itemType) {
        case NotificationItemType.Event:
          this.eventContainerService.populateEvent(this.notification.itemId);
          break;
        case NotificationItemType.TrafficAction:
          this.eventContainerService.populateTrafficAction(
            this.notification.itemId
          );
          break;
        case NotificationItemType.Announcement:
          this.eventContainerService.populateTrafficActionForAnnouncement(
            this.notification.itemId
          );
          break;
        default:
          console.error(
            `Notification type ${
              NotificationItemType[this.notification.itemType] ??
              this.notification.itemType
            } is not implemented`
          );
          return;
      }

      this.selectNotification.emit(this.notification);
    }
  }
}
