import {
  AfterContentInit,
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { forkJoin, Observable, Subscription } from "rxjs";
import { Station } from "src/app/models/station";
import { MapLineType } from "../../models/enums";
import { Filter } from "../../models/event/filter";
import { KeyValuePair } from "../../models/key-value-pair";
import { MapNode } from "../../models/line-map/map-node";
import { FilterService } from "../../services/filter.service";
import { LineMapService } from "../../services/line-map.service";
import { LineService } from "../../services/line.service";
import { PathDrawService } from "../../services/path-draw.service";
import { StationService } from "../../services/station.service";
import { ZoomAndPanService } from "../../services/zoom-and-pan.service";
import { ClosestPath } from "../../shared/closest-path";
import { AutoUnsubscribe } from "../../shared/decorators";
import { Helper } from "../../shared/helper";
import { PopupService } from "../../services/popup.service";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "dua-line-map",
  templateUrl: "./line-map.component.html",
  styleUrls: ["./line-map.component.scss"],
})
@AutoUnsubscribe()
export class LineMapComponent
  implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  mapNodes: MapNode[] = [];
  highlighted: number[] = [];
  nodeElements: SVGPathElement[];
  highlightColor: string;
  highlightDashColor: string;
  activeColor: string;
  baseColor: string;
  strokeDash: string = "5";
  previousNode: MapNode;
  lineNumbers: string[] = [];

  lineNumberSubscription: Subscription;
  getLineSubscription: Subscription;
  getStationsForLinesSubscription: Subscription;

  resetSubscription: Subscription;
  eventSelectedSubscription: Subscription;

  loading: boolean;

  constructor(
    private lineMapService: LineMapService,
    private filterService: FilterService,
    private stationService: StationService,
    private pathDrawService: PathDrawService,
    private zoomAndPanService: ZoomAndPanService,
    private lineService: LineService,
    private popupService: PopupService,
  ) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  ngAfterContentInit(): void {
    //Has to be done after content is initialized as we're trying to get colors from the body of site
    this.getColors();
  }

  ngAfterViewInit(): void {
    //Add zoom and pan, and set line map to initial position
    this.zoomAndPanService.addZoomAndPanFunctionality(
      "line-map",
      "line-map-container"
    );
  }

  clickMapNode(
    event: KeyValuePair<MapNode, boolean>,
    shouldEmit: boolean = true
  ) {
    let node = event.key;
    let isShiftClick = event.value;

    //If shift + click and a previous node has been checked, mark all nodes between last and current node
    if (this.previousNode && isShiftClick) {
      let nodePairsOnLines = this.lineMapService.getAllNodesForPathfinding(
        node.physicalPosition,
        this.previousNode.physicalPosition
      );

      let path = ClosestPath.calculateClosestPath(
        node,
        this.previousNode,
        nodePairsOnLines
      );

      let nodes = this.lineMapService.activateMapNodes(path);

      let nodePairs = this.lineMapService.getPathsFromNodes(nodes);
      for (let pair of nodePairs) {
        if (pair.key.active && pair.value.active) {
          this.drawLines(pair, MapLineType.Active);
        } else {
          this.removeLines(pair);
        }
      }
      let specialNodeData = this.lineMapService.getSpecialPathsFromNodes(nodes);
      for (let pair of specialNodeData) {
        if (pair.key.key.active && pair.key.value.active) {
          this.drawSpecialLines(pair, MapLineType.Active);
        } else {
          this.removeLines(pair.key);
        }
      }
    }
    //If click without shift or no previous selected node. Toggle currently clicked node
    else {
      node = this.lineMapService.toggleMapNode(node);

      let nodePairs = this.lineMapService.getPathsFromNode(node);
      for (let pair of nodePairs) {
        if (pair.key.active && pair.value.active) {
          this.drawLines(pair, MapLineType.Active);
        } else {
          this.removeLines(pair);
        }
      }
      let specialNodeData = this.lineMapService.getSpecialPathsFromNode(node);
      for (let pair of specialNodeData) {
        if (pair.key.key.active && pair.key.value.active) {
          this.drawSpecialLines(pair, MapLineType.Active);
        } else {
          this.removeLines(pair.key);
        }
      }
    }

    this.previousNode = node.active ? node : null;

    if (shouldEmit) {
      this.filterService.emitStationCodesChange(
        this.mapNodes.filter((x) => x.active).map((x) => x.station.stationCode)
      );
    }

    this.pathDrawService.moveAllToTop("-node");
  }

  drawLines(pair: KeyValuePair<MapNode, MapNode>, mapLineType: MapLineType) {
    let id = this.getIdForConnector(pair, mapLineType);
    let drawValue = this.pathDrawService.getDrawValue(
      pair?.key?.cx,
      pair?.key?.cy,
      pair?.value?.cx,
      pair?.value?.cy
    );
    switch (mapLineType) {
      case MapLineType.Base:
        this.createBaseElement(drawValue, "5", id);
        break;
      case MapLineType.Active:
        this.createActiveElement(drawValue, "5", id);

        break;
      case MapLineType.Highlight:
        this.createHighlightElements(drawValue, "5", id);

        break;
      default:
        console.error(`Map line type ${mapLineType} not implemented`);
    }
  }

  createHighlightElements(drawValue, strokeWidth, lineId) {
    //Create bottom color
    this.pathDrawService.createSvgPath(
      drawValue,
      this.highlightColor,
      strokeWidth,
      lineId,
      "highlight"
    );

    //Create dashes
    this.pathDrawService.createSvgPath(
      drawValue,
      this.highlightDashColor,
      strokeWidth,
      lineId + "-dash",
      "highlight",
      this.strokeDash
    );

    this.pathDrawService.moveToTop(lineId + "-dash");
  }

  drawSpecialLines(
    pair: KeyValuePair<
      KeyValuePair<MapNode, MapNode>,
      KeyValuePair<string, string>[]
    >,
    mapLineType: MapLineType
  ) {
    let id = this.getIdForConnector(pair?.key, mapLineType);

    let drawValue = pair?.value.find((x) => x.key == "d")?.value;
    let strokeWidth = pair?.value.find((x) => x.key == "stroke-width")?.value;

    switch (mapLineType) {
      case MapLineType.Base:
        this.createBaseElement(drawValue, strokeWidth, id);
        break;
      case MapLineType.Active:
        this.createActiveElement(drawValue, strokeWidth, id);
        break;
      case MapLineType.Highlight:
        this.createHighlightElements(drawValue, strokeWidth, id);
        break;
      default:
        console.error(`Map line type ${mapLineType} not implemented`);
    }
  }

  createActiveElement(drawValue, strokeWidth, lineId) {
    this.pathDrawService.createSvgPath(
      drawValue,
      this.activeColor,
      strokeWidth,
      lineId
    );
  }

  createBaseElement(drawValue, strokeWidth, lineId) {
    this.pathDrawService.createSvgPath(
      drawValue,
      this.baseColor,
      strokeWidth,
      lineId
    );
  }

  removeLines(pair: KeyValuePair<MapNode, MapNode>) {
    let id = this.getIdForConnector(pair, MapLineType.Active);
    this.pathDrawService.removeElementById(id);
  }

  getIdForConnector(
    pair: KeyValuePair<MapNode, MapNode>,
    mapLineType: MapLineType
  ) {
    return `${pair?.key?.id}-${pair?.value?.id}-${MapLineType[
      mapLineType
    ].toLowerCase()}`;
  }

  ngOnDestroy() {}

  //#region Private

  private initiateComponent(): void {
    this.getMapNodes();
    this.subscribeToChanges();
    this.subscribeToResetFilter();
  }

  private subscribeToChanges(): void {
    this.getLineSubscription = this.lineService
      .getLines()
      .subscribe((lines) => {
        this.lineService.setLineNumbers(lines);
        this.lineNumbers = lines.map((x) => x.name);
      });

    this.lineNumberSubscription = this.filterService
      .getLineNumbersChange()
      .subscribe((lineNumbers) => {
        this.highlightByLine(lineNumbers);
      });

    this.filterService
      .getAllUpdatedFilters()
      .subscribe((change: Filter) => {
        this.highlightByLine(this.filterService.getFilter().lines);
        this.markStationsFromFilter();
      });
  }

  private markStationsFromFilter() {
    this.resetFilteredStations();

    let stationCodes = this.filterService.getFilter().stationCodes;
    this.mapNodes.forEach((node) => {
      if (stationCodes.includes(node.station.stationCode)) {
        this.clickMapNode(new KeyValuePair(node, false), false);
      }
    });
  }

  private subscribeToResetFilter(): void {
    this.resetSubscription = this.filterService
      .getResetFilterTrigger()
      .subscribe((reset) => {
        this.resetFilteredStations();
        this.removeAllLineHighlights();
      });
  }

    /** Resets the active lines and active nodes to default state: No nodes active */
    private resetFilteredStations(): void {
      this.pathDrawService.removeElementsByIdSuffix("active");
      this.mapNodes.forEach((x) => (x.active = false));
      this.previousNode = null;
  }

  /** Gets the stations, and stations for each line from the API.
   * Then tells the line map service to create map nodes and connections from the information,
   * then draws base lines */
  protected getMapNodes(): void {
    this.loading = true;
    //Gets the necessary data about stations, announcement counts and lines
    const stationAndLineDataRequests: Observable<any>[] = [];
    stationAndLineDataRequests.push(this.stationService.getStationData());
    stationAndLineDataRequests.push(
      this.lineService.getStationsConnectedToLines()
    );

    //Makes sure all data is collected before proceeding to use the data and draw the base lines.
    forkJoin(stationAndLineDataRequests).subscribe(
      ([ stations,linesWithStations]) => {
        this.pathDrawService.setAnchor("line-map");

        stations = stations as Station[];
        linesWithStations = linesWithStations as { [line: string]: string[] };
        this.mapNodes = this.lineMapService.getMapNodesFromStations(
          stations,
          linesWithStations
        );
        this.mapNodes.forEach((x) => {
          this.setAnnouncementCounts(x);
        });
        this.loading = false;

        this.checkIfLineMapExists();
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        console.error(error);
        this.popupService.setErrorPopup(error);
      }
    );
  }

  private checkIfLineMapExists() {
    let counter = 0;
    const checkExist = setInterval(
      () => this.createLinesWhenSvgExits(checkExist, counter),
      100
    );
  }

  /** Checks if line-map svg exists once every 100 ms and draw the base lines when it exists.  */
  private createLinesWhenSvgExits(
    checkExist: ReturnType<typeof setInterval>,
    counter: number
  ) {
    if (counter > 100) {
      console.error(
        "Waited too long to draw line map. Could not find line map svg base"
      );
      clearInterval(checkExist);
    } else if (document.getElementById("line-map")) {
      this.drawBaseLines();
      clearInterval(checkExist);
    } else {
      counter++;
    }
  }

  /** Sets announcement count from count data to each station connected to a map node */
  private setAnnouncementCounts(
    mapNode: MapNode
  ) {
    const code = mapNode.station?.stationCode;
    if (!code) return;
  }

  /** Draws the initial lines between all nodes to create the line map without any active lines or highlight */
  private drawBaseLines(): void {
    let nodePairs = this.lineMapService.getAllPaths();
    for (let pair of nodePairs) {
      this.drawLines(pair, MapLineType.Base);
    }

    let specialNodeData = this.lineMapService.getAllSpecialPaths();
    for (let pair of specialNodeData) {
      this.drawSpecialLines(pair, MapLineType.Base);
    }

    this.pathDrawService.moveAllToTop("-node");
  }

  /** Create map lines with css class 'highlight' and dashes based on line numbers */
  private highlightByLine(lineNumbers: string[]) {
    this.removeAllLineHighlights();
    if (!Helper.compareStringArrays(lineNumbers, this.lineNumbers)) {
      let nodePairs = this.lineMapService.getPathsToHighlight(lineNumbers);
      for (let pair of nodePairs) {
        this.drawLines(pair, MapLineType.Highlight);
        this.moveActiveLineToTop(pair);
      }
      let specialNodeData = this.lineMapService.getSpecialPathsToHighlight(
        lineNumbers
      );
      for (let pair of specialNodeData) {
        this.drawSpecialLines(pair, MapLineType.Highlight);
      }
    }
    this.pathDrawService.moveAllToTop("-node");
  }

  /** Modes the active line for a pair of MapNode's to the top of the SVG */
  private moveActiveLineToTop(pair: KeyValuePair<MapNode, MapNode>) {
    let id = this.getIdForConnector(pair, MapLineType.Active);
    this.pathDrawService.moveToTop(id);
  }

  /** Removes all elements by css class 'highlight' */
  private removeAllLineHighlights() {
    this.pathDrawService.removeElementsByClass("highlight");
  }

  /** Gets colors from the document to be used in code */
  private getColors() {
    this.highlightColor = Helper.getCssProperty("--data-line-highlight-color");
    this.highlightDashColor = Helper.getCssProperty(
      "--data-line-highlight-dash-color"
    );
    this.activeColor = Helper.getCssProperty("--data-line-active-color");
    this.baseColor = Helper.getCssProperty("--data-line-base-color");
  }
  //#endregion
}
