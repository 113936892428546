import { Injectable } from "@angular/core";
import { EventCard } from "../models/enums";

/** Service to handle the event container header and header button actions */
@Injectable({
  providedIn: "root",
})
export class EventContainerHeaderService {
  public headerText: string;
  public forwardText: string;
  public backButtonVisible: boolean;
  public forwardButtonVisible: boolean;
  constructor() {}

  /** Sets the container header based on which flow step is active. Selects if buttons should be visible and what texts to use */
  setContainerHeader(
    currentEventCard: EventCard,
    title?: string,
    forwardButtonVisible?: boolean
  ) {
    switch (currentEventCard) {
      case EventCard.EventList:
        this.headerText = title;
        this.backButtonVisible = false;
        this.forwardButtonVisible = forwardButtonVisible;
        this.forwardText = "Skapa nytt";
        break;
      case EventCard.EventForm:
        this.headerText = title;

        this.backButtonVisible = true;

        this.forwardButtonVisible = forwardButtonVisible;
        this.forwardText = "Loggar";
        break;

      case EventCard.TrafficActionForm:
        this.headerText = title;
        this.backButtonVisible = true;
        this.forwardButtonVisible = false;
        this.forwardText = "";
        break;

      case EventCard.EventLog:
        this.headerText = "Loggar";
        this.backButtonVisible = true;
        this.forwardButtonVisible = false;
        this.forwardText = "";
        break;
      case EventCard.Announcement:
        this.headerText = title;
        this.backButtonVisible = true;
        this.forwardButtonVisible = false;
        this.forwardText = "";
        break;
      default:
        console.error("Not implemented");
        break;
    }
  }
}
