import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { NotificationItemType } from "../models/enums";
import { NotificationItem } from "../models/notification/notification-item";

/** Service to handle system notification based on dua notifications */
@Injectable({
  providedIn: "root",
})
export class SystemNotificationService {
  private static openEventCardFromNotification = new Subject<
    NotificationItem
  >();
  constructor() {}

  /** Ask for permission to send notification and then send */
  static async askPermissionAndSendNotification(
    data: NotificationItem,
    logo: string = "/assets/Images/short-logo.png"
  ): Promise<NotificationPermission> {
    return Notification.requestPermission().then(() => {
      this.sendNotification(data, logo);
      return Notification.permission;
    });
  }

  /** Send notification */
  static sendNotification(
    data: NotificationItem,
    logo: string = "/assets/Images/short-logo.png"
  ) {
    const body = SystemNotificationService.notificationTextBuilder(
      data.itemType
    );
    const notification = new Notification(data.title, {
      body: body,
      icon: logo,
      dir: "auto",
      tag: `${data.id}`, //If tag already exists it will be replaced
      timestamp: data.timestamp.getTime(),
      lang: "sv-SE",
      data: data,
    });

    notification.onclick = () => {
      SystemNotificationService.onClickNotification(notification.data);
    };
  }

  public static getOpenEventCardSubject() {
    return SystemNotificationService.openEventCardFromNotification;
  }

  /**  Handle notification clicks */
  private static onClickNotification(data: NotificationItem) {
    if (data == null) return;
    this.openEventCardFromNotification.next(data);
  }

  /** Build system notification text  */
  private static notificationTextBuilder(type: NotificationItemType) {
    switch (type) {
      case NotificationItemType.Event:
        return "Ny händelse finns nu tillgänglig";
      case NotificationItemType.TrafficAction:
        return "Trafikåtgärd upphör";
      case NotificationItemType.Announcement:
        return "Annonsering nekad";
      default:
        return null;
    }
  }
}
