import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr";
import { HubConnection, IHttpConnectionOptions } from "@microsoft/signalr";
import { AuthService } from "./auth.service";
import { filter, take } from "rxjs/operators";

/** Service to handle SignalR for pushing messages from backend */
@Injectable({
  providedIn: "root",
})
export class SignalRService {
  constructor(private authService: AuthService) {}

  createConnection(url: string, connectFunction: Function[], scope: string[]): void {
    let options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        const result = this.authService.getApiAccessToken(scope)
          .pipe(filter((token) => !!token))
          .toPromise<string>();

        return result;
      }
    };

    const connection = new signalR.HubConnectionBuilder()
      .withAutomaticReconnect()
      .withUrl(url, options)
      .build();

    connection
      .start()
      .then(() => {
        this.recreateConnectionOnTokenChange(connection, url, connectFunction, scope);

        for(let i = 0; i < connectFunction.length; i++) {
          this.bindConnectionMessage(connection, connectFunction[i]);
        }
      })
      .catch((error) => {
        this.recreateConnectionOnTokenChange(connection, url, connectFunction, scope);
        console.error(error.message);
      });
  }

  private recreateConnectionOnTokenChange(connection: HubConnection, url: string, connectFunction: Function[], scope: string[]): void {
    // Subscribes to auth token changes and unsubscribes after first execution
    // (since connection is recreated and needs subscriber to be recreated)
    this.authService.accessTokenChanged.pipe(take(1)).subscribe((payload) => {
      // Recreate connection when auth token is changed
      this.createConnection(url, connectFunction, scope);

      // Stop previous connection
      connection.stop();
    });
  }

  private bindConnectionMessage(
    connection: signalR.HubConnection,
    connectFunction: Function
  ) {
    connection.on(connectFunction.name.replace("bound ", ""), (args) =>
      connectFunction(args)
    );
  }
}
