import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { IPathDrawService } from "./interfaces/i-path-draw.service";

/** Service to handle drawing paths programmatically */
@Injectable({
  providedIn: "root",
})
export class PathDrawService implements IPathDrawService {
  private anchorId: string;

  constructor(@Inject(DOCUMENT) private document: Document) {}

  //#region Public functions
  /** Set anchor id so that an anchor can be found when it has to be used.
   * The anchor is the SVG Element
   * */
  public setAnchor(anchorId: string): void {
    this.anchorId = anchorId;
  }

  /** get the path draw value from the coordinates of the first and second point based on the center of the shape*/
  public getDrawValue(
    firstCx: string,
    firstCy: string,
    secondCx: string,
    secondCy: string
  ): string {
    return `M ${firstCx} ${firstCy} L ${secondCx} ${secondCy} z`;
  }

  /** Create an SVG path element */
  public createSvgPath(
    drawValue: string,
    color: string,
    strokeWidth: string,
    id: string,
    cssClass?: string,
    dashArray?: string
  ): void {
    if (this.elementNotCreated(id)) {
      var ele = document.createElementNS("http://www.w3.org/2000/svg", "path");

      ele.setAttribute("d", drawValue);
      ele.setAttribute("stroke", color);
      ele.setAttribute("stroke-width", strokeWidth);
      ele.setAttribute("id", id);

      //Make sure stroke width is not scaled
      ele.setAttribute("vector-effect", "non-scaling-stroke");

      if (dashArray) {
        ele.setAttribute("stroke-dasharray", dashArray);
      }

      if (cssClass) {
        ele.setAttribute("class", cssClass);
      }
      this.appendChild(ele);
    }
  }

  /** Removes a single element by id */
  public removeElementById(elementId: string): void {
    var element = document.getElementById(elementId);
    if (element != null) element.parentNode.removeChild(element);
  }

  /** Moves an element so it is drawn on top of other elements. */
  public moveToTop(elementId: string): void {
    let element = document.getElementById(elementId);
    this.appendChild(element);
  }

  /** Moves several elements based on suffix so it is draw on top of other elements */
  public moveAllToTop(suffix: string): void {
    let elements = Array.from(document.querySelectorAll(`[id$="${suffix}"]`));
    for (let element of elements) {
      this.appendChild(element);
    }
  }

  /** Removes all elements with a specified css class */
  public removeElementsByClass(className: string): void {
    var elements = Array.from(document.getElementsByClassName(className));
    for (let element of elements) {
      element.parentNode.removeChild(element);
    }
  }

  /** Removes all elements with a specified suffix in the id */
  public removeElementsByIdSuffix(suffix: string): void {
    let elements = Array.from(document.querySelectorAll(`[id$="${suffix}"]`));
    for (let element of elements) {
      element.parentNode.removeChild(element);
    }
  }
  //#endregion

  //#region Private functions
  private appendChild(child): void {
    if (child) this.document.getElementById(this.anchorId)?.appendChild(child);
  }

  /** Checks that the element does not already exist */
  private elementNotCreated(elementId: string): boolean {
    return !document.getElementById(elementId);
  }
  //#endregion
}
