import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { AnnouncementsModalStateService } from "../home/line-map/announcements-modal/announcements-modal-state.service";
import { ViewBox } from "../models/line-map/view-box";
import { IZoomAndPanService } from "./interfaces/i-zoom-and-pan.service";

/** Service to handle zoom and pan for line map or other SVGs */
@Injectable({
  providedIn: "root",
})
export class ZoomAndPanService implements IZoomAndPanService {
  scale;

  private circleSize: number;
  private svgViewBox: ViewBox;

  private mouseZoomFactor = 0.05;
  private manualZoomFactor = 0.05;

  private nonScalingCircleSuffix = "-circle";

  /** Viewbox width and height for when the map is as zoomed in as possible */
  private maxZoom = {
    w: 125,
    h: 130,
  };

  /** Viewbox width and height for when the map is as zoomed out as possible */
  private minZoom = {
    w: 2600,
    h: 2100,
  };

  private circleScaleChange = new Subject<number>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private announcementModalState: AnnouncementsModalStateService
  ) {}

  getCircleScaleChange(): Observable<number> {
    return this.circleScaleChange;
  }

  //#region Public functions
  /** Adds zoom and pan functionality to SVG element contained in a div .
   * @param svgId the ID of the SVG element.
   * @param containerId the ID of the div containing the SVG element. Used to react to mouse events.
   * @param initialValue Optional! Sets the initial position
   */
  public addZoomAndPanFunctionality(svgId: string, containerId: string): void {
    this.setupSvgFunctionality(svgId, containerId);
  }

  /** Triggers manual zoom for an SVG based on sign (-1, 0, 1) */
  public triggerManualZoom(sign: number, svgId: string) {
    this.manualZoom(sign, svgId);
  }
  //#endregion

  //#region Private functions
  private setupSvgFunctionality(svgId: string, containerId: string): void {
    //The SVG element we want to affect
    const svgElement = this.document.getElementById(svgId);
    //The div containing the SVG to react to mouse events
    const divContainer = this.document.getElementById(containerId);

    const initialX = -1000;
    const initialY = 100;
    const initialValue = { x: initialX, y: initialY };

    this.svgViewBox = this.setViewBox(
      initialValue.x,
      initialValue.y,
      this.minZoom.w,
      svgElement.clientHeight,
      false,
      svgElement
    );

    const svgSize = { w: svgElement.clientWidth, h: svgElement.clientHeight };
    var isPanning = false;
    var startPoint = { x: 0, y: 0 };
    var endPoint = { x: 0, y: 0 };
    this.scale = 1;

    divContainer.onwheel = (e) => {
      this.announcementModalState.setIsOpen(false);
      e.preventDefault();
      let zoomValues = this.getMouseZoomValues(this.svgViewBox, svgSize, e);
      this.svgViewBox = this.setViewBox(
        this.svgViewBox.x + zoomValues.dx,
        this.svgViewBox.y + zoomValues.dy,
        this.svgViewBox.w - zoomValues.dw,
        this.svgViewBox.h - zoomValues.dh,
        true,
        svgElement
      );
      this.scale = svgSize.w / this.svgViewBox.w;

      this.reverseCircleScaling();
    };

    divContainer.onmousedown = (e) => {
      isPanning = true;
      startPoint = { x: e.x, y: e.y };
    };

    divContainer.onmousemove = (e) => {
      if (isPanning) {
        this.announcementModalState.setIsOpen(false);
        endPoint = { x: e.x, y: e.y };
        var dx = (startPoint.x - endPoint.x) / this.scale;
        var dy = (startPoint.y - endPoint.y) / this.scale;
        this.setViewBox(
          this.svgViewBox.x + dx,
          this.svgViewBox.y + dy,
          this.svgViewBox.w,
          this.svgViewBox.h,
          false,
          svgElement
        );
      }
    };

    divContainer.onmouseup = (e) => {
      if (isPanning) {
        endPoint = { x: e.x, y: e.y };
        var dx = (startPoint.x - endPoint.x) / this.scale;
        var dy = (startPoint.y - endPoint.y) / this.scale;
        this.svgViewBox = this.setViewBox(
          this.svgViewBox.x + dx,
          this.svgViewBox.y + dy,
          this.svgViewBox.w,
          this.svgViewBox.h,
          false,
          svgElement
        );
        
        isPanning = false;
      }
    };

    divContainer.onmouseleave = (e) => {
      isPanning = false;
    };
  }

  /**Reverse scaling on all SVGCircleElements with the ID suffix -circle,
   * emits event to let components handle other scalings using the circle scaling*/
  private reverseCircleScaling() {
    let circleElements = Array.from(
      document.querySelectorAll(`[id$="${this.nonScalingCircleSuffix}"]`)
    );
    for (let element of circleElements) {
      let circle = element as SVGCircleElement;
      if (!this.circleSize) this.circleSize = circle.r.baseVal.value;
      let newValue = this.circleSize / this.scale;
      circle.r.baseVal.value = newValue;
      this.circleScaleChange.next(newValue);
    }
  }

  /** Zoom manually, without input from mouse, based on a sign value for direction of zoom. */
  private manualZoom(sign: number, svgId: string) {
    this.announcementModalState.setIsOpen(false);

    //The SVG element we want to affect
    const svgElement = this.document.getElementById(svgId);

    //Make sure sign is an actual Math.sign value (-1,0,1)
    sign = Math.sign(sign);

    const svgSize = {
      w: svgElement.clientWidth,
      h: svgElement.clientHeight,
    };

    let zoomValues = this.getManualZoomValues(this.svgViewBox, svgSize, sign);
    this.svgViewBox = this.setViewBox(
      this.svgViewBox.x + zoomValues.dx,
      this.svgViewBox.y + zoomValues.dy,
      this.svgViewBox.w - zoomValues.dw,
      this.svgViewBox.h - zoomValues.dh,
      true,
      svgElement
    );
    this.scale = svgSize.w / this.svgViewBox.w;

    this.reverseCircleScaling();
  }

  /** Sets the viewBox attribute of the SVG element, implementing the pan and zoom.
   * @param clamp - decides if value should be clamped between max and min values set in class.
   */
  private setViewBox(
    x: number,
    y: number,
    w: number,
    h: number,
    clamp: boolean,
    svgElement: HTMLElement
  ): ViewBox {
    let viewBox = {
      x: clamp ? this.getClampedViewBoxValue("x", x, w) : x,
      y: clamp ? this.getClampedViewBoxValue("y", y, h) : y,
      w: clamp ? this.getClampedViewBoxValue("w", w) : w,
      h: clamp ? this.getClampedViewBoxValue("h", h) : h,
    };

    svgElement.setAttribute(
      "viewBox",
      `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`
    );

    return viewBox;
  }

  /** Checks the viewbox property against the min and max zoom values.
   * If the property is x, the width should be the clampCheckValue
   * If the property is y, the height should be the clampCheckValue
   * @param property the property of the viewbox that we want to change
   * @param value the value that we want to check for the property
   * @param clampCheckValue the new viewbox width or height to check to clamp x or y for viewbox
   */
  private getClampedViewBoxValue(
    property: string,
    value: number,
    clampCheckValue?: number
  ): number {
    switch (property) {
      case "w":
      case "h":
        if (this.maxZoom[property] > value) {
          return this.maxZoom[property];
        } else if (this.minZoom[property] < value) {
          return this.minZoom[property];
        } else {
          return value;
        }
      case "x":
        if (this.maxZoom.w > clampCheckValue) {
          return this.svgViewBox.x;
        } else if (this.minZoom.w < clampCheckValue) {
          return this.svgViewBox.x;
        } else {
          return value;
        }
      case "y":
        if (this.maxZoom.h > clampCheckValue) {
          return this.svgViewBox.y;
        } else if (this.minZoom.h < clampCheckValue) {
          return this.svgViewBox.y;
        } else {
          return value;
        }
      default:
        console.error(`Property ${property} is not valid`);
    }
  }

  /** Calculates and returns delta values for zoom by mouse scroll. */
  private getMouseZoomValues(
    svgViewBox: ViewBox,
    svgSize: { w: number; h: number },
    mouseWheelEvent: WheelEvent
  ) {
    //SVG view box measures
    var w = svgViewBox.w;
    var h = svgViewBox.h;

    //Mouse offset
    var mx = mouseWheelEvent.offsetX;
    var my = mouseWheelEvent.offsetY;

    //Calculate zoom based on zoom factor
    var dw = w * Math.sign(mouseWheelEvent.deltaY) * -this.mouseZoomFactor;
    var dh = h * Math.sign(mouseWheelEvent.deltaY) * -this.mouseZoomFactor;
    var dx = (dw * mx) / svgSize.w;
    var dy = (dh * my) / svgSize.h;

    let deltas = { dx: dx, dy: dy, dw: dw, dh: dh };
    return deltas;
  }

  /** Calculates and returns delta values for zoom by button click */
  private getManualZoomValues(
    svgViewBox: ViewBox,
    svgSize: { w: number; h: number },
    zoomSign: number
  ) {
    //SVG view box measures
    var w = svgViewBox.w;
    var h = svgViewBox.h;

    let windowX = window.innerWidth;
    let windowY = window.innerHeight;

    //Calculate zoom based on zoomIn boolean
    var dw = w * zoomSign * this.manualZoomFactor;
    var dh = h * zoomSign * this.manualZoomFactor;
    var dx = (dw * (windowX / 2)) / svgSize.w;
    var dy = (dh * (windowY / 2)) / svgSize.h;

    let deltas = { dx: dx, dy: dy, dw: dw, dh: dh };
    return deltas;
  }
  //#endregion
}
