import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { asapScheduler, scheduled } from "rxjs";
import { environment } from "../../environments/environment";
import { LineNumber } from "../models/line-number";
import { ILineService } from "./interfaces/i-line.service";
import { UrlBuilderService } from "./url-builder.service";

/** Service to handle linenumber API */
@Injectable({
  providedIn: "root",
})
export class LineService implements ILineService {
  private lineNumbers: LineNumber[] = [];

  private readonly apiSegment = "LineNumber";
  private readonly getStationsConnectedToLinesSegment =
    "GetStationsConnectedToLines";
  private readonly getSegment = "Get";

  private readonly headers = { "Api-Version": environment.linenumberApi.version };

  constructor(
    private httpClient: HttpClient,
    private urlBuilderService: UrlBuilderService
  ) {}

  public getLines() {
    if (!this.lineNumbers || this.lineNumbers.length == 0) {
      return this.httpClient.get<LineNumber[]>(
        this.urlBuilderService.buildUrl([
          environment.linenumberApi.path,
          this.apiSegment,
          this.getSegment
        ]),
        { headers: this.headers }
      );
    } else {
      return scheduled([this.lineNumbers], asapScheduler);
    }
  }

  public setLineNumbers(lineNumbers: LineNumber[]) {
    this.lineNumbers = lineNumbers;
  }

  /** Uses line data to get stations for each line  */
  public getStationsConnectedToLines() {
    return this.httpClient.get<{ [line: string]: string[] }>(
      this.urlBuilderService.buildUrl([
        environment.linenumberApi.path,
        this.apiSegment,
        this.getStationsConnectedToLinesSegment,
      ]),
      { headers: this.headers, }
    );
  }

  ngOnDestroy() {}
}
