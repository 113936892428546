import { Component, OnInit } from "@angular/core";
import { ZoomAndPanService } from "../../../services/zoom-and-pan.service";

@Component({
  selector: "dua-line-map-zoom",
  templateUrl: "./line-map-zoom.component.html",
  styleUrls: ["./line-map-zoom.component.scss"],
})
export class LineMapZoomComponent implements OnInit {
  constructor(private zoomAndPanService: ZoomAndPanService) {}

  ngOnInit(): void {}

  zoomLineMap(sign: number) {
    this.zoomAndPanService.triggerManualZoom(sign, "line-map");
  }
}
