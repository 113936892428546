import { Component, OnInit } from '@angular/core';
import { SortService } from 'src/app/services/sort.service';
import { SortProperty } from "../../../../models/enums";
import { KeyValuePair } from "../../../../models/key-value-pair";

@Component({
  selector: "dua-event-list-sorter",
  templateUrl: "./event-list-sorter.component.html",
  styleUrls: ["./event-list-sorter.component.scss"],
})
export class EventListSorterComponent implements OnInit {

  options: KeyValuePair<string, SortProperty>[] = [
    new KeyValuePair("Alfabetisk ordning", SortProperty.Alphabetically),
    new KeyValuePair("Starttid", SortProperty.StartTime),
    new KeyValuePair("Senast uppdaterad", SortProperty.LastUpdate),
  ];
  currentOptionValue = SortProperty.Alphabetically;

  constructor(private sortService: SortService) {}

  ngOnInit() {
    this.currentOptionValue = this.sortService.getCurrentSort();
  }

  onChange(eventValue: SortProperty) {
    /*The value comes as a string even though the function is expecting a SortProperty, 
    therefore we need to parse the value */
    const sort = ((eventValue as unknown) as string);
    this.sortService.setCurrentSort(parseInt(sort));
  }
}
