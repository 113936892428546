import { Injectable } from "@angular/core";
import _ from "lodash";
import { environment } from "../../environments/environment";
import { IUrlBuilderService } from "./interfaces/i-url-builder.service";

@Injectable({
    providedIn: "root",
  })
export class UrlBuilderService implements IUrlBuilderService {
    public buildUrl(segments: string[]): string{
        return _.trim(`${environment.apiUrl}/${segments.join("/")}`, "/");
    }
}