import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FilterService } from "../../../../services/filter.service";

@Component({
  selector: "dua-train-number-picker",
  templateUrl: "./train-number-picker.component.html",
  styleUrls: ["./train-number-picker.component.scss"],
})
export class TrainNumberPickerComponent implements OnInit {
  @Output() closeFilter: EventEmitter<boolean> = new EventEmitter();
  currentTrainSeries: string;
  trainSeries: string[] = [];
  infoText =
    'Du kan ange flera tågnummer genom att separera dem med kommatecken, eller en hel serie genom att ange första och sista numret i spannet med bindestreck i mellan.\nTågnummer kan också exkluderas genom att ange utropstecken framför, ex "!2240"';

  constructor(private filterService: FilterService) {}

  ngOnInit(): void {
    this.initiateComponent();
  }

  isDisabled(): boolean {
    return this.filterService.isDisabled();
  }

  isFilterStringValid(value: string): boolean {
    return /^(\!?\w{1,6}[\-\,\t\n\r\ ])*(\!?\w{1,6})$/.test(value);
  }

  private initiateComponent() {
    this.trainSeries = this.filterService.getFilter().trainNumbers;
  }

  addSeriesToArray(seriesString: string) {
    let trainSelection = seriesString.split(/[\,\ ]{1,}/);
    trainSelection.forEach((element) => {
      if (!this.trainSeries.includes(element)) this.trainSeries.push(element);
    });
    this.orderAndCleanArray();
    this.filterService.emitTrainNumbersChange(this.trainSeries);
  }

  pasteTrainNumbers(event: ClipboardEvent) {
    event.preventDefault();
    let data = event.clipboardData.getData("text");
    let transformedTrains: string[] = [];
    let trainSelection = data.split(/[\,\t\n\r ]{1,}/);

    trainSelection.forEach((train) => {
      train = train.trim();
      if (
        this.isFilterStringValid(train) &&
        !this.trainSeries.includes(train) &&
        train
      )
        transformedTrains.push(train);
    });
    let trainSelectionString = transformedTrains.join(",");
    this.addSeriesToArray(trainSelectionString);
  }

  addCurrentString(seriesString: string) {
    this.currentTrainSeries = seriesString;
  }

  removeSeries(event: string) {
    this.trainSeries = this.trainSeries.filter((x) => x != event);
    this.filterService.emitTrainNumbersChange(this.trainSeries);
  }

  orderAndCleanArray() {
    //Select distinct
    this.trainSeries = [...new Set(this.trainSeries)];

    //Order
    this.trainSeries = this.trainSeries.sort((a, b) => {
      let compareA: string;
      let compareB: string;
      if (a.startsWith("!")) {
        compareA = a.substring(1, 5);
      } else {
        compareA = a.substring(0, 4);
      }

      if (b.startsWith("!")) {
        compareB = b.substring(1, b.length);
      } else {
        compareB = b.substring(0, 4);
      }

      return parseInt(compareA) > parseInt(compareB) ? 1 : -1;
    });
  }

  close() {
    if (this.currentTrainSeries) this.addSeriesToArray(this.currentTrainSeries);
    this.closeFilter.emit(true);
  }
}
