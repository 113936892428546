import { KeyValues } from "./key-values";

export class KeyValuesException {
  public title: string;
  public keyValues: KeyValues[];

  public static IsKeyValuesException(object: any): object is KeyValuesException
  {
    if (!object || typeof object !== 'object' || Array.isArray(object)) {
      return false;
    }

    if ('title' in object && 'keyValues' in object &&
      typeof object.title === 'string' && (!object.errors || Array.isArray(object.keyValues) &&
      (object.keyValues.length <= 0 || KeyValues.IsKeyValues(object.keyValues[0])))) {
      return true;
    }
    return false;
  }
}
