import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "dua-not-authorized",
  templateUrl: "./not-authorized.component.html",
  styleUrls: ["./not-authorized.component.scss"],
})
export class NotAuthorizedComponent implements OnInit, AfterViewInit {
  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.authService.getIsLoggedIn()) {
      this.router.navigateByUrl("");
    }
  }

  login() {
    this.authService.login();
  }
}
