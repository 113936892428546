import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dua-info-button',
  templateUrl: './info-button.component.html',
  styleUrls: ['./info-button.component.scss']
})
export class InfoButtonComponent implements OnInit {

  @Input() infoText: string;
  open:boolean;
  constructor() { }

  ngOnInit(): void {
  }

  toggleOpen(){
    this.open = !this.open;
  }

}
