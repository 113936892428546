import { Component, Input, OnInit } from "@angular/core";
import { TrafficActionListItem } from "src/app/models/traffic-action/traffic-action-list-item";
import { ListItemService } from "src/app/services/list-item.service";

@Component({
  selector: "dua-traffic-action-list-item",
  templateUrl: "./traffic-action-list-item.component.html",
  styleUrls: ["./traffic-action-list-item.component.scss"],
})
export class TrafficActionListItemComponent implements OnInit {
  @Input() action: TrafficActionListItem = null;
  @Input() numberOfAffectedTrains: number;

  constructor(private listItemService: ListItemService) {}

  ngOnInit(): void {}

  ngOnChanges(): void {}

  getIconFromStatus(): string {
    return this.listItemService.getIconFromStatus(this.action?.notified, this.action?.status);
  }
}
